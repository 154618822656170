"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Input = _interopRequireDefault(require("../components/forms/Input"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Icon = _interopRequireDefault(require("../components/Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Title = (0, _styled["default"])(_Text["default"].h4)(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    marginBottom: '10px'
  }, theme.media.tablet, {
    marginBottom: '15px'
  });
});
var Wrapper = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    backgroundColor: 'white',
    padding: '15px',
    width: '100%'
  }, theme.media.tablet, {
    padding: '30px 40px',
    height: '160px',
    borderRadius: '4px',
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)'
  });
});
var InputWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    label: 'InputWrapper',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  }, theme.media.tablet, {
    height: '60px',
    flexDirection: 'row'
  });
});
var StyledInputText = (0, _styled["default"])(_Input["default"])(function (_ref7) {
  var _ref8;

  var theme = _ref7.theme;
  return _ref8 = {
    label: 'StyledInputText',
    marginBottom: '10px',
    paddingRight: '40px'
  }, _defineProperty(_ref8, theme.media.tablet, {
    paddingRight: '15px',
    paddingLeft: '60px',
    borderRight: '0',
    width: '68%',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px'
  }), _defineProperty(_ref8, theme.media.desktop, {
    width: '80%'
  }), _ref8;
});
var SubmitButton = (0, _styled["default"])(_Button["default"])(function (_ref9) {
  var _ref10;

  var theme = _ref9.theme;
  return _ref10 = {
    label: 'Submit',
    fontWeight: '400',
    flexBasis: 'auto',
    flexGrow: 1,
    lineHeight: '38px',
    width: '100%',
    display: 'none'
  }, _defineProperty(_ref10, theme.media.tablet, {
    display: 'block',
    width: '32%',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px'
  }), _defineProperty(_ref10, theme.media.desktop, {
    width: '20%'
  }), _ref10;
});
var StyledIcon = (0, _styled["default"])(_Icon["default"])(function (_ref11) {
  var theme = _ref11.theme;
  return _defineProperty({
    position: 'absolute',
    top: '10px',
    right: '10px',
    height: '25px',
    width: '25px',
    color: theme.colors.text
  }, theme.media.tablet, {
    left: '13px',
    top: '13px',
    right: 'auto',
    height: '35px',
    width: '35px'
  });
});

var SearchAction = function SearchAction(_ref13) {
  var label = _ref13.label,
      placeholder = _ref13.placeholder,
      value = _ref13.value,
      submitLabel = _ref13.submitLabel,
      onSubmit = _ref13.onSubmit,
      style = _ref13.style,
      className = _ref13.className;

  var _useState = (0, _react.useState)(value),
      _useState2 = _slicedToArray(_useState, 2),
      inputValue = _useState2[0],
      setInputValue = _useState2[1];

  return _react["default"].createElement(Wrapper, {
    style: style,
    className: className
  }, _react["default"].createElement(Title, null, label), _react["default"].createElement(InputWrapper, null, _react["default"].createElement(StyledIcon, {
    size: "100%",
    name: "search"
  }), _react["default"].createElement(StyledInputText, {
    big: true,
    value: inputValue,
    placeholder: placeholder,
    onChange: function onChange(event) {
      return setInputValue(event.target.value);
    },
    onKeyPress: function onKeyPress(event) {
      return event.key === 'Enter' && onSubmit(inputValue);
    }
  }), _react["default"].createElement(SubmitButton, {
    type: "submit",
    onClick: function onClick() {
      return onSubmit(inputValue);
    }
  }, submitLabel)));
};

SearchAction.propTypes = {
  /**
   * label
   */
  label: _propTypes["default"].string,

  /**
   * placeholder
   */
  placeholder: _propTypes["default"].string,

  /**
   * value
   */
  value: _propTypes["default"].string,

  /**
   * submit label
   */
  submitLabel: _propTypes["default"].string,

  /**
   * on submit callback
   */
  onSubmit: _propTypes["default"].func
};
SearchAction.defaultProps = {
  label: '',
  placeholder: '',
  submitLabel: '',
  onSubmit: function onSubmit() {}
};
var _default = SearchAction;
exports["default"] = _default;