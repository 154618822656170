"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Button = _interopRequireDefault(require("../../../components/Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  padding: '56px 20px',
  textAlign: 'center'
});

function MobileCTA(_ref) {
  var link = _ref.link,
      label = _ref.label;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Button["default"], {
    href: link,
    size: "big"
  }, label));
}

var _default = MobileCTA;
exports["default"] = _default;