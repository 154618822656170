"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../../components/Container"));

var _FooterLegal = _interopRequireDefault(require("./FooterLegal"));

var _FooterLinks = _interopRequireDefault(require("./FooterLinks"));

var _FooterSocials = _interopRequireDefault(require("./FooterSocials"));

var _FooterLang = _interopRequireDefault(require("./FooterLang"));

var _utils = require("../../theme/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var TopFooter = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    label: 'TopFooter',
    backgroundColor: theme.colors.primary,
    color: (0, _utils.textColor)((0, _utils.lighten)(theme.colors.primary, 5), theme),
    borderBottom: "1px solid ".concat((0, _utils.lighten)(theme.colors.primary, 15))
  };
});
var TopWrapper = (0, _styled["default"])('div')(function (_ref2) {
  var _objectSpread2;

  var reverse = _ref2.reverse,
      isLinks = _ref2.isLinks,
      theme = _ref2.theme;
  return _objectSpread({
    label: 'TopWrapper',
    marginLeft: '-15px',
    marginRight: '-15px'
  }, reverse ? {
    display: 'flex',
    flexDirection: 'column-reverse'
  } : {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, theme.media.tablet, _objectSpread({
    marginLeft: 0,
    marginRight: 0
  }, !isLinks ? {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  } : {})), _defineProperty(_objectSpread2, theme.media.laptop, {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }), _objectSpread2));
});
var BottomFooter = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return {
    label: 'BottomFooter',
    color: (0, _utils.textColor)((0, _utils.darken)(theme.colors.primary, 20), theme),
    backgroundColor: (0, _utils.darken)(theme.colors.primary, 30)
  };
});
var BottomWrapper = (0, _styled["default"])('div')(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty({
    label: 'BottomWrapper',
    marginLeft: '-15px',
    marginRight: '-15px'
  }, theme.media.tablet, {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px'
  });
});

var Footer = function Footer(_ref6) {
  var links = _ref6.links,
      socials = _ref6.socials,
      langs = _ref6.langs,
      legal = _ref6.legal,
      lang = _ref6.lang,
      langTitle = _ref6.langTitle,
      onLangChange = _ref6.onLangChange; // Create each part (links, langs, socials, legal), or null if not given

  var Links = links.length > 0 ? _react["default"].createElement(_FooterLinks["default"], {
    links: links
  }) : null;
  var Langs = langs.length > 1 ? _react["default"].createElement(_FooterLang["default"], {
    langs: langs,
    lang: lang,
    title: langTitle,
    onLangChange: onLangChange
  }) : null;
  var Socials = socials.length > 0 ? _react["default"].createElement(_FooterSocials["default"], {
    socials: socials
  }) : null;

  var Legal = _react["default"].createElement(_FooterLegal["default"], null, legal); // Fill Positions of each part in top/bottom, left/right


  var TopComponents = [Links || Langs || Socials, Socials];
  var BottomComponents = [];
  if (!Links && !(Socials && Langs)) TopComponents[1] = Legal;else BottomComponents[1] = Legal;
  if (Links && Langs) BottomComponents[0] = Langs;

  var Top = _react["default"].createElement(TopFooter, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(TopWrapper, {
    reverse: BottomComponents.length > 0,
    isLinks: !!Links
  }, TopComponents[0], TopComponents[1])));

  var Bottom = BottomComponents.length > 0 ? _react["default"].createElement(BottomFooter, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(BottomWrapper, null, BottomComponents[0], BottomComponents[1]))) : null;
  return _react["default"].createElement("footer", null, Top, Bottom);
};

Footer.propTypes = {
  /**
   * Array of links object. Object must have two properties : label and url
   */
  links: _propTypes["default"].arrayOf(_propTypes["default"].object),

  /**
   * Array of socials links object. Object must have a `icon` and `url`
   */
  socials: _propTypes["default"].arrayOf(_propTypes["default"].object),

  /**
   * Array of lang object. Object must have two properties : ID and label
   */
  langs: _propTypes["default"].arrayOf(_propTypes["default"].object),

  /**
   * String as a title
   */
  legal: _propTypes["default"].string,

  /**
   * Lang object has two properties: ID and label
   */
  lang: _propTypes["default"].shape({
    ID: _propTypes["default"].string,
    label: _propTypes["default"].string
  }),

  /**
   * Lang label
   */
  langTitle: _propTypes["default"].string,

  /**
   * Callback when the user lang changes
   */
  onLangChange: _propTypes["default"].func
};
Footer.defaultProps = {
  links: [],
  socials: [],
  langs: [],
  onLangChange: function onLangChange() {}
};
var _default = Footer;
exports["default"] = _default;