"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("./Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('ul')({
  display: 'inline-block',
  backgroundColor: '#fff',
  boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)',
  padding: 30,
  overflow: 'hidden',
  listStyleType: 'none'
});
var Detail = (0, _styled["default"])('li')({
  "float": 'left',
  width: 200,
  paddingRight: 30,
  marginBottom: 0
});
var Label = (0, _styled["default"])(_Text["default"])(function (_ref) {
  var theme = _ref.theme;
  return {
    display: 'block',
    color: theme.colors.text,
    marginBottom: 10
  };
});
var Value = (0, _styled["default"])(_Text["default"])(function (_ref2) {
  var color = _ref2.color,
      theme = _ref2.theme;
  return {
    color: theme.colors[color] || color,
    marginBottom: 10
  };
});
var Info = (0, _styled["default"])(_Text["default"])({
  display: 'block',
  textTransform: 'uppercase'
});

function ContractDetails(_ref3) {
  var details = _ref3.details;

  if (details.length <= 0) {
    return null;
  }

  return _react["default"].createElement(Wrapper, null, details.map(function (_ref4, i) {
    var label = _ref4.label,
        value = _ref4.value,
        color = _ref4.color,
        info = _ref4.info;
    return _react["default"].createElement(Detail, {
      key: i
    }, label ? _react["default"].createElement(Label, {
      tag: "span",
      size: "typo5"
    }, label) : null, value ? _react["default"].createElement(Value, {
      tag: "span",
      size: "typo2",
      color: color
    }, value) : null, info ? _react["default"].createElement(Info, {
      tag: "span",
      size: "typo6"
    }, info) : null);
  }));
}

ContractDetails.propTypes = {
  /**
   * List of details for the contract
   */
  details: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number, _propTypes["default"].element]),
    color: _propTypes["default"].string,
    info: _propTypes["default"].any
  }))
};
ContractDetails.defaultProps = {
  details: []
};
var _default = ContractDetails;
exports["default"] = _default;