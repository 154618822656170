"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _PieChart = _interopRequireDefault(require("../PieChart"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} // import { PieChart, Pie, Cell } from 'recharts';


var Wrapper = (0, _styled["default"])('div')({
  display: 'flex',
  // horizontal center of the chart
  flexDirection: 'column',
  alignItems: 'center'
});

function ExpensesGraphic(_ref) {
  var items = _ref.items,
      total = _ref.total,
      label = _ref.label; // const size = 230;

  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_PieChart["default"], {
    size: 230,
    value: total,
    values: items,
    label: label
  }));
}

ExpensesGraphic.propTypes = {
  total: _propTypes["default"].any,
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    value: _propTypes["default"].any,
    color: _propTypes["default"].string
  }))
};
ExpensesGraphic.defaultProps = {
  items: []
};
var _default = ExpensesGraphic;
exports["default"] = _default;