"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../../components/Container"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var ArticleContainer = (0, _styled["default"])(_Container["default"])({
  maxWidth: '798px' // 768 + 30px padding

});
var _default = ArticleContainer;
exports["default"] = _default;