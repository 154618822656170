"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ContainerWrapper = (0, _styled["default"])('div')({
  marginTop: 70,
  marginBottom: 70
});
var List = (0, _styled["default"])('ul')({
  paddingLeft: 0
});
var Info = (0, _styled["default"])('li')({
  display: 'flex',
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: '1px solid #e5e5e5',
  '&:last-child': {
    borderBottom: '0px'
  }
});
var Label = (0, _styled["default"])(_Text["default"])({
  minWidth: '40%'
});
var ActionWrapper = (0, _styled["default"])('ul')({
  paddingLeft: 0,
  listStyleType: 'none',
  marginTop: 40
});
var ButtonWrapper = (0, _styled["default"])('li')({
  marginRight: 10
});
var StyledHeading = (0, _styled["default"])(_Text["default"])({
  marginBottom: 30
});
var InfoWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({}, theme.media.desktop, {
    width: '60%',
    padding: 30,
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)'
  });
});

function InfoBox(_ref3) {
  var title = _ref3.title,
      infos = _ref3.infos;
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(ContainerWrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(InfoWrapper, null, title ? _react["default"].createElement(StyledHeading, {
    size: "typo3",
    tag: "h3"
  }, title) : null, infos.length > 0 ? _react["default"].createElement(List, null, infos.map(function (_ref4, i) {
    var label = _ref4.label,
        value = _ref4.value;
    return _react["default"].createElement(Info, {
      key: i
    }, _react["default"].createElement(Label, {
      tag: "span",
      size: "typo4"
    }, label), _react["default"].createElement(_Text["default"], {
      tag: "span",
      size: "typo5"
    }, value));
  })) : null))));
}

InfoBox.propTypes = {
  /**
   * Slice title
   */
  title: _propTypes["default"].string,

  /**
   * Array of profil infos
   */
  infos: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].string
  })),

  /**
   * Array of props given to buttons at the end of the slices
   */
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    link: _propTypes["default"].string,
    label: _propTypes["default"].string
  }))
};
InfoBox.defaultProps = {
  infos: [],
  actions: []
};
var _default = InfoBox;
exports["default"] = _default;