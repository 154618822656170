"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _ContractItem = _interopRequireDefault(require("../components/ContractItem"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')({
  paddingTop: 50,
  paddingBottom: 50
});
var FirstLine = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    display: 'flex',
    marginBottom: 30,
    flexDirection: 'column'
  }, theme.media.tablet, {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'row'
  });
});
var Title = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    marginBottom: 15
  }, theme.media.tablet, {
    marginBottom: 0
  });
});
var ContractList = (0, _styled["default"])('div')({}); //WHY ?

function QuickContractList(_ref5) {
  var title = _ref5.title,
      listLink = _ref5.listLink,
      contracts = _ref5.contracts;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(FirstLine, null, _react["default"].createElement(Title, {
    tag: "h1"
  }, title), listLink ? _react["default"].createElement(_Button["default"], {
    href: listLink.link,
    color: "secondary"
  }, listLink.label) : null), _react["default"].createElement(ContractList, null, contracts.map(function (contract, i) {
    return _react["default"].createElement(_ContractItem["default"], _extends({}, contract, {
      key: i
    }));
  }))));
}

QuickContractList.propTypes = {
  /**
   * Slice title
   */
  title: _propTypes["default"].string,

  /**
   * Button props for the links of all contracts
   */
  listLink: _propTypes["default"].shape({
    link: _propTypes["default"].string,
    label: _propTypes["default"].string
  }),

  /**
   * List of contracts props
   */
  contracts: _propTypes["default"].arrayOf(_propTypes["default"].shape(_ContractItem["default"].propTypes))
};
QuickContractList.defaultProps = {
  contracts: []
};
var _default = QuickContractList;
exports["default"] = _default;