"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  backgroundColor: '#fff',
  label: 'health-overview',
  paddingTop: 70,
  paddingBottom: 70
});
var Title = (0, _styled["default"])(_Text["default"])(function (_ref) {
  var theme = _ref.theme;
  return {
    marginBottom: '50px',
    color: theme.colors.text
  };
});
var RefundsWrapper = (0, _styled["default"])('div')({
  marginBottom: '40px'
});
var ExpensesWrapper = (0, _styled["default"])('div')({
  marginBottom: '40px'
});
var Action = (0, _styled["default"])('div')({
  textAlign: 'center',
  marginBottom: '25px'
});

function HealthOverview(_ref2) {
  var title = _ref2.title,
      action = _ref2.action,
      Refunds = _ref2.Refunds,
      Expenses = _ref2.Expenses;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(Title, {
    tag: "h2",
    size: "typo1"
  }, title), Expenses ? _react["default"].createElement(ExpensesWrapper, null, Expenses) : null, Refunds ? _react["default"].createElement(RefundsWrapper, null, Refunds) : null, action ? _react["default"].createElement(Action, null, _react["default"].createElement(_Button["default"], {
    href: action.url,
    color: "secondary"
  }, action.label)) : null));
}

HealthOverview.propTypes = {
  /**
   * Component title
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * Call to action at the bottom
   */
  action: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  }),

  /**
   * RefundTimeline Component
   */
  Refunds: _propTypes["default"].element,

  /**
   * ExpensesOverview Component
   */
  Expenses: _propTypes["default"].element
};
var _default = HealthOverview;
exports["default"] = _default;