"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ParagraphContent = (0, _styled["default"])('p')(function (_ref) {
  var theme = _ref.theme,
      tag = _ref.tag;
  return _defineProperty({
    label: "paragraph-".concat(tag),
    fontSize: 16,
    lineHeight: 1.5,
    fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
    color: theme.colors.text || '#333',
    'p > a': {
      textDecoration: 'underline',
      color: theme.colors.primary,
      fontWeight: 'bold'
    }
  }, theme.media.tablet, {
    fontSize: 16,
    lineHeight: 1.67
  });
});
var tags = {
  p: ParagraphContent,
  span: ParagraphContent.withComponent('span'),
  div: ParagraphContent.withComponent('div')
};

function Paragraph(_ref3) {
  var content = _ref3.content,
      tag = _ref3.tag,
      className = _ref3.className;

  var _tag = tag ? tag : typeof content === 'string' ? 'p' : 'div';

  var Tag = tags[_tag];
  return _react["default"].createElement(Tag, {
    tag: _tag,
    className: className
  }, content);
}

Paragraph.propTypes = {
  /**
   * Content
   */
  content: _propTypes["default"].any.isRequired,

  /**
   * p, span, div
   */
  tag: _propTypes["default"].oneOf(['p', 'span', 'div']),

  /**
   * Allow user to override style
   */
  className: _propTypes["default"].string
};
var _default = Paragraph;
exports["default"] = _default;