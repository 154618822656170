"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExpensesDetail = ExpensesDetail;
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled4 = _interopRequireDefault(require("@emotion/styled"));

var _Pill = _interopRequireDefault(require("../Pill"));

var _media = _interopRequireDefault(require("../../theme/media"));

var _Text = _interopRequireDefault(require("../Text"));

var _styled;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Tr = (0, _styled4["default"])('tr')((_styled = {
  height: '40px'
}, _defineProperty(_styled, _media["default"].tablet, {
  height: '60px'
}), _defineProperty(_styled, ':nth-of-type(odd)', {
  backgroundColor: '#f8fafd'
}), _defineProperty(_styled, '& > td', {
  verticalAlign: 'middle'
}), _styled));
var Percent = (0, _styled4["default"])('td')(_defineProperty({
  width: '70px',
  textAlign: 'center'
}, _media["default"].tablet, {
  width: '100px'
}));
var Content = (0, _styled4["default"])('td')(_defineProperty({
  textAlign: 'right',
  paddingRight: '15px'
}, _media["default"].tablet, {
  width: '100px'
}));

function ExpensesDetail(_ref) {
  var title = _ref.title,
      value = _ref.value,
      status = _ref.status,
      color = _ref.color;
  return _react["default"].createElement(Tr, null, _react["default"].createElement(Percent, null, _react["default"].createElement(_Pill["default"], {
    large: true,
    color: color
  }, status)), _react["default"].createElement("td", null, _react["default"].createElement(_Text["default"], {
    size: "typo5"
  }, title)), _react["default"].createElement(Content, null, _react["default"].createElement(_Text["default"], {
    size: "typo4"
  }, value)));
}

ExpensesDetail.propTypes = {
  title: _propTypes["default"].string,
  value: _propTypes["default"].any,
  status: _propTypes["default"].string,
  color: _propTypes["default"].string
};
var Table = (0, _styled4["default"])('table')({
  width: '100%',
  borderCollapse: 'collapse'
});

function ExpensesDetails(_ref2) {
  var items = _ref2.items;
  return _react["default"].createElement("div", null, _react["default"].createElement(Table, null, _react["default"].createElement("tbody", null, items && items.map(function (item, i) {
    return _react["default"].createElement(ExpensesDetail, {
      key: i,
      title: item.label,
      value: item.displayValue,
      status: item.status,
      color: item.color
    });
  }))));
}

ExpensesDetails.propTypes = {
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    displayValue: _propTypes["default"].any,
    status: _propTypes["default"].string,
    color: _propTypes["default"].string
  }))
};
ExpensesDetails.defaultProps = {
  items: []
};
var _default = ExpensesDetails;
exports["default"] = _default;