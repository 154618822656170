"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var AnimatedBurger = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    width: 24,
    height: 24,
    position: 'relative',
    cursor: 'pointer'
  }, theme.media.laptop, {
    display: 'none'
  });
});
var Span = (0, _styled["default"])('span')(function (_ref3) {
  var theme = _ref3.theme,
      open = _ref3.open;
  return {
    display: 'block',
    position: 'absolute',
    height: '2px',
    width: '100%',
    background: theme.colors.primary,
    borderRadius: '2px',
    opacity: 1,
    left: 0,
    transform: 'rotate(0deg)',
    transition: '0.3s cubic-bezier(0.075, 0.82, 0.165, 1)',
    willChange: 'transform',
    '&:nth-of-type(1)': _objectSpread({
      top: 6
    }, open ? {
      transform: 'rotate(-135deg)',
      width: 10
    } : {}),
    '&:nth-of-type(2)': _objectSpread({
      top: 12
    }, open ? {
      left: 0,
      transform: 'rotate(135deg)'
    } : {}),
    '&:nth-of-type(3)': _objectSpread({
      top: 18
    }, open ? {
      transform: 'rotate(-135deg)',
      left: 12,
      width: 10
    } : {})
  };
});

function Hamburger(_ref4) {
  var open = _ref4.open,
      onClick = _ref4.onClick;
  return _react["default"].createElement(AnimatedBurger, {
    onClick: onClick
  }, _react["default"].createElement(Span, {
    open: open
  }), _react["default"].createElement(Span, {
    open: open
  }), _react["default"].createElement(Span, {
    open: open
  }));
}

Hamburger.propTypes = {
  /**
   * Boolean that display the burger open or closed
   * The burger display a close icon when it's open
   */
  open: _propTypes["default"].bool,

  /**
   * onClick listener
   */
  onClick: _propTypes["default"].func
};
Hamburger.defaultProps = {
  open: false,
  onClick: function onClick() {}
};
var _default = Hamburger;
exports["default"] = _default;