"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../../components/Link"));

var _Icon = _interopRequireDefault(require("../../../components/Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])(_Link["default"])(function (_ref) {
  var theme = _ref.theme;
  return {
    padding: '24px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "2px solid ".concat(theme.colors.lightGray)
  };
});
var UserIcon = (0, _styled["default"])(_Icon["default"])(function (_ref2) {
  var count = _ref2.count;
  return {
    position: 'relative',
    marginRight: 10,
    '&:after': count > 0 ? {
      content: "\"".concat(count, "\""),
      fontSize: 10,
      width: 12,
      height: 12,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      // TODO find the hex code for the notification red
      backgroundColor: 'red',
      borderRadius: '50%',
      right: '-2px',
      top: '-3px'
    } : {}
  };
});
var UserWrapper = (0, _styled["default"])('div')({
  display: 'flex'
});
var UserName = (0, _styled["default"])('div')({
  fontSize: 18,
  color: '#333',
  marginBottom: 10
});
var ActionLabel = (0, _styled["default"])('div')({
  fontSize: 14,
  color: '#999',
  fontWeight: 600
});

function UserLink(_ref3) {
  var name = _ref3.name,
      label = _ref3.label,
      link = _ref3.link,
      notifications = _ref3.notifications;
  return _react["default"].createElement(Wrapper, {
    href: link
  }, _react["default"].createElement(UserWrapper, null, _react["default"].createElement(UserIcon, {
    name: "user",
    count: notifications
  }), _react["default"].createElement("div", null, _react["default"].createElement(UserName, null, name), _react["default"].createElement(ActionLabel, null, label))), _react["default"].createElement(_Icon["default"], {
    name: "right"
  }));
}

var _default = UserLink;
exports["default"] = _default;