"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled3 = _interopRequireDefault(require("@emotion/styled"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SquareButton = _interopRequireDefault(require("../components/SquareButton"));

var _SearchAction = _interopRequireDefault(require("../components/SearchAction"));

var _ActionBanner = _interopRequireDefault(require("./ActionBanner"));

var _media = _interopRequireDefault(require("../theme/media"));

var _styled;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var StyledSearchAction = (0, _styled3["default"])(_SearchAction["default"])((_styled = {
  label: 'StyledSearchAction'
}, _defineProperty(_styled, _media["default"].tablet, {
  padding: '30px 40px',
  height: '160px',
  width: '74%',
  "float": 'left'
}), _defineProperty(_styled, _media["default"].laptop, {
  width: '78%'
}), _defineProperty(_styled, _media["default"].desktop, {
  width: '82%'
}), _styled));
var StyledButton = (0, _styled3["default"])(_SquareButton["default"])(_defineProperty({
  flexDirection: 'unset',
  display: 'block',
  marginTop: '11px'
}, _media["default"].tablet, {
  "float": 'right',
  marginTop: 0
}));

var SearchActionBanner = function SearchActionBanner(_ref) {
  var actionBannerProps = _ref.actionBannerProps,
      searchActionProps = _ref.searchActionProps,
      buttonProps = _ref.buttonProps,
      props = _objectWithoutProperties(_ref, ["actionBannerProps", "searchActionProps", "buttonProps"]);

  return _react["default"].createElement(_ActionBanner["default"], _extends({}, actionBannerProps, props), _react["default"].createElement(StyledSearchAction, searchActionProps), buttonProps && _react["default"].createElement(StyledButton, _extends({
    ghost: true
  }, buttonProps), buttonProps.label));
};

SearchActionBanner.propTypes = {
  actionBannerProps: _propTypes["default"].shape(_ActionBanner["default"].propTypes),
  searchActionProps: _propTypes["default"].shape(_SearchAction["default"].propTypes),
  buttonProps: _propTypes["default"].shape(_SquareButton["default"].propTypes)
};
SearchActionBanner.defaultProps = {
  actionBannerProps: {},
  searchActionProps: {}
};
var _default = SearchActionBanner;
exports["default"] = _default;