"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _emotionTheming = require("emotion-theming");

var _reactSelect = _interopRequireDefault(require("react-select"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var customStyles = function customStyles(theme, error) {
  return {
    control: function control(provided) {
      return _objectSpread({}, provided, {
        height: '45px',
        borderColor: error ? theme.colors.error : theme.colors.lightGray,
        borderRadius: '3px',
        boxShadow: 'none',
        ':hover': {
          borderColor: error ? theme.colors.error : theme.colors.lightGray
        }
      });
    },
    menu: function menu(provided) {
      return _objectSpread({}, provided, {
        borderRadius: '3px'
      });
    },
    valueContainer: function valueContainer(provided) {
      return _objectSpread({}, provided, {
        padding: '0 15px',
        fontSize: '16px'
      });
    },
    input: function input(provided, state) {
      return _objectSpread({}, provided, {
        margin: '0',
        color: state.isDisabled ? theme.colors.gray : theme.colors.text
      });
    },
    placeholder: function placeholder(provided, state) {
      return _objectSpread({}, provided, {
        margin: '0',
        color: state.isDisabled ? theme.colors.gray : theme.colors.text
      });
    },
    option: function option(provided, state) {
      return _objectSpread({}, provided, {
        textAlign: 'left',
        fontSize: '16px',
        backgroundColor: state.isSelected ? theme.colors.primary : state.isFocused ? theme.colors.lightGray : 'none',
        color: state.isSelected ? theme.colors.white : theme.colors.text
      });
    }
  };
};

var InputWrapper = (0, _styled["default"])('div')({
  display: 'inline-block',
  width: '100%',
  minWidth: '100px',
  maxWidth: '300px'
});
var Label = (0, _styled["default"])('label')(function (_ref) {
  var error = _ref.error,
      colors = _ref.theme.colors;
  return {
    display: 'block',
    margin: '0 0 15px 1px',
    fontSize: '15px',
    color: error ? colors.error : colors.text
  };
});

function InputSelect(_ref2) {
  var error = _ref2.error,
      id = _ref2.id,
      label = _ref2.label,
      theme = _ref2.theme,
      options = _ref2.options,
      rest = _objectWithoutProperties(_ref2, ["error", "id", "label", "theme", "options"]);

  return _react["default"].createElement(InputWrapper, null, label && _react["default"].createElement(Label, {
    error: error,
    htmlFor: id
  }, label), _react["default"].createElement(_reactSelect["default"], _extends({
    styles: customStyles(theme, error),
    options: options
  }, rest)));
}

InputSelect.propTypes = {
  /**
   * input value
   */
  label: _propTypes["default"].string,

  /**
   * input ID
   */
  id: _propTypes["default"].string,

  /**
   * error
   */
  error: _propTypes["default"].bool,

  /**
   * onChange function
   */
  onChange: _propTypes["default"].func,

  /**
   * placeholder
   */
  placeholder: _propTypes["default"].string,

  /**
   * label
   */
  options: _propTypes["default"].arrayOf(_propTypes["default"].object),

  /**
   * disabled input
   */
  isDisabled: _propTypes["default"].bool
};
InputSelect.defaultProps = {};

var _default = (0, _emotionTheming.withTheme)(InputSelect);

exports["default"] = _default;