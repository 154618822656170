"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Container = _interopRequireDefault(require("../components/Container"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var AccordionInner = (0, _styled["default"])('div')({
  label: 'AccordionInner',
  background: 'white',
  padding: '30px'
});
var StyledText = (0, _styled["default"])(_Text["default"])({
  label: 'Title',
  marginTop: 0,
  marginBottom: '30px'
});
var StyledParagraph = (0, _styled["default"])(_Text["default"])({
  label: 'Description',
  marginBottom: '30px'
});
var ItemGroup = (0, _styled["default"])('div')({
  label: 'ItemGroup',
  '&:last-child > div': {
    borderBottomWidth: '1px'
  }
});
var ItemLabel = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      isOpen = _ref.isOpen;
  return {
    label: 'ItemLabel',
    padding: '15px 0',
    borderWidth: '1px 0',
    borderTopWidth: '1px',
    borderBottomWidth: isOpen ? '1px' : '0px',
    borderColor: theme.colors.lightGray,
    borderStyle: 'solid',
    position: 'relative',
    cursor: 'pointer'
  };
}); // TODO: animate a better way (resizeObserver to animate the height ?)

var ItemContent = (0, _styled["default"])('div')(function (_ref2) {
  var isOpen = _ref2.isOpen;
  return {
    label: 'ItemContent',
    transition: 'margin-top 0.2s, margin-bottom 0.2s, maxHeight 0.3s, opacity 0.1s',
    marginTop: isOpen ? '30px' : '0',
    marginBottom: isOpen ? '20px' : '0',
    marginLeft: '20px',
    marginRight: '20px',
    maxHeight: isOpen ? '1000px' : '0',
    opacity: isOpen ? 1 : 0
  };
});
var StyledItemContent = (0, _styled["default"])('div')({
  p: {
    marginBottom: '15px'
  }
});
var StyledButton = (0, _styled["default"])(_Button["default"])(function (_ref3) {
  var isOpen = _ref3.isOpen;
  return {
    label: 'StyledButton',
    position: 'absolute',
    width: 'auto',
    right: '0',
    top: '7px',
    border: '0',
    '& > svg': {
      transition: 'transform 0.2s',
      transform: "rotate(".concat(isOpen ? '0' : '180deg', ")")
    }
  };
});
var StyledItemText = (0, _styled["default"])(_Text["default"])({});

var Accordion = function Accordion(_ref4) {
  var title = _ref4.title,
      description = _ref4.description,
      items = _ref4.items;

  var _useState = (0, _react.useState)(-1),
      _useState2 = _slicedToArray(_useState, 2),
      selectedGroup = _useState2[0],
      setSelectedGroup = _useState2[1];

  return _react["default"].createElement(_Container["default"], null, _react["default"].createElement(AccordionInner, null, _react["default"].createElement(StyledText, {
    size: "typo2",
    tag: "h2"
  }, title), _react["default"].createElement(StyledParagraph, {
    tag: "p"
  }, description), items && items.map(function (item, i) {
    return _react["default"].createElement(ItemGroup, {
      key: i
    }, _react["default"].createElement(ItemLabel, {
      isOpen: selectedGroup === i,
      onClick: function onClick() {
        return setSelectedGroup(selectedGroup === i ? -1 : i);
      }
    }, _react["default"].createElement(StyledItemText, {
      size: "typo5"
    }, item.title), _react["default"].createElement(StyledButton, {
      isOpen: selectedGroup === i,
      ghost: true,
      icon: "up"
    })), _react["default"].createElement(ItemContent, {
      isOpen: selectedGroup === i
    }, _react["default"].createElement(StyledItemContent, null, item.content)));
  })));
};

var _default = Accordion;
exports["default"] = _default;