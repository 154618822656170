"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled3 = _interopRequireDefault(require("@emotion/styled"));

var _media = _interopRequireDefault(require("../../theme/media"));

var _Text = _interopRequireDefault(require("../Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Line = (0, _styled3["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme,
      isHover = _ref.isHover;
  return _ref2 = {
    label: 'Line',
    backgroundColor: theme.colors.smallLightGray,
    position: 'relative',
    minHeight: '120px',
    padding: '10px',
    fontSize: '16px',
    lineHeight: '1.56em'
  }, _defineProperty(_ref2, _media["default"].tablet, {
    padding: 0,
    margin: '13px 0',
    display: 'flex',
    justifyContent: 'space-between'
  }), _defineProperty(_ref2, _media["default"].desktop, {
    minHeight: '70px',
    '&:after': {
      content: '""',
      display: 'block',
      transition: 'opacity 0.3s',
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: 1,
      opacity: isHover ? 1 : 0,
      boxShadow: 'rgba(0,0,0,0.2) 0px 4px 18px 0px'
    }
  }), _ref2;
});
var styledInner = {
  transition: 'opacity 0.2s',
  position: 'absolute',
  top: '10px',
  height: 50,
  left: '30px',
  right: '30px',
  lineHeight: '50px',
  display: 'flex',
  justifyContent: 'space-between'
};
var InnerLine = (0, _styled3["default"])('div')(function (_ref3) {
  var isHover = _ref3.isHover;
  return _objectSpread({}, styledInner, {
    opacity: isHover ? 0 : 1
  });
});
var InnerLineDetails = (0, _styled3["default"])('div')(function (_ref4) {
  var isHover = _ref4.isHover;
  return _objectSpread({}, styledInner, {
    opacity: isHover ? 1 : 0
  });
});
var Amount = (0, _styled3["default"])(_Text["default"])(_defineProperty({
  lineHeight: '50px'
}, _media["default"].tablet, {
  textAlign: 'right',
  width: '19%',
  borderLeft: '1px solid #e5e5e5'
}));
var AmountDetail = (0, _styled3["default"])(_Text["default"])({
  label: 'AmountDetail'
});
var LineSection = (0, _styled3["default"])('div')(_defineProperty({}, _media["default"].tablet, {
  display: 'flex',
  alignItems: 'center',
  padding: '0 30px',
  width: '33%',
  justifyContent: 'center',
  ':not(:first-of-type)': {
    borderLeft: '1px solid #E5E5E5'
  }
}));
var FromLabel = (0, _styled3["default"])('div')({
  label: 'FromLabel',
  marginRight: '30px'
});

function RefundLineContent(_ref5) {
  var isHover = _ref5.isHover,
      label = _ref5.label,
      value = _ref5.value,
      items = _ref5.items;
  return _react["default"].createElement(Line, {
    isHover: isHover
  }, _react["default"].createElement(InnerLine, {
    isHover: isHover
  }, _react["default"].createElement(FromLabel, {
    size: "typo4"
  }, label), _react["default"].createElement(Amount, {
    size: "typo3"
  }, value)), _react["default"].createElement(InnerLineDetails, {
    isHover: isHover
  }, items && items.map(function (item, i) {
    return _react["default"].createElement(LineSection, {
      key: i
    }, _react["default"].createElement(FromLabel, {
      size: "typo4"
    }, item.label), _react["default"].createElement(AmountDetail, {
      size: "typo3"
    }, item.value));
  })));
}

RefundLineContent.propTypes = {
  isHover: _propTypes["default"].bool,
  label: _propTypes["default"].string,
  amount: _propTypes["default"].any,
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].any
  }))
};
RefundLineContent.defaultProps = {
  items: []
};
var _default = RefundLineContent;
exports["default"] = _default;