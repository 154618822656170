"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactUse = require("react-use");

var _Text = _interopRequireDefault(require("./Text"));

var _Icon = _interopRequireDefault(require("./Icon"));

var _DataTable = _interopRequireDefault(require("./DataTable"));

var _DataTableRow = _interopRequireDefault(require("./DataTableRow"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var InfoWrapper = (0, _styled["default"])('div')({
  label: 'InfoWrapper',
  position: 'relative',
  cursor: 'pointer',
  marginLeft: '10px'
});
var InfoIcon = (0, _styled["default"])(_Icon["default"])({
  label: 'InfoIcon',
  color: '#5f5f5f'
});
var InfoContent = (0, _styled["default"])('div')({
  label: 'InfoContent',
  position: 'absolute',
  backgroundColor: '#fff',
  padding: '40px 30px',
  minWidth: '250px',
  boxShadow: '0 25px 30px 0 rgba(126, 129, 157, 0.2), 0 0 20px 0 rgba(50, 55, 141, 0.15)',
  top: '100%',
  right: 0
});

function Info(_ref) {
  var children = _ref.children;

  var _React$useState = _react["default"].useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      expand = _React$useState2[0],
      setExpand = _React$useState2[1];

  var ref = _react["default"].useRef(null);

  (0, _reactUse.useClickAway)(ref, function () {
    return setExpand(false);
  });
  return _react["default"].createElement(InfoWrapper, {
    ref: ref,
    onClick: function onClick() {
      return setExpand(!expand);
    }
  }, _react["default"].createElement(InfoIcon, {
    name: "info-circle",
    size: 30
  }), expand ? _react["default"].createElement(InfoContent, null, children) : null);
}

var Wrapper = (0, _styled["default"])('div')(function (_ref2) {
  var theme = _ref2.theme;
  return _defineProperty({
    label: 'Wrapper',
    border: '1px solid #e5e5e5',
    padding: '0',
    backgroundColor: '#fff'
  }, theme.media.laptop, {
    maxWidth: '785px',
    display: 'flex'
  });
});
var Left = (0, _styled["default"])('div')(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty({
    label: 'Left',
    padding: 20
  }, theme.media.laptop, {
    flex: 1,
    marginRight: '30px'
  });
});
var TitleWrapper = (0, _styled["default"])('div')(function (_ref6) {
  var theme = _ref6.theme;
  return _defineProperty({
    label: 'TitleWrapper',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  }, theme.media.laptop, {
    marginBottom: '35px'
  });
});
var Amount = (0, _styled["default"])(_Text["default"])(function (_ref8) {
  var theme = _ref8.theme;
  return _defineProperty({
    label: 'Amount',
    textAlign: 'center'
  }, theme.media.laptop, {
    textAlign: 'left'
  });
});
var Right = (0, _styled["default"])('div')(function (_ref10) {
  var theme = _ref10.theme;
  return _defineProperty({
    label: 'Right',
    padding: '20px'
  }, theme.media.laptop, {
    width: '404px',
    borderLeft: '1px solid #e5e5e5'
  });
});

function BenefitsTable(_ref12) {
  var title = _ref12.title,
      info = _ref12.info,
      refunds = _ref12.refunds,
      amount = _ref12.amount;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Left, null, _react["default"].createElement(TitleWrapper, null, _react["default"].createElement(_Text["default"], {
    size: "typo5"
  }, title), info ? _react["default"].createElement(Info, null, info) : null), _react["default"].createElement(Amount, {
    size: "typo8",
    tag: "span"
  }, amount)), _react["default"].createElement(Right, null, _react["default"].createElement(_DataTable["default"], {
    rows: refunds
  })));
}

BenefitsTable.propTypes = {
  /**
   * Title display above the amount, on the left
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * Amount displays below the title, on the left
   */
  amount: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),

  /**
   * Small piece of text only display when user click on info icon
   */
  info: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),

  /**
   * Array of values that goes into the table on the left
   */
  refunds: _propTypes["default"].arrayOf(_propTypes["default"].shape(_DataTableRow["default"].propTypes))
};
BenefitsTable.defaultProps = {
  // refunds: [],
  amount: '-'
};
var _default = BenefitsTable;
exports["default"] = _default;