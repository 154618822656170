"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = SquareButton;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _media = _interopRequireDefault(require("../theme/media"));

var _utils = require("../theme/utils");

var _Icon = _interopRequireDefault(require("./Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ghostStyle = function ghostStyle() {
  return {
    backgroundColor: '#fff',
    color: 'black',
    borderColor: '#e5e5e5',
    '&:hover': {
      backgroundColor: (0, _utils.darken)('white', 5),
      color: '#555555'
    }
  };
};

var nonGhostStyle = function nonGhostStyle(color, theme) {
  return {
    backgroundColor: color,
    color: (0, _utils.textColor)(color, theme),
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: (0, _utils.darken)(color, 10),
      color: (0, _utils.darken)((0, _utils.textColor)(color, theme), 10),
      borderColor: '#e5e5e5'
    }
  };
};

var Button = (0, _styled["default"])('button')(function (_ref) {
  var ghost = _ref.ghost,
      color = _ref.color,
      theme = _ref.theme;
  return _objectSpread(_defineProperty({
    label: 'square-button',
    border: 0,
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    height: 'auto',
    justifyContent: 'center',
    letterSpacing: '1.1px',
    lineHeight: '1.3',
    maxWidth: '700px',
    MozOsxFontSmoothing: 'grayscale',
    outline: 'none',
    overflow: 'hidden',
    padding: '10px 20px',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    textRendering: 'optimizeLegibility',
    textTransform: 'uppercase',
    transition: 'background-color 0.2s, color 0.2s, border-color 0.2s',
    verticalAlign: 'middle',
    WebkitFontSmoothing: 'antialiased',
    width: '100%',
    zIndex: '0',
    alignItems: 'center'
  }, _media["default"].tablet, {
    width: 160,
    height: 160,
    display: 'flex',
    flexDirection: 'column'
  }), ghost ? ghostStyle(theme.colors[color] || color, theme) : nonGhostStyle(theme.colors[color] || color, theme));
});
var StyledIcon = (0, _styled["default"])(_Icon["default"])({
  label: 'square-button-icon',
  display: 'inline-block',
  width: '100%',
  verticalAlign: 'bottom'
});
var Label = (0, _styled["default"])('span')({
  label: 'square-button-label',
  lineHeight: '20px',
  fontWeight: 400,
  '&:not(:first-child)': {
    marginTop: 15
  }
});

function SquareButton(_ref2) {
  var icon = _ref2.icon,
      children = _ref2.children,
      props = _objectWithoutProperties(_ref2, ["icon", "children"]);

  return _react["default"].createElement(Button, props, icon && _react["default"].createElement(StyledIcon, {
    name: icon,
    size: 40
  }), children && _react["default"].createElement(Label, null, children));
}

SquareButton.propTypes = {
  icon: _Icon["default"].propTypes.name,
  ghost: _propTypes["default"].bool
};