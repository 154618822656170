"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var _default = (0, _styled["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme;
  return _ref2 = {
    label: 'Container',
    margin: '0px auto',
    maxWidth: '100%',
    paddingLeft: '15px',
    paddingRight: '15px'
  }, _defineProperty(_ref2, theme.media.tablet, {
    width: '720px'
  }), _defineProperty(_ref2, theme.media.laptop, {
    width: '960px'
  }), _defineProperty(_ref2, theme.media.desktop, {
    width: '1140px'
  }), _ref2;
});

exports["default"] = _default;