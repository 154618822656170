import React from 'react';
import styled from '@emotion/styled';
import { Button, Container, Text, Paragraph } from 'component-studio';

const Wrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',

  [theme.media.tablet]: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100
  }
}));

const Left = styled('div')({
  padding: 20
});

const ErrorCode = styled('span')({
  fontSize: 72
});

const Right = styled('div')(({ theme }) => ({
  padding: '0 20px',
  [theme.media.tablet]: {
    borderLeft: `3px solid ${theme.colors.secondary}`
  }
}));

const Content = styled(Paragraph)({
  marginBottom: 20
});

const Action = styled('div')({});

// TODO better not found state
function NotFound() {
  return (
    <Container>
      <Wrapper>
        <Left>
          <ErrorCode>404</ErrorCode>
        </Left>
        <Right>
          <Text title="Not Found" mb={20}>
            Not Found
          </Text>
          <Content content="The page you are looking for does not exists ou has been removed" />
          <Action>
            <Button href="/">Back to home</Button>
          </Action>
        </Right>
      </Wrapper>
    </Container>
  );
}

export default NotFound;
