"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _emotionTheming = require("emotion-theming");

var _BudgetRepartitionChart = _interopRequireDefault(require("./BudgetRepartitionChart"));

var _BudgetRepartitionTable = _interopRequireDefault(require("./BudgetRepartitionTable"));

var _Text = _interopRequireDefault(require("../Text"));

var _SimpleButton = _interopRequireDefault(require("../SimpleButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'SavingCard',
    backgroundColor: theme.colors.white,
    border: '1px solid #f3f3f3',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0 1px 10px 0',
    padding: '15px 20px',
    width: '100%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:not(:last-child)': {
      marginBottom: '30px'
    }
  }, theme.media.tablet, {
    marginLeft: 0,
    marginRight: 0,
    padding: '30px 40px',
    '&:not(:last-child)': {
      marginRight: '30px',
      marginBottom: 0
    }
  });
});
var Title = (0, _styled["default"])(_Text["default"])({
  label: 'title',
  marginBottom: '30px'
});
var ChartWrapper = (0, _styled["default"])('div')({
  label: 'chart-wrapper',
  marginBottom: '60px'
});
var Actions = (0, _styled["default"])('div')({
  label: 'actions',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '30px'
});

function BudgetRepartitionCard(_ref3) {
  var title = _ref3.title,
      chartLabel = _ref3.chartLabel,
      savings = _ref3.savings,
      actions = _ref3.actions,
      currency = _ref3.currency,
      locale = _ref3.locale;
  return _react["default"].createElement(Wrapper, null, title ? _react["default"].createElement(Title, {
    size: "typo4"
  }, title) : null, _react["default"].createElement(ChartWrapper, null, _react["default"].createElement(_BudgetRepartitionChart["default"], {
    label: chartLabel,
    savings: savings,
    currency: currency,
    locale: locale
  })), _react["default"].createElement(_BudgetRepartitionTable["default"], {
    savings: savings,
    currency: currency,
    locale: locale
  }), actions.length > 0 ? _react["default"].createElement(Actions, null, actions.map(function (action, i) {
    return _react["default"].createElement(_SimpleButton["default"], {
      href: action.url,
      key: i
    }, action.label);
  })) : null);
}

BudgetRepartitionCard.propTypes = {
  title: _propTypes["default"].string,
  chartLabel: _propTypes["default"].string,
  savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].number,
    colorKey: _propTypes["default"].string
  })),
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),

  /**
   * currency of the value
   */
  currency: _propTypes["default"].string.isRequired,

  /**
   * Locale used to format the money
   */
  locale: _propTypes["default"].string.isRequired
};
BudgetRepartitionCard.defaultProps = {
  savings: [],
  actions: []
};

var _default = (0, _emotionTheming.withTheme)(BudgetRepartitionCard);

exports["default"] = _default;