"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Link;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * Theses props may be passed by parents for styling reason, but we don't want them to be put in the HTML
 * because they throw a warning message.
 */


var PROPS_BLACKLIST = ['ghost', 'square', 'hasLabel', 'loading'];
/**
 * LinkContext allow users of this library to use the component they want as a link. By defaut we use 'a', but
 * they could use 'button', or Link from react-router, or add some function on the click, like analytics tracking.
 */

var LinkContext = _react["default"].createContext('a');

var LinkProvider = function LinkProvider(_ref) {
  var _ref$element = _ref.element,
      element = _ref$element === void 0 ? 'a' : _ref$element,
      children = _ref.children;
  return _react["default"].createElement(LinkContext.Provider, {
    value: element
  }, children);
};

LinkProvider.propTypes = {
  element: _propTypes["default"].oneOfType([_propTypes["default"].element, _propTypes["default"].func, _propTypes["default"].string])
};

function Link(_ref2) {
  var href = _ref2.href,
      to = _ref2.to,
      props = _objectWithoutProperties(_ref2, ["href", "to"]);

  var Element = _react["default"].useContext(LinkContext);

  if (to) {
    // eslint-disable-next-line no-console
    console.warn('to is deprecated and will be remove in future version. Use href instead.');
  }

  return _react["default"].createElement(Element, _extends({
    href: href || to
  }, (0, _lodash.omit)(props, PROPS_BLACKLIST)));
}

Link.propTypes = {
  href: _propTypes["default"].string
};
Link.ContextProvider = LinkProvider;