"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _PieChart = _interopRequireDefault(require("../components/PieChart"));

var _DataTable = _interopRequireDefault(require("../components/DataTable"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Wrapper = (0, _styled["default"])('div')({
  paddingTop: 50,
  paddingBottom: 50
});
var Title = (0, _styled["default"])('div')({
  fontSize: 36,
  fontWeight: 600,
  lineHeight: 1.56,
  marginBottom: 30
});
var Box = (0, _styled["default"])('div')({
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  padding: 40,
  border: '1px solid #f3f3f3',
  boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.04)'
});
var PieWrapper = (0, _styled["default"])('div')({
  paddingLeft: 40,
  paddingRight: 80
});
var TableWrapper = (0, _styled["default"])('div')({
  flex: 1
});

function CommissionsRepartition(_ref) {
  var title = _ref.title,
      chart = _ref.chart,
      table = _ref.table;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, null, title) : null, _react["default"].createElement(Box, null, _react["default"].createElement(PieWrapper, null, _react["default"].createElement(_PieChart["default"], _extends({}, chart, {
    size: 230
  }))), _react["default"].createElement(TableWrapper, null, _react["default"].createElement(_DataTable["default"], table)))));
}

CommissionsRepartition.propTypes = {
  /**
   * Slice title
   */
  title: _propTypes["default"].string,

  /**
   * Props that will be pass to piechart
   */
  chart: _propTypes["default"].shape(_PieChart["default"].propTypes),

  /**
   * Props that will be pass to DataTable
   */
  table: _propTypes["default"].shape(_DataTable["default"].propTypes)
};
CommissionsRepartition.defaultProps = {
  chart: {},
  table: {}
};
var _default = CommissionsRepartition;
exports["default"] = _default;