"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactSpring = require("react-spring");

var _ActionBanner = _interopRequireDefault(require("../slices/ActionBanner"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _ServiceCard = _interopRequireDefault(require("../components/ServiceCard"));

var _Checkboxes = _interopRequireDefault(require("../components/forms/Checkboxes"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ActionBannerStyled = (0, _styled["default"])(_ActionBanner["default"])({
  marginBottom: 30
});
var Cards = (0, _styled["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme,
      toDisplay = _ref.toDisplay;
  return _ref2 = {
    marginLeft: '-15px',
    marginRight: '-15px'
  }, _defineProperty(_ref2, theme.media.laptop, {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  }), _defineProperty(_ref2, theme.media.desktop, {
    '&:after': {
      content: toDisplay % 3 === 2 ? '""' : null,
      width: '350px'
    }
  }), _ref2;
}); // use hooks as soon as react-spring with hooks is stable
// function AnimatedCards({ cards }) {
//   const transitions = useTransition({
//     items: cards,
//     keys: item => item.title + item.category,
//     from: { opacity: 0 },
//     enter: { opacity: 1 },
//     leave: { opacity: 0 },
//     config: { duration: 200 }
//   });
//   return transitions.map(({ item, key, props }) => (
//     <ServiceCard {...item} key={key} style={props} />
//   ));
// }

var AnimatedCards = function AnimatedCards(_ref3) {
  var cards = _ref3.cards;
  return _react["default"].createElement(_reactSpring.Transition, {
    "native": true,
    items: cards,
    keys: function keys(item) {
      return item.key;
    },
    from: {
      opacity: 0,
      y: 10
    },
    enter: {
      opacity: 1,
      y: 0
    },
    leave: {
      opacity: 0
    },
    config: {
      duration: 200
    }
  }, function (item) {
    return function (_ref4) {
      var opacity = _ref4.opacity,
          y = _ref4.y;
      return _react["default"].createElement(_ServiceCard["default"], _extends({}, item, {
        style: {
          opacity: opacity,
          transform: y.interpolate(function (y) {
            return "translate3d(0px,".concat(y, "px,0)");
          })
        }
      }));
    };
  });
};

var StyledHeading = (0, _styled["default"])(_Text["default"])({
  display: 'inline',
  marginRight: '15px'
});
var FilterWrapper = (0, _styled["default"])('div')({
  marginBottom: '15px'
});

function Filters(_ref5) {
  var categories = _ref5.categories,
      _onChange = _ref5.onChange,
      filterLabel = _ref5.filterLabel;
  return _react["default"].createElement(FilterWrapper, null, filterLabel ? _react["default"].createElement(StyledHeading, {
    tag: "span",
    size: "typo5"
  }, filterLabel) : null, _react["default"].createElement("span", null, _react["default"].createElement(_Checkboxes["default"], {
    color: "secondary",
    onChange: function onChange(_ref6) {
      var value = _ref6.value;
      return _onChange(value);
    },
    name: "categories",
    options: categories
  })));
}

var ServicesWrapper = (0, _styled["default"])('div')({
  marginBottom: 50
});

function ServiceSelector(_ref7) {
  var title = _ref7.title,
      categories = _ref7.categories,
      cards = _ref7.cards,
      filters = _ref7.filters,
      filterLabel = _ref7.filterLabel,
      initialActiveCategories = _ref7.activeCategories;

  var _useState = (0, _react.useState)(initialActiveCategories),
      _useState2 = _slicedToArray(_useState, 2),
      activeCategories = _useState2[0],
      setActiveCategories = _useState2[1];

  var activeCards = activeCategories.length > 0 ? cards.filter(function (card) {
    return activeCategories.includes(card.category);
  }) : cards;
  return _react["default"].createElement(ServicesWrapper, null, _react["default"].createElement(ActionBannerStyled, {
    title: title
  }), _react["default"].createElement(_Container["default"], null, filters ? _react["default"].createElement(Filters, {
    filterLabel: filterLabel,
    categories: categories.map(function (category) {
      return {
        value: category,
        label: category,
        checked: activeCategories.includes(category)
      };
    }),
    onChange: setActiveCategories
  }) : null, _react["default"].createElement(Cards, {
    toDisplay: activeCards.length
  }, _react["default"].createElement(AnimatedCards, {
    cards: activeCards
  }))));
}

ServiceSelector.propTypes = {
  title: _propTypes["default"].string,
  categories: _propTypes["default"].arrayOf(_propTypes["default"].string),
  activeCategories: _propTypes["default"].arrayOf(_propTypes["default"].string),
  cards: _propTypes["default"].arrayOf(_propTypes["default"].shape(_ServiceCard["default"].propTypes)).isRequired,
  filters: _propTypes["default"].bool
};
ServiceSelector.defaultProps = {
  categories: [],
  cards: [],
  filters: true,
  activeCategories: []
};
var _default = ServiceSelector;
exports["default"] = _default;