"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var CardWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'CardWrapper',
    textAlign: 'center',
    '&:not(:last-of-type)': _defineProperty({
      marginBottom: '70px'
    }, theme.media.tablet, {
      marginBottom: '60px'
    })
  }, theme.media.laptop, {
    display: 'flex',
    textAlign: 'left',
    '&:nth-of-type(odd) > :first-of-type': {
      marginLeft: '60px'
    },
    '&:nth-of-type(even) > :first-of-type': {
      marginRight: '60px'
    },
    '&:nth-of-type(odd)': {
      flexDirection: 'row-reverse'
    }
  });
});
var ImageWrapper = (0, _styled["default"])('div')(function (_ref3) {
  var _ref4;

  var theme = _ref3.theme;
  return _ref4 = {
    position: 'relative',
    label: 'ImageWrapper',
    marginBottom: '20px',
    minHeight: '200px'
  }, _defineProperty(_ref4, theme.media.tablet, {
    marginBottom: '50px'
  }), _defineProperty(_ref4, theme.media.laptop, {
    width: '50%',
    marginBottom: 0
  }), _ref4;
});
var ContentWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    label: 'contentWrapper',
    textAlign: 'center',
    padding: '0 15px 15px',
    color: theme.colors.text
  }, theme.media.laptop, {
    maxWidth: '47%',
    padding: 0,
    display: 'table'
  });
});
var ContentInner = (0, _styled["default"])('div')({
  display: 'table-cell'
});
var TitleWrapper = (0, _styled["default"])(_Text["default"])(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({
    marginBottom: '30px'
  }, theme.media.laptop, {
    marginBottom: '40px',
    textAlign: ' left'
  });
});
var TextWrapper = (0, _styled["default"])(_Text["default"])({
  textAlign: 'justify'
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref9) {
  var _ref10;

  var theme = _ref9.theme;
  return _ref10 = {
    marginTop: '25px'
  }, _defineProperty(_ref10, theme.media.tablet, {
    marginTop: '30px'
  }), _defineProperty(_ref10, theme.media.laptop, {
    marginTop: '40px',
    textAlign: 'left'
  }), _ref10;
});
var StyledImg = (0, _styled["default"])('img')({
  width: '100%'
});

function Card(_ref11) {
  var image = _ref11.image,
      title = _ref11.title,
      text = _ref11.text,
      action = _ref11.action;
  return _react["default"].createElement(CardWrapper, null, _react["default"].createElement(ImageWrapper, null, _react["default"].createElement(StyledImg, {
    src: image.src,
    alt: image.alt
  })), _react["default"].createElement(ContentWrapper, null, _react["default"].createElement(ContentInner, null, _react["default"].createElement(TitleWrapper, {
    tag: "h3",
    size: "typo2"
  }, title), _react["default"].createElement(TextWrapper, {
    tag: "p"
  }, text), action ? _react["default"].createElement(ActionWrapper, null, _react["default"].createElement(_Button["default"], {
    href: action.url
  }, action.label)) : null)));
}

Card.propTypes = {
  image: _propTypes["default"].shape({
    src: _propTypes["default"].string.isRequired,
    alt: _propTypes["default"].string
  }),
  title: _propTypes["default"].string.isRequired,
  text: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].object]),
  action: _propTypes["default"].shape({
    target: _propTypes["default"].string,
    label: _propTypes["default"].string
  })
};
var Wrapper = (0, _styled["default"])('div')(function (_ref12) {
  var _ref13;

  var theme = _ref12.theme;
  return _ref13 = {
    paddingTop: '50px',
    paddingBottom: '50px',
    backgroundColor: '#fff'
  }, _defineProperty(_ref13, theme.media.tablet, {
    paddingTop: '80px',
    paddingBottom: '80px'
  }), _defineProperty(_ref13, theme.media.laptop, {
    paddingTop: '100px',
    paddingBottom: '100px'
  }), _ref13;
});

function AsideCards(_ref14) {
  var cards = _ref14.cards;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, cards.map(function (card, i) {
    return _react["default"].createElement(Card, _extends({}, card, {
      key: i
    }));
  })));
}

AsideCards.propTypes = {
  /**
   * Array of cards
   */
  cards: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    image: _propTypes["default"].shape({
      src: _propTypes["default"].string.isRequired,
      alt: _propTypes["default"].string
    }),
    title: _propTypes["default"].string.isRequired,
    text: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].object]),
    action: _propTypes["default"].shape({
      target: _propTypes["default"].string,
      label: _propTypes["default"].string
    })
  })).isRequired
};
AsideCards.defaultProps = {
  cards: []
};
var _default = AsideCards;
exports["default"] = _default;