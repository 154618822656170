"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _SimpleButton = _interopRequireDefault(require("../components/SimpleButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // Main div that wrap the whole slice


var Wrapper = (0, _styled["default"])('div')({
  label: 'wrapper',
  paddingTop: 70,
  paddingBottom: 100
}); // Slice title

var Title = (0, _styled["default"])(_Text["default"])({
  label: 'title',
  marginBottom: 50
}); // Container separate in 2 pieces

var Card = (0, _styled["default"])('div')({
  label: 'card',
  backgroundColor: '#fff',
  boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.04)',
  display: 'flex'
}); // Left part of the card, with texts and links

var Left = (0, _styled["default"])('div')({
  label: 'left',
  flex: '1 0 53%',
  padding: 30
}); // Title inside the card

var CardTitle = (0, _styled["default"])(_Text["default"])({
  label: 'second title',
  marginBottom: 40
}); // List of value + labels

var Items = (0, _styled["default"])('ul')({
  label: 'items',
  listStyleType: 'none',
  margin: 0,
  paddingLeft: 0
}); // Container for value + label

var Item = (0, _styled["default"])('li')({
  label: 'item',
  '&:not(:last-child)': {
    marginBottom: 30
  }
}); // Main information of the card

var ItemValue = (0, _styled["default"])('span')({
  label: 'value',
  fontSize: 48,
  fontWeight: 600,
  letterSpacing: '1.2px',
  marginRight: 10
}); // Indicate the type if the value, display just after it

var ItemLabel = (0, _styled["default"])('span')({
  label: 'label',
  fontSize: 16,
  lineHeight: 1.56
}); // Wrap the link

var LinkWrapper = (0, _styled["default"])('div')({
  label: 'link',
  marginTop: 50
}); // Right part of the card, with a background and the image

var Right = (0, _styled["default"])('div')(function (_ref) {
  var color = _ref.color,
      theme = _ref.theme;
  return _defineProperty({
    label: 'right',
    flex: '1 0 47%',
    backgroundColor: theme.colors[color] || color,
    padding: 30,
    position: 'relative',
    display: 'none',
    overflow: 'hidden'
  }, theme.media.tablet, {
    display: 'block'
  });
}); // Image element

var Image = (0, _styled["default"])('img')({
  label: 'img',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 330,
  maxHeight: 300
});

function Absenteeism(_ref3) {
  var title = _ref3.title,
      color = _ref3.color,
      image = _ref3.image,
      cardTitle = _ref3.cardTitle,
      items = _ref3.items,
      link = _ref3.link;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(Title, {
    tag: "h2",
    size: "typo1"
  }, title), _react["default"].createElement(Card, null, _react["default"].createElement(Left, null, cardTitle ? _react["default"].createElement(CardTitle, {
    tag: "h3"
  }, cardTitle) : null, items.length > 0 ? _react["default"].createElement(Items, null, items.map(function (item, key) {
    return _react["default"].createElement(Item, {
      key: key
    }, _react["default"].createElement(ItemValue, {
      "data-cy": item.label
    }, item.value), _react["default"].createElement(ItemLabel, null, item.label));
  })) : null, link ? _react["default"].createElement(LinkWrapper, null, _react["default"].createElement(_SimpleButton["default"], {
    href: link.url,
    tracker: "absenteism"
  }, link.label)) : null), _react["default"].createElement(Right, {
    color: color
  }, image ? _react["default"].createElement(Image, {
    src: image.url,
    alt: image.alt
  }) : null))));
}

Absenteeism.propTypes = {
  /**
   * Slice title
   */
  title: _propTypes["default"].string,

  /**
   * Backgorund color behind the image, on the right
   * of the slice
   */
  color: _propTypes["default"].string,

  /**
   * Image display in the middle of the right part
   */
  image: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    alt: _propTypes["default"].string
  }),

  /**
   * Title of the left part, above the items
   */
  cardTitle: _propTypes["default"].string,

  /**
   * Array of items, display on the left part
   */
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    value: _propTypes["default"].any,
    label: _propTypes["default"].any
  })),

  /**
   * Link properties, display below the items
   */
  link: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })
};
Absenteeism.defaultProps = {
  color: 'transparent',
  items: []
};
var _default = Absenteeism;
exports["default"] = _default;