"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _recharts = require("recharts");

var _Currency = _interopRequireDefault(require("../../components/Currency"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  width: '200px',
  height: '200px',
  margin: 'auto'
});

function SavingGraph(_ref) {
  var label = _ref.label,
      amounts = _ref.amounts,
      currency = _ref.currency,
      locale = _ref.locale,
      theme = _ref.theme;
  var total = amounts.reduce(function (total, _ref2) {
    var amount = _ref2.amount;
    return total + amount;
  }, 0);
  var size = 200;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_recharts.PieChart, {
    width: size,
    height: size
  }, label ? _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 - 15,
    textAnchor: "middle",
    fill: theme.colors.label,
    fontSize: "13px"
  }, label) : null, _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 + (label ? 24 : 12),
    textAnchor: "middle",
    fill: theme.colors.text,
    fontSize: "25px",
    fontWeight: "bold"
  }, _react["default"].createElement(_Currency["default"], {
    value: total,
    currency: currency,
    locale: locale
  })), _react["default"].createElement(_recharts.Pie, {
    data: amounts,
    dataKey: "amount",
    innerRadius: "85%",
    outerRadius: "100%",
    animationDuration: 1000,
    AnimationEasing: 'ease-out'
  }, amounts.map(function (amount) {
    return _react["default"].createElement(_recharts.Cell, {
      key: amount.colorKey,
      fill: theme.colors[amount.colorKey]
    });
  }))));
}

SavingGraph.propTypes = {
  label: _propTypes["default"].string.isRequired,
  amounts: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    colorKey: _propTypes["default"].string.isRequired,
    amount: _propTypes["default"].number.isRequired
  })),
  currency: _propTypes["default"].string.isRequired,
  locale: _propTypes["default"].string.isRequired
};
SavingGraph.defaultProps = {
  amounts: []
};
var _default = SavingGraph;
exports["default"] = _default;