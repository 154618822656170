"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Currency = _interopRequireDefault(require("../Currency"));

var _Text = _interopRequireDefault(require("../Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var List = (0, _styled["default"])('ul')(function (_ref) {
  var theme = _ref.theme;
  return {
    label: 'saving-list',
    border: "1px solid ".concat(theme.colors.lightGray),
    margin: 0,
    padding: 0
  };
});
var Saving = (0, _styled["default"])('li')(function (_ref2) {
  var theme = _ref2.theme;
  return {
    label: 'Saving',
    margin: 0,
    backgroundColor: theme.colors.smallLightGray,
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderBottom: "1px solid ".concat(theme.colors.lightGray)
    }
  };
});
var CurrencyWrapper = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme,
      color = _ref3.color;
  return {
    label: 'currency',
    color: theme.colors[color] || color
  };
});

function BudgetRepartitionTable(_ref4) {
  var savings = _ref4.savings,
      currency = _ref4.currency,
      locale = _ref4.locale;
  return _react["default"].createElement(List, null, savings.map(function (_ref5, i) {
    var label = _ref5.label,
        value = _ref5.value,
        color = _ref5.color;
    return _react["default"].createElement(Saving, {
      key: i
    }, _react["default"].createElement(_Text["default"], null, label), _react["default"].createElement(CurrencyWrapper, {
      size: "typo4",
      color: color
    }, _react["default"].createElement(_Currency["default"], {
      value: value,
      currency: currency,
      locale: locale
    })));
  }));
}

BudgetRepartitionTable.propTypes = {
  savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].number,
    color: _propTypes["default"].string
  })),

  /**
   * currency of the value
   */
  currency: _propTypes["default"].string.isRequired,

  /**
   * Locale used to format the money
   */
  locale: _propTypes["default"].string.isRequired
};
BudgetRepartitionTable.defaultProps = {
  savings: []
};
var _default = BudgetRepartitionTable;
exports["default"] = _default;