"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _LargeCard = _interopRequireDefault(require("../components/LargeCard"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var background = _ref.background,
      theme = _ref.theme;
  return {
    backgroundColor: theme.colors.background || background,
    paddingTop: 70,
    paddingBottom: 70
  };
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: 45
});
var Content = (0, _styled["default"])(_Text["default"])({
  marginBottom: 50
});
var CardWrapper = (0, _styled["default"])('div')({
  marginBottom: 50
});

function LargeCards(_ref2) {
  var title = _ref2.title,
      content = _ref2.content,
      cards = _ref2.cards,
      background = _ref2.background;
  return _react["default"].createElement(Wrapper, {
    background: background
  }, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, {
    tag: "h2"
  }, title) : null, content ? _react["default"].createElement(Content, null, content) : null, _react["default"].createElement("div", null, cards.map(function (card, i) {
    return _react["default"].createElement(CardWrapper, {
      key: i
    }, _react["default"].createElement(_LargeCard["default"], card));
  }))));
}

LargeCards.propTypes = {
  title: _propTypes["default"].string,
  content: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),
  cards: _propTypes["default"].arrayOf(_propTypes["default"].shape(_LargeCard["default"].propTypes)),
  background: _propTypes["default"].string
};
LargeCards.defaultProps = {
  cards: [],
  background: '#fff'
};
var _default = LargeCards;
exports["default"] = _default;