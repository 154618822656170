"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Checkboxes = _interopRequireDefault(require("../components/forms/Checkboxes"));

var _Radios = _interopRequireDefault(require("../components/forms/Radios"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FilterLabel = (0, _styled["default"])('span')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    marginRight: '10px',
    marginBottom: '15px',
    display: 'block'
  }, theme.media.tablet, {
    display: 'initial'
  });
});
var Nav = (0, _styled["default"])('nav')({
  marginBottom: '10px'
});
var SimpleFilterGroup = (0, _styled["default"])('div')({
  marginTop: '30px',
  marginBottom: '10px'
});

var FilterGroup = function FilterGroup(_ref3) {
  var _ref3$all = _ref3.all,
      all = _ref3$all === void 0 ? false : _ref3$all,
      _ref3$multiple = _ref3.multiple,
      multiple = _ref3$multiple === void 0 ? true : _ref3$multiple,
      groupId = _ref3.groupId,
      label = _ref3.label,
      items = _ref3.items,
      onFilter = _ref3.onFilter;

  var onChange = function onChange(_ref4) {
    var value = _ref4.value;
    return onFilter(value === 'all' ? '' : {
      value: value
    });
  };

  var currentItems = all ? [{
    value: '',
    label: 'Tous'
  }].concat(_toConsumableArray(items)) : items;
  return _react["default"].createElement(Nav, null, _react["default"].createElement(FilterLabel, {
    "data-cy": "typeContract"
  }, label), _react["default"].createElement("span", null, multiple ? _react["default"].createElement(_Checkboxes["default"], {
    name: groupId,
    onChange: onChange,
    options: currentItems
  }) : _react["default"].createElement(_Radios["default"], {
    name: groupId,
    onChange: onChange,
    options: currentItems
  })));
};

var SimpleFilters = function SimpleFilters(_ref5) {
  var all = _ref5.all,
      filters = _ref5.filters,
      onFilter = _ref5.onFilter;
  return filters.length > 0 ? _react["default"].createElement(_Container["default"], null, _react["default"].createElement(SimpleFilterGroup, null, filters && filters.map(function (item, i) {
    return _react["default"].createElement(FilterGroup, {
      all: all,
      key: i + item.id,
      multiple: item.multiple,
      groupId: item.id,
      label: item.label,
      items: item.filters,
      onFilter: onFilter
    });
  }))) : null;
};

SimpleFilters.propTypes = {
  filters: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    id: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),
  onFilter: _propTypes["default"].func
};
SimpleFilters.defaultProps = {
  filters: [],
  onFilter: function onFilter() {}
};
var _default = SimpleFilters;
exports["default"] = _default;