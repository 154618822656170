"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FourCardsContainer = (0, _styled["default"])(_Container["default"])({
  label: 'FourCard',
  paddingTop: '50px',
  paddingBottom: '50px'
});
var FourCardsText = (0, _styled["default"])(_Text["default"])({
  marginBottom: '30px'
});
var CardsContainer = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({}, theme.media.desktop, {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  });
});
var Links = (0, _styled["default"])('div')({
  marginTop: 20
});
var ButtonWrapper = (0, _styled["default"])('div')({
  '&:not(:last-child)': {
    marginBottom: 20
  }
});

var FourCards = function FourCards(_ref3) {
  var title = _ref3.title,
      cards = _ref3.cards,
      _ref3$links = _ref3.links,
      links = _ref3$links === void 0 ? [] : _ref3$links;
  return _react["default"].createElement(FourCardsContainer, null, _react["default"].createElement(FourCardsText, {
    size: "typo2",
    tag: "h2"
  }, title), _react["default"].createElement(CardsContainer, null, cards && cards.length > 0 && cards.map(function (c, i) {
    return _react["default"].createElement(SingleCard, {
      key: i,
      image: c.image,
      title: c.title,
      text: c.text,
      links: c.links
    });
  })), links.length > 0 ? _react["default"].createElement(Links, null, links.map(function (link, key) {
    return _react["default"].createElement(ButtonWrapper, {
      key: key
    }, _react["default"].createElement(_Button["default"], {
      href: link.url,
      ghost: true,
      icon: "download",
      iconPosition: "left"
    }, link.label));
  })) : null);
};

var SingleCardWrapper = (0, _styled["default"])('div')(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty({
    label: 'SingleCard',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '5px',
    overflow: 'hidden',
    border: "1px solid ".concat(theme.colors.lightGray),
    marginBottom: '30px',
    minHeight: '380px'
  }, theme.media.desktop, {
    flex: '0 1 48%',
    minHeight: '200px'
  });
});
var CardImage = (0, _styled["default"])('img')(function (_ref6) {
  var theme = _ref6.theme;
  return _defineProperty({
    label: 'CardImage',
    margin: '0 auto',
    display: 'block',
    width: '100%',
    marginBottom: '30px'
  }, theme.media.desktop, {
    marginBottom: 0
  });
});
var SingleCardInner = (0, _styled["default"])('div')(function (_ref8) {
  var theme = _ref8.theme;
  return _defineProperty({
    label: 'SingleCardInner'
  }, theme.media.desktop, {
    display: 'flex'
  });
});
var ContentWrapper = (0, _styled["default"])('div')(function (_ref10) {
  var theme = _ref10.theme;
  return _defineProperty({
    label: 'ContentWrapper',
    margin: '20px',
    color: theme.colors.text
  }, theme.media.desktop, {
    margin: '0',
    width: '55%',
    padding: '30px'
  });
});
var HeadingWrapper = (0, _styled["default"])(_Text["default"])({
  marginBottom: '15px'
});
var TextWrapper = (0, _styled["default"])(_Text["default"])({
  display: 'block',
  label: 'TextWrapper',
  marginBottom: '30px'
});
var ImageWrapper = (0, _styled["default"])('div')(function (_ref12) {
  var theme = _ref12.theme;
  return _defineProperty({
    label: 'ImageWrapper'
  }, theme.media.desktop, {
    width: '45%'
  });
}); // Links

var LinksContainer = (0, _styled["default"])('div')(function (_ref14) {
  var theme = _ref14.theme;
  return _defineProperty({
    label: 'LinkContainer',
    paddingLeft: 0,
    maxWidth: '45%'
  }, theme.media.desktop, {
    position: 'relative',
    maxWidth: 'none',
    bottom: 'initial'
  });
});
var LinkButtonWrapper = (0, _styled["default"])(_Button["default"])({
  label: 'LinkButtonWrapper',
  textTransform: 'uppercase',
  marginBottom: '20px',
  fontWeight: 'bold',
  fontSize: '13px',
  '&:last-child': {
    marginBottom: 0
  }
});

var SingleCard = function SingleCard(_ref16) {
  var image = _ref16.image,
      title = _ref16.title,
      text = _ref16.text,
      links = _ref16.links;
  var mobileImage = image && image.mobile && image.mobile.url;
  var tabletImage = image && image.tablet && image.tablet.url;
  var desktopImage = image && image.desktop && image.desktop.url;
  return _react["default"].createElement(SingleCardWrapper, null, _react["default"].createElement(SingleCardInner, null, _react["default"].createElement(ImageWrapper, null, _react["default"].createElement("picture", null, mobileImage ? _react["default"].createElement("source", {
    media: '(max-width: 576px)',
    srcSet: mobileImage
  }) : null, tabletImage ? _react["default"].createElement("source", {
    media: "(max-width: 1199px)",
    srcSet: tabletImage
  }) : null, _react["default"].createElement(CardImage, {
    src: desktopImage || image.url,
    alt: image.alt
  }))), _react["default"].createElement(ContentWrapper, null, title ? _react["default"].createElement(HeadingWrapper, {
    size: "typo3",
    tag: "h4"
  }, title) : null, text ? _react["default"].createElement(TextWrapper, null, text) : null, links && links.length > 0 ? _react["default"].createElement(LinksContainer, null, links.map(function (_ref17, i) {
    var url = _ref17.url,
        label = _ref17.label;
    return url && label ? _react["default"].createElement(LinkButtonWrapper, {
      key: i,
      href: url
    }, label) : null;
  })) : null)));
};

var imagePropType = _propTypes["default"].shape({
  src: _propTypes["default"].string,
  alt: _propTypes["default"].string,
  dimensions: _propTypes["default"].shape({
    width: _propTypes["default"].number,
    height: _propTypes["default"].number
  })
});

FourCards.propTypes = {
  /**
   * Title as H2 above the cards
   */
  title: _propTypes["default"].string,

  /**
   * Array of cards
   */
  cards: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    image: _propTypes["default"].shape({
      src: _propTypes["default"].string,
      alt: _propTypes["default"].string,
      dimensions: _propTypes["default"].shape({
        width: _propTypes["default"].number,
        height: _propTypes["default"].number
      }),
      mobile: imagePropType,
      tablet: imagePropType,
      desktop: imagePropType
    }),
    title: _propTypes["default"].string,
    text: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),
    action: _propTypes["default"].shape({
      url: _propTypes["default"].string,
      label: _propTypes["default"].string
    })
  })).isRequired
};
FourCards.defaultProps = {
  cards: []
};
var _default = FourCards;
exports["default"] = _default;