"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var colors = {
  // Main colors
  primary: '#005792',
  // OK
  secondary: '#F0A202',
  // OK
  // Text colors
  text: '#333333',
  // OK
  third: '#36A9E1',
  fourth: '#33B4AE',
  fifth: '#C391BD',
  sixth: '#FBF2C0',
  seventh: '#CE6153',
  label: '#999999',
  // status
  error: '#C91432',
  // OK
  success: '#1CC54E',
  // OK
  warning: '#FCD385',
  // OK
  // icon backgrounds
  background1: '#b5d0ee',
  background2: '#fcd385',
  background3: '#f1afc6',
  background4: '#9fd9b4',
  // Chart colors
  chartColor1: '#025275',
  chartColor2: '#00adc6',
  chartColor3: '#56d1c7',
  // Common colors
  white: '#ffffff',
  // (50) shades of grays
  smallLightGray: '#fafafa',
  lighterGray: '#f3f3f3',
  lightGray: '#e5e5e5',
  gray: '#cccccc',
  darkGray: '#989898',
  black: '#000000'
};
var _default = colors;
exports["default"] = _default;