"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  paddingTop: 60,
  paddingBottom: 60
});
var Title = (0, _styled["default"])(_Text["default"])({
  display: 'block',
  marginBottom: 30,
  borderBottom: '1px solid #979797'
});

function EditorialContent(_ref) {
  var title = _ref.title,
      children = _ref.children;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, {
    size: "typo2",
    tag: "h2"
  }, title) : null, _react["default"].createElement(_Text["default"], null, children)));
}

EditorialContent.propTypes = {
  title: _propTypes["default"].string
};
EditorialContent.defaultProps = {};
var _default = EditorialContent;
exports["default"] = _default;