"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var LangWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      current = _ref.current;
  return {
    flex: 1,
    cursor: 'pointer',
    color: current ? theme.colors.primary : '#999',
    textAlign: 'center',
    '&:not(:last-child)': {
      borderRight: '2px solid #999'
    }
  };
});

function Lang(_ref2) {
  var label = _ref2.label,
      current = _ref2.current,
      onClick = _ref2.onClick;
  return _react["default"].createElement(LangWrapper, {
    onClick: onClick,
    current: current
  }, label);
}

var Wrapper = (0, _styled["default"])('div')({
  padding: '14px 20px',
  backgroundColor: '#eee',
  display: 'flex',
  justifyContent: 'center'
});

function MobileLang(_ref3) {
  var current = _ref3.current,
      available = _ref3.available,
      onChange = _ref3.onChange;
  return _react["default"].createElement(Wrapper, null, available.map(function (lang) {
    return _react["default"].createElement(Lang, {
      key: lang.id,
      label: lang.shortLabel,
      current: lang.id === current.id,
      onClick: function onClick() {
        return onChange(lang.id);
      }
    });
  }));
}

var _default = MobileLang;
exports["default"] = _default;