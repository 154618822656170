"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _RefundLineContent = _interopRequireDefault(require("./RefundLineContent"));

var _Text = _interopRequireDefault(require("../Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Line = (0, _styled["default"])('div')({
  display: 'flex',
  alignItems: 'center',
  ':first-of-type:not(:last-of-type) .refund-timeline__dot:after': {
    height: '50%',
    top: '50%'
  },
  ':last-of-type:not(:first-of-type) .refund-timeline__dot:after': {
    height: '50%',
    bottom: '50%'
  },
  ':not(:first-of-type):not(:last-of-type) .refund-timeline__dot:after': {
    height: '100%'
  }
});
var DesktopDate = (0, _styled["default"])(_Text["default"])(function (_ref) {
  var _ref2;

  var theme = _ref.theme;
  return _ref2 = {
    textTransform: 'uppercase',
    display: 'none'
  }, _defineProperty(_ref2, theme.media.tablet, {
    display: 'block'
  }), _defineProperty(_ref2, theme.media.desktop, {
    width: '80px'
  }), _ref2;
});
var MobileDate = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    color: theme.colors.label,
    display: 'block',
    margin: '5px 0'
  }, theme.media.tablet, {
    display: 'none'
  });
});
var Dot = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme,
      isHover = _ref5.isHover;
  return _defineProperty({
    marginRight: '8px',
    width: '22px',
    position: 'relative',
    alignSelf: 'stretch',
    '&:before': {
      content: '""',
      width: '20px',
      height: '20px',
      zIndex: '2',
      backgroundColor: theme.colors.primary,
      borderRadius: '15px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transition: 'transform 0.2s',
      transform: "translate(-50%, -50%) scale(".concat(isHover ? '1.25' : '1', ")")
    },
    '&:after': {
      content: '""',
      width: '2px',
      opacity: '0.6',
      left: '50%',
      position: 'absolute',
      backgroundColor: theme.colors.primary,
      transform: 'translate(-50%, 0)'
    }
  }, theme.media.tablet, {
    margin: '0 30px'
  });
});
var Container = (0, _styled["default"])('div')(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({
    flex: 1
  }, theme.media.tablet, {
    marginBottom: 0
  });
});

function RefundLine(_ref9) {
  var date = _ref9.date,
      label = _ref9.label,
      value = _ref9.value,
      items = _ref9.items;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return _react["default"].createElement(Line, {
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    },
    onClick: function onClick() {
      return setIsHover(!isHover);
    }
  }, _react["default"].createElement(DesktopDate, {
    size: "typo6"
  }, date), _react["default"].createElement(Dot, {
    isHover: isHover,
    className: "refund-timeline__dot"
  }), _react["default"].createElement(Container, null, _react["default"].createElement(MobileDate, {
    size: "typo6"
  }, date), _react["default"].createElement(_RefundLineContent["default"], {
    isHover: isHover,
    label: label,
    value: value,
    items: items
  })));
}

RefundLine.propTypes = {
  date: _propTypes["default"].string,
  label: _propTypes["default"].string,
  value: _propTypes["default"].any,
  items: _RefundLineContent["default"].propTypes.items
};
var _default = RefundLine;
exports["default"] = _default;