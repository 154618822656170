"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Activity;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _PieChart = _interopRequireDefault(require("../components/PieChart"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Tip = _interopRequireDefault(require("../components/Tip"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var CardWrapper = (0, _styled["default"])('li')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'Card',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)',
    backgroundColor: '#fff',
    padding: 20,
    marginBottom: 30
  }, theme.media.tablet, {
    '&:first-of-type': {
      marginRight: 40
    },
    '&:last-of-type': {
      marginRight: 0
    },
    width: '50%'
  });
});
var Top = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({}, theme.media.tablet, {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  });
});
var PieWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    paddingTop: 20,
    paddingBottom: 20,
    '& > div': {
      margin: '0 auto'
    }
  }, theme.media.tablet, {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 30
  });
});
var Right = (0, _styled["default"])('div')({
  marginBottom: 30
});
var CardTitle = (0, _styled["default"])(_Text["default"])(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({
    marginBottom: 10
  }, theme.media.desktop, {
    marginBottom: 20
  });
});
var Bottom = (0, _styled["default"])('div')(function (_ref9) {
  var theme = _ref9.theme;
  return _defineProperty({
    marginTop: 30
  }, theme.media.desktop, {
    marginTop: 20
  });
});

function Card(_ref11) {
  var _ref11$percent = _ref11.percent,
      percent = _ref11$percent === void 0 ? 0 : _ref11$percent,
      title = _ref11.title,
      subtitle = _ref11.subtitle,
      noteTitle = _ref11.noteTitle,
      noteContent = _ref11.noteContent;
  return _react["default"].createElement(CardWrapper, null, _react["default"].createElement(Top, null, _react["default"].createElement(PieWrapper, null, _react["default"].createElement(_PieChart["default"], {
    "data-cy": title,
    value: percent + ' %',
    values: [{
      value: 100 - percent,
      color: '#e5e5e5'
    }, {
      value: percent,
      color: 'fifth'
    }]
  })), _react["default"].createElement(Right, null, _react["default"].createElement(CardTitle, {
    tag: "h3",
    size: "typo3"
  }, title), subtitle)), _react["default"].createElement(Bottom, null, _react["default"].createElement(_Tip["default"], {
    title: noteTitle
  }, noteContent)));
}

var Wrapper = (0, _styled["default"])('div')({
  label: 'Activity',
  paddingTop: 70,
  paddingBottom: 70
});
var StyledTitle = (0, _styled["default"])(_Text["default"])({
  marginBottom: 50
});
var Cards = (0, _styled["default"])('ul')(function (_ref12) {
  var theme = _ref12.theme;
  return _defineProperty({
    margin: 0,
    paddingLeft: 0,
    listStyleType: 'none'
  }, theme.media.desktop, {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  });
});

function Activity(_ref14) {
  var title = _ref14.title,
      _ref14$cards = _ref14.cards,
      cards = _ref14$cards === void 0 ? [] : _ref14$cards;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(StyledTitle, {
    tag: "h2",
    size: "typo1"
  }, title) : null, cards.length > 0 ? _react["default"].createElement(Cards, null, cards.map(function (card, key) {
    return _react["default"].createElement(Card, _extends({}, card, {
      key: key
    }));
  })) : null));
}