"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../components/Link"));

var _Icon = _interopRequireDefault(require("../../components/Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var NotificationIcon = (0, _styled["default"])(_Icon["default"])(function (_ref) {
  var count = _ref.count;
  return {
    position: 'relative',
    marginRight: 10,
    '&:after': count > 0 ? {
      content: "\"".concat(count, "\""),
      fontSize: 10,
      width: 12,
      height: 12,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      // TODO find the hex code for the notification red
      backgroundColor: 'red',
      borderRadius: '50%',
      right: '-2px',
      top: '-3px'
    } : {}
  };
});
/**
 * Notification badge. It's a link to the notification center, and
 * also displays a small red pill when there is some notifications.
 * The number of notification is display in the red pill.
 */

function Notifications(_ref2) {
  var label = _ref2.label,
      url = _ref2.url,
      count = _ref2.count;
  return _react["default"].createElement(_Link["default"], {
    href: url
  }, _react["default"].createElement(NotificationIcon, {
    count: Math.floor(Math.min(count, 99)),
    name: 'bell',
    size: 15
  }), label);
}

Notifications.propTypes = {
  /**
   * Small label that will be display as text in the link
   */
  label: _propTypes["default"].string,

  /**
   * Url to the notification center
   */
  url: _propTypes["default"].string,

  /**
   * Number of notifications the user has
   */
  count: _propTypes["default"].number
};
Notifications.defaultProps = {
  label: 'notifications',
  count: 0
};
var _default = Notifications;
exports["default"] = _default;