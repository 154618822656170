"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Notification;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Icon = _interopRequireDefault(require("../components/Icon"));

var _utils = require("../theme/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var color = _ref.color,
      theme = _ref.theme;
  return {
    backgroundColor: theme.colors[color] || color,
    color: (0, _utils.textColor)(theme.colors[color] || color, theme),
    paddingTop: 15,
    paddingBottom: 15,
    position: 'relative'
  };
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: 15
});
var Actions = (0, _styled["default"])('ul')({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  marginTop: 15,
  overflow: 'hidden'
});
var Action = (0, _styled["default"])('li')({
  "float": 'right',
  marginLeft: 10
});
var Close = (0, _styled["default"])(_Icon["default"])(function (_ref2) {
  var color = _ref2.color,
      theme = _ref2.theme;
  return {
    color: (0, _utils.textColor)(theme.colors[color] || color, theme),
    position: 'absolute',
    top: 0,
    right: 10,
    cursor: 'pointer'
  };
});
var StyledContainer = (0, _styled["default"])(_Container["default"])({
  position: 'relative'
});

function Notification(_ref3) {
  var title = _ref3.title,
      _ref3$actions = _ref3.actions,
      actions = _ref3$actions === void 0 ? [] : _ref3$actions,
      _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? '#fff' : _ref3$color,
      _ref3$onClose = _ref3.onClose,
      onClose = _ref3$onClose === void 0 ? function () {} : _ref3$onClose,
      children = _ref3.children;
  return _react["default"].createElement(Wrapper, {
    color: color
  }, _react["default"].createElement(StyledContainer, null, title ? _react["default"].createElement(Title, {
    size: "typo3",
    tag: "h2"
  }, title) : null, children, actions && actions.length > 0 ? _react["default"].createElement(Actions, null, actions.reverse().map(function (action, key) {
    return _react["default"].createElement(Action, {
      key: key
    }, _react["default"].createElement(_Button["default"], action));
  })) : null, _react["default"].createElement(Close, {
    onClick: onClose,
    color: color,
    name: "close",
    size: 25
  })));
}