"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    paddingTop: '40px',
    paddingBottom: '60px'
  }, theme.media.laptop, {
    paddingTop: '60px',
    paddingBottom: '50px'
  });
});
var Title = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    marginBottom: 40
  }, theme.media.tablet, {
    marginBottom: 60
  });
});
var RowContainer = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
    marginLeft: '-15px',
    marginRight: '-15px'
  }, theme.media.tablet, {
    marginLeft: 0,
    marginRight: 0
  });
});
var InfoWrapper = (0, _styled["default"])('div')(function (_ref7) {
  var _ref8;

  var theme = _ref7.theme;
  return _ref8 = {
    backgroundColor: 'white',
    width: '100%',
    padding: '20px 10px 15px 10px'
  }, _defineProperty(_ref8, theme.media.tablet, {
    padding: '20px 30px 15px 30px',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)'
  }), _defineProperty(_ref8, theme.media.laptop, {
    width: '55%',
    padding: '30px 30px 30px'
  }), _ref8;
});
var TableTitle = (0, _styled["default"])(_Text["default"])(function (_ref9) {
  var theme = _ref9.theme;
  return {
    paddingTop: 5,
    paddingBottom: 20,
    borderBottom: "1px solid ".concat(theme.colors.lightGray)
  };
});
var List = (0, _styled["default"])('ul')({
  padding: 0
});
var ListItem = (0, _styled["default"])('li')(function (_ref10) {
  var theme = _ref10.theme;
  return {
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: "1px solid ".concat(theme.colors.lightGray),
    '* > &:first-child ': {
      borderTop: 'none'
    }
  };
});
var Label = (0, _styled["default"])(_Text["default"])({
  width: '45%'
});
var AsideImage = (0, _styled["default"])('div')(function (_ref11) {
  var theme = _ref11.theme,
      image = _ref11.image;
  return _defineProperty({
    display: 'none',
    backgroundSize: 'cover',
    backgroundImage: "url(\"".concat(image, "\")"),
    backgroundPosition: 'center center '
  }, theme.media.laptop, {
    display: 'block',
    width: '45%'
  });
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref13) {
  var _ref14;

  var theme = _ref13.theme;
  return _ref14 = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center'
  }, _defineProperty(_ref14, theme.media.tablet, {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }), _defineProperty(_ref14, theme.media.laptop, {
    width: '55%'
  }), _ref14;
});
var ActionButton = (0, _styled["default"])(_Button["default"])(function (_ref15) {
  var theme = _ref15.theme;
  return _defineProperty({
    marginBottom: 20
  }, theme.media.tablet, {
    marginBottom: 0
  });
});

var renderInfoList = function renderInfoList(infos) {
  return infos.length > 0 ? _react["default"].createElement(List, null, infos.map(function (_ref17, i) {
    var label = _ref17.label,
        value = _ref17.value;
    return _react["default"].createElement(ListItem, {
      key: i
    }, _react["default"].createElement(Label, {
      size: "typo4"
    }, label), _react["default"].createElement(_Text["default"], {
      size: "typo5"
    }, value));
  })) : null;
};

var renderActionsList = function renderActionsList(actions) {
  return actions.length > 0 ? _react["default"].createElement(ActionWrapper, null, actions.map(function (_ref18, i) {
    var link = _ref18.link,
        label = _ref18.label;
    return _react["default"].createElement(ActionButton, {
      key: i,
      href: link
    }, label);
  })) : null;
};

function ProfilInfoList(_ref19) {
  var title = _ref19.title,
      tableTitle = _ref19.tableTitle,
      infos = _ref19.infos,
      actions = _ref19.actions,
      image = _ref19.image;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, {
    tag: "h3",
    size: "typo1"
  }, title) : null, _react["default"].createElement(RowContainer, null, _react["default"].createElement(InfoWrapper, null, tableTitle ? _react["default"].createElement(TableTitle, {
    size: "typo2",
    tag: "h4"
  }, tableTitle) : null, renderInfoList(infos)), image ? _react["default"].createElement(AsideImage, {
    image: image
  }) : null), renderActionsList(actions)));
}

ProfilInfoList.propTypes = {
  tableTitle: _propTypes["default"].string,
  title: _propTypes["default"].string,
  infos: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    label: _propTypes["default"].string,
    value: _propTypes["default"].string
  })).isRequired,
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    link: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),
  image: _propTypes["default"].string
};
ProfilInfoList.defaultProps = {
  infos: [],
  actions: []
};
var _default = ProfilInfoList;
exports["default"] = _default;