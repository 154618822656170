"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ActionBanner = _interopRequireDefault(require("./ActionBanner"));

var _ContractDetails = _interopRequireDefault(require("../components/ContractDetails"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} //import styled from '@emotion/styled';


function ContractDetailsBanner(_ref) {
  var actionBannerProps = _ref.actionBannerProps,
      details = _ref.details;
  return _react["default"].createElement(_ActionBanner["default"], actionBannerProps, _react["default"].createElement(_ContractDetails["default"], {
    details: details
  }));
}

ContractDetailsBanner.propTypes = {
  /**
   * Props pass to action banner
   */
  actionBannerProps: _propTypes["default"].shape(_ActionBanner["default"].propTypes),

  /**
   * Details list passed to ContractDetail component
   */
  details: _ContractDetails["default"].propTypes.details
};
ContractDetailsBanner.defaultProps = {
  actionBannerProps: {}
};
var _default = ContractDetailsBanner;
exports["default"] = _default;