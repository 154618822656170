"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _ExpensesGraphic = _interopRequireDefault(require("./ExpensesGraphic"));

var _ExpensesDetails = _interopRequireDefault(require("./ExpensesDetails"));

var _SimpleButton = _interopRequireDefault(require("../SimpleButton"));

var _Text = _interopRequireDefault(require("../Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)',
    border: "1px solid ".concat(theme.colors.lighterGray),
    padding: '20px',
    backgroundColor: '#fff'
  };
});
var Title = (0, _styled["default"])(_Text["default"])(function (_ref2) {
  var theme = _ref2.theme;
  return {
    color: theme.colors.text,
    paddingBottom: '20px',
    borderBottom: "2px solid ".concat(theme.colors.lighterGray),
    marginTop: 0,
    marginBottom: '24px'
  };
});
var DataWrapper = (0, _styled["default"])('div')(function (_ref3) {
  var _ref4;

  var theme = _ref3.theme;
  return _ref4 = {
    marginBottom: '20px'
  }, _defineProperty(_ref4, theme.media.tablet, {
    marginBottom: '30px'
  }), _defineProperty(_ref4, theme.media.laptop, {
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }), _ref4;
});
var GraphWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    marginBottom: '60px'
  }, theme.media.laptop, {
    marginBottom: 0,
    width: '430px'
  });
});
var Separator = (0, _styled["default"])('div')(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({}, theme.media.laptop, {
    background: '#e5e5e5',
    width: '1px',
    height: '200px',
    maxHeight: '100%'
  });
});
var DetailsWrapper = (0, _styled["default"])('div')(function (_ref9) {
  var theme = _ref9.theme;
  return _defineProperty({}, theme.media.laptop, {
    marginLeft: '70px',
    marginRight: '70px',
    flex: 1
  });
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref11) {
  var _ref12;

  var theme = _ref11.theme;
  return _ref12 = {
    marginBottom: '20px'
  }, _defineProperty(_ref12, theme.media.tablet, {
    marginBottom: '10px'
  }), _defineProperty(_ref12, theme.media.laptop, {
    display: 'block',
    textAlign: 'center'
  }), _ref12;
});

function ExpensesOverview(_ref13) {
  var title = _ref13.title,
      label = _ref13.label,
      items = _ref13.items,
      total = _ref13.total,
      action = _ref13.action;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Title, {
    tag: "h3",
    size: "typo2"
  }, title), _react["default"].createElement(DataWrapper, null, _react["default"].createElement(GraphWrapper, null, _react["default"].createElement(_ExpensesGraphic["default"], {
    label: label,
    total: total,
    items: items
  })), _react["default"].createElement(Separator, null), _react["default"].createElement(DetailsWrapper, null, _react["default"].createElement(_ExpensesDetails["default"], {
    items: items
  }))), action ? _react["default"].createElement(ActionWrapper, null, _react["default"].createElement(_SimpleButton["default"], {
    href: action.url
  }, action.label)) : null);
}

ExpensesOverview.propTypes = {
  title: _propTypes["default"].string,
  total: _ExpensesGraphic["default"].propTypes.total,
  items: _ExpensesDetails["default"].propTypes.items,
  action: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })
};
ExpensesOverview.defaultProps = {
  items: []
};
var _default = ExpensesOverview;
exports["default"] = _default;