"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("./Text"));

var _Button = _interopRequireDefault(require("./Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: '10px'
});
var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color;
  return {
    backgroundColor: color ? theme.colors[color] || color : theme.colors.smallLightGray,
    padding: '20px'
  };
});

var LinkContainer = function LinkContainer(_ref2) {
  var link = _ref2.link;
  return _react["default"].createElement("div", null, _react["default"].createElement(_Button["default"], {
    ghost: true,
    href: link.url
  }, link.label));
};

var Content = (0, _styled["default"])('div')(function (_ref3) {
  var hasLink = _ref3.hasLink;
  return _objectSpread({}, hasLink ? {
    paddingRight: '70px'
  } : {}, {
    flex: '1'
  });
});
var ContentWrapper = (0, _styled["default"])('div')({
  display: 'flex'
});

function Tip(_ref4) {
  var title = _ref4.title,
      children = _ref4.children,
      link = _ref4.link,
      color = _ref4.color,
      className = _ref4.className;
  var hasLink = link && link.url && link.label;
  return _react["default"].createElement(Wrapper, {
    className: className,
    color: color
  }, title ? _react["default"].createElement(Title, {
    size: "typo4",
    tag: "span"
  }, title) : null, _react["default"].createElement(ContentWrapper, null, _react["default"].createElement(Content, {
    hasLink: hasLink
  }, children), hasLink ? _react["default"].createElement(LinkContainer, {
    link: link
  }) : null));
}

Tip.propTypes = {
  /**
   * Title of the note
   */
  title: _propTypes["default"].string,

  /**
   * content of the note. Can be a string, or element(s)
   */
  children: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element, _propTypes["default"].array]).isRequired,

  /**
   * theme.colors[color] for the background. Default to "#f8fafd"
   */
  color: _propTypes["default"].string
};
var _default = Tip;
exports["default"] = _default;