"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _RefundLine = _interopRequireDefault(require("./RefundLine"));

var _SimpleButton = _interopRequireDefault(require("../SimpleButton"));

var _Text = _interopRequireDefault(require("../Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)',
    border: "1px solid ".concat(theme.colors.lighterGray),
    padding: '20px',
    backgroundColor: '#fff'
  };
});
var Title = (0, _styled["default"])(_Text["default"])(function (_ref2) {
  var theme = _ref2.theme;
  return {
    color: theme.colors.text,
    paddingBottom: '20px',
    borderBottom: "2px solid ".concat(theme.colors.lighterGray),
    marginTop: 0,
    marginBottom: '24px'
  };
});
var RefundsWrapper = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    marginBottom: '30px'
  }, theme.media.laptop, {
    padding: '0 20px',
    marginBottom: '40px'
  });
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    marginBottom: '20px',
    marginLeft: '30px'
  }, theme.media.tablet, {
    marginLeft: 0,
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-around'
  });
});
var Action = (0, _styled["default"])(_SimpleButton["default"])(function (_ref7) {
  var theme = _ref7.theme;
  return {
    display: 'block',
    '&:not(:last-child)': _defineProperty({
      marginBottom: '10px'
    }, theme.media.tablet, {
      marginBottom: 0
    })
  };
});

function RefundTimeline(_ref8) {
  var title = _ref8.title,
      items = _ref8.items,
      actions = _ref8.actions;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Title, {
    tag: "h3",
    size: "typo2"
  }, title), _react["default"].createElement(RefundsWrapper, null, items.map(function (item, i) {
    return _react["default"].createElement(_RefundLine["default"], _extends({}, item, {
      key: i
    }));
  })), actions.length > 0 ? _react["default"].createElement(ActionWrapper, null, actions.map(function (_ref9, i) {
    var url = _ref9.url,
        label = _ref9.label;
    return _react["default"].createElement(Action, {
      key: i,
      href: url
    }, label);
  })) : null);
}

RefundTimeline.propTypes = {
  title: _propTypes["default"].string,
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape(_RefundLine["default"].propTypes)),
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  }))
};
RefundTimeline.defaultProps = {
  items: [],
  actions: []
};
var _default = RefundTimeline;
exports["default"] = _default;