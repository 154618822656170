import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Link, GlobalStyles } from 'component-studio';
import ThemeProvider from './contexts/Theme';
import translations from './config/translations';
import { FinderProvider } from './contexts/Finder';
import { LayoutProvider } from './contexts/Layout';
import { LanguageProvider } from './contexts/Language';
import { PrismicProvider } from './contexts/PrismicAPI';
import { TranslationProvider } from './contexts/Translation';
import repositories from './repositories';

function CustomLink({ href, children, ...props }) {
  if (!href) return null;

  if (/(^(https?|mailto|\/app|tel).*)|(.+\.(csv|xlsx|pdf)$)/.test(href)) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }

  return (
    <ReactLink to={href} {...props}>
      {children}
    </ReactLink>
  );
}

function getRepository() {
  if (process.env.REACT_APP_PRISMIC_REPOSITORY)
    return process.env.REACT_APP_PRISMIC_REPOSITORY;

  // Test for each possible repository or take the first part of the hostname
  const [repository] =
    repositories.find(([, regex]) => regex.test(window.location.hostname)) ||
    window.location.hostname.split('.');

  return repository;
}

export default function Providers({ children }) {
  return (
    <Link.ContextProvider element={CustomLink}>
      <PrismicProvider repository={getRepository()}>
        <LanguageProvider>
          <FinderProvider>
            <LayoutProvider>
              <ThemeProvider>
                <TranslationProvider translations={translations}>
                  <GlobalStyles />
                  {children}
                </TranslationProvider>
              </ThemeProvider>
            </LayoutProvider>
          </FinderProvider>
        </LanguageProvider>
      </PrismicProvider>
    </Link.ContextProvider>
  );
}
