"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _recharts = require("recharts");

var _emotionTheming = require("emotion-theming");

var _Currency = _interopRequireDefault(require("../Currency"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  width: '200px',
  height: '200px',
  margin: 'auto'
});

function BudgetRepartitionChart(props) {
  var label = props.label,
      savings = props.savings,
      currency = props.currency,
      locale = props.locale,
      theme = props.theme;
  var total = savings.reduce(function (a, b) {
    return a + b.value;
  }, 0);
  var size = 200;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_recharts.PieChart, {
    width: size,
    height: size
  }, label ? _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 - 15,
    textAnchor: "middle",
    fill: theme.colors.text,
    fontSize: "13px"
  }, label) : null, _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 + (label ? 24 : 12),
    textAnchor: "middle",
    fill: theme.colors.text,
    fontSize: "25px",
    fontWeight: "bold"
  }, _react["default"].createElement(_Currency["default"], {
    value: total,
    currency: currency,
    locale: locale
  })), _react["default"].createElement(_recharts.Pie, {
    data: savings,
    dataKey: "value",
    innerRadius: "85%",
    outerRadius: "100%",
    animationDuration: 1000,
    AnimationEasing: 'ease-out'
  }, savings.map(function (saving) {
    return _react["default"].createElement(_recharts.Cell, {
      key: saving.colorKey,
      fill: theme.colors[saving.colorKey] || saving.colorKey
    });
  }))));
}

BudgetRepartitionChart.propTypes = {
  label: _propTypes["default"].string.isRequired,
  savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    value: _propTypes["default"].number,
    color: _propTypes["default"].string
  })),

  /**
   * currency of the value
   */
  currency: _propTypes["default"].string.isRequired,

  /**
   * Locale used to format the money
   */
  locale: _propTypes["default"].string.isRequired
};
BudgetRepartitionChart.defaultProps = {
  label: '',
  savings: []
};

var _default = (0, _emotionTheming.withTheme)(BudgetRepartitionChart);

exports["default"] = _default;