"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _SavingGraph = _interopRequireDefault(require("./SavingGraph"));

var _SavingTables = _interopRequireDefault(require("./SavingTables"));

var _Text = _interopRequireDefault(require("../../components/Text"));

var _theme = _interopRequireDefault(require("../../theme"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ContainerWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return {
    label: 'ContainerWrapper',
    backgroundColor: theme.colors.white,
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.04)',
    border: "1px solid ".concat(theme.colors.lighterGray),
    padding: '20px',
    paddingTop: '10px'
  };
});
var Wrapper = (0, _styled["default"])('div')(function (_ref2) {
  var _ref3;

  var theme = _ref2.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _ref3 = {
    label: 'Wrapper'
  }, _defineProperty(_ref3, theme.media.tablet, {
    paddingBottom: '30px'
  }), _defineProperty(_ref3, theme.media.laptop, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px'
  }), _ref3;
});
var GraphWrapper = (0, _styled["default"])('div')(function (_ref4) {
  var theme = _ref4.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _defineProperty({
    marginTop: '30px',
    marginBottom: '50px'
  }, theme.media.laptop, {
    margin: '0 100px'
  });
});
var TablesWrapper = (0, _styled["default"])('div')(function (_ref6) {
  var theme = _ref6.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _defineProperty({}, theme.media.laptop, {
    flex: 1
  });
});
var StyledText = (0, _styled["default"])(_Text["default"])(function (_ref8) {
  var theme = _ref8.theme;
  return {
    label: 'StyledText',
    paddingBottom: '10px',
    borderBottom: '1px solid #e5e5e5',
    color: theme.colors.primary
  };
});

function Saving(_ref9) {
  var cardTitle = _ref9.cardTitle,
      graphLabel = _ref9.graphLabel,
      savingsTables = _ref9.savingsTables,
      actions = _ref9.actions,
      currency = _ref9.currency,
      locale = _ref9.locale;
  var amounts = savingsTables.map(function (table) {
    return table.savings;
  }).reduce(function (_final, current) {
    return _final.concat(current);
  }, []);
  return _react["default"].createElement(ContainerWrapper, null, cardTitle ? _react["default"].createElement(StyledText, {
    size: "typo2"
  }, cardTitle) : null, _react["default"].createElement(Wrapper, null, _react["default"].createElement(GraphWrapper, null, _react["default"].createElement(_SavingGraph["default"], {
    label: graphLabel,
    amounts: amounts,
    currency: currency,
    locale: locale
  })), _react["default"].createElement(TablesWrapper, null, _react["default"].createElement(_SavingTables["default"], {
    tables: savingsTables,
    actions: actions,
    currency: currency,
    locale: locale
  }))));
}

Saving.propTypes = {
  /**
   * Label above the total amount on the graph
   */
  graphLabel: _propTypes["default"].string.isRequired,

  /**
   * Array of array we should display and calcul total from
   */
  savingsTables: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    title: _propTypes["default"].string.isRequired,
    savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      colorKey: _propTypes["default"].string.isRequired,
      label: _propTypes["default"].string.isRequired,
      amount: _propTypes["default"].number,
      url: _propTypes["default"].string,
      percent: _propTypes["default"].string
    })).isRequired
  })),

  /**
   * Array of actions below tables
   */
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),

  /**
   * Currency for the amount display
   */
  currency: _propTypes["default"].string.isRequired,

  /**
   * Locale for the amount display
   */
  locale: _propTypes["default"].string.isRequired
};
Saving.defaultProps = {
  savingsTables: [],
  actions: []
};
var _default = Saving;
exports["default"] = _default;