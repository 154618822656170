"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = DocumentsList;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Pill = _interopRequireDefault(require("../components/Pill"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Icon = _interopRequireDefault(require("../components/Icon"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var DocumentWrapper = (0, _styled["default"])('li')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    padding: 20,
    background: 'white',
    margin: 0,
    minHeight: 82,
    position: 'relative',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.smallLightGray
    }
  }, theme.media.laptop, {
    height: 85
  });
});
var FileIcon = (0, _styled["default"])(_Icon["default"])(function (_ref3) {
  var theme = _ref3.theme,
      hasStatus = _ref3.hasStatus;
  return _defineProperty({
    marginRight: 20,
    display: 'block',
    position: 'absolute',
    top: hasStatus ? 70 : 25,
    left: 25
  }, theme.media.tablet, {
    top: 25
  });
});
var Content = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    marginLeft: 60
  }, theme.media.tablet, {});
});
var Left = (0, _styled["default"])('div')(function (_ref7) {
  var _ref8;

  var theme = _ref7.theme,
      hasStatus = _ref7.hasStatus,
      hasLinks = _ref7.hasLinks;
  return _ref8 = {
    marginTop: hasStatus ? 45 : 0,
    marginBottom: hasLinks ? 30 : 0
  }, _defineProperty(_ref8, theme.media.tablet, {
    minWidth: '50%',
    minHeight: 40,
    marginTop: 0,
    marginBottom: 0
  }), _defineProperty(_ref8, theme.media.desktop, {
    minHeight: 'initial',
    "float": 'left'
  }), _ref8;
});
var Description = (0, _styled["default"])(_Text["default"])({
  marginTop: 5,
  display: 'block'
});
var Status = (0, _styled["default"])(_Pill["default"])(function (_ref9) {
  var _ref10;

  var theme = _ref9.theme;
  return _ref10 = {
    textTransform: 'uppercase',
    position: 'absolute',
    top: 25,
    left: 25,
    margin: '0'
  }, _defineProperty(_ref10, theme.media.tablet, {
    marginLeft: 30,
    left: 'initial',
    top: 30,
    right: 20
  }), _defineProperty(_ref10, theme.media.desktop, {
    position: 'relative',
    top: 0,
    right: 0,
    marginRight: 30
  }), _ref10;
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref11) {
  var _ref12;

  var theme = _ref11.theme;
  return _ref12 = {
    textAlign: 'right'
  }, _defineProperty(_ref12, theme.media.tablet, {
    marginTop: 20,
    minWidth: '100%'
  }), _defineProperty(_ref12, theme.media.desktop, {
    marginLeft: 0,
    minWidth: 'initial',
    marginTop: 0
  }), _ref12;
});
var StyledButton = (0, _styled["default"])(_Button["default"])({
  width: 'initial',
  marginLeft: 15
});
var StyledText = (0, _styled["default"])(_Text["default"])({
  '&:last-child': {
    lineHeight: '40px'
  }
});
var StyledTextAvailability = (0, _styled["default"])(_Text["default"])({
  lineHeight: '40px'
});

function Document(_ref13) {
  var title = _ref13.title,
      description = _ref13.description,
      status = _ref13.status,
      actionButtons = _ref13.actionButtons,
      availability = _ref13.availability;
  var hasStatus = status && status.label;
  var hasLinks = actionButtons && actionButtons.length > 0;
  return _react["default"].createElement(DocumentWrapper, null, _react["default"].createElement(Left, {
    hasStatus: hasStatus,
    hasLinks: hasLinks
  }, _react["default"].createElement(FileIcon, {
    hasStatus: hasStatus,
    name: "file",
    size: 32
  }), _react["default"].createElement(Content, null, _react["default"].createElement(StyledText, null, title), description ? _react["default"].createElement(Description, {
    size: "typo6"
  }, description) : null)), _react["default"].createElement(ActionWrapper, null, hasStatus ? _react["default"].createElement(Status, {
    color: status.color
  }, status.label) : null, hasLinks ? actionButtons.map(function (action, i) {
    return _react["default"].createElement(StyledButton, _extends({
      key: i
    }, action, {
      ghost: true,
      big: true
    }));
  }) : _react["default"].createElement(StyledTextAvailability, {
    size: "typo6"
  }, availability)));
}

var Wrapper = (0, _styled["default"])('div')({
  paddingTop: 30,
  paddingBottom: 30
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: 40
});
var Sections = (0, _styled["default"])('div')({});
var Section = (0, _styled["default"])('div')(function (_ref14) {
  var _ref15;

  var theme = _ref14.theme;
  return _ref15 = {
    marginBottom: 30
  }, _defineProperty(_ref15, theme.media.laptop, {
    width: '78%'
  }), _defineProperty(_ref15, theme.media.desktop, {
    width: '82%',
    '&:last-child': {
      marginBottom: 0
    }
  }), _ref15;
});
var SectionTitle = (0, _styled["default"])(_Text["default"])({
  marginTop: 40,
  marginBottom: 20
});
var Documents = (0, _styled["default"])('ul')({
  listStyleType: 'none',
  margin: 0,
  padding: 0
});
var EmptySentence = (0, _styled["default"])(_Text["default"])({
  display: 'block',
  borderTop: '1px solid #eee',
  paddingTop: 20,
  marginBottom: 20
});

function DocumentsList(_ref16) {
  var title = _ref16.title,
      _ref16$sections = _ref16.sections,
      sections = _ref16$sections === void 0 ? [] : _ref16$sections,
      sentenceOnEmpty = _ref16.sentenceOnEmpty;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, {
    tag: "h2",
    size: "typo2"
  }, title) : null, sections && sections.length > 0 ? _react["default"].createElement(Sections, null, sections.map(function (section, key) {
    return _react["default"].createElement(Section, {
      key: key
    }, section.title ? _react["default"].createElement(SectionTitle, {
      tag: "h3",
      size: "typo3"
    }, section.title) : null, section.documents && section.documents.length > 0 ? _react["default"].createElement(Documents, null, section.documents.map(function (document, key) {
      return _react["default"].createElement(Document, _extends({}, document, {
        key: key
      }));
    })) : _react["default"].createElement(EmptySentence, {
      size: "typo6"
    }, sentenceOnEmpty));
  })) : null));
}