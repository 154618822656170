"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Text = _interopRequireDefault(require("./Text"));

var _Link = _interopRequireDefault(require("./Link"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme,
      to = _ref.to;
  return _ref2 = {
    display: 'block',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
    backgroundColor: '#fff',
    maxWidth: '885px'
  }, _defineProperty(_ref2, theme.media.tablet, {
    display: 'flex'
  }), _defineProperty(_ref2, '&:hover .content-wrapper', to ? {
    padding: '30px 25px 30px 35px'
  } : {}), _ref2;
});
var LinkWrapper = Wrapper.withComponent(_Link["default"]);
var Image = (0, _styled["default"])('img')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    display: 'none',
    maxWidth: '100%'
  }, theme.media.tablet, {
    display: 'block',
    width: '268px',
    height: '290px'
  });
});
var ContentWrapper = (0, _styled["default"])('div')({
  padding: '30px',
  transition: 'padding 0.2s ease'
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: '20px'
});

function LargeCard(_ref5) {
  var url = _ref5.url,
      image = _ref5.image,
      title = _ref5.title,
      content = _ref5.content;
  var WrappingTag = url ? LinkWrapper : Wrapper;
  return _react["default"].createElement(WrappingTag, {
    href: url
  }, image ? _react["default"].createElement(Image, {
    src: image.src,
    alt: image.alt
  }) : null, _react["default"].createElement(ContentWrapper, {
    className: "content-wrapper"
  }, _react["default"].createElement(Title, {
    size: "typo3"
  }, title), _react["default"].createElement(_Text["default"], null, content)));
}

LargeCard.propTypes = {
  url: _propTypes["default"].string,
  image: _propTypes["default"].shape({
    src: _propTypes["default"].string.isRequired,
    alt: _propTypes["default"].string
  }),
  title: _propTypes["default"].string.isRequired,
  content: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]).isRequired
};
var _default = LargeCard;
exports["default"] = _default;