"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var SvgEbUsers = function SvgEbUsers(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 24 24"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    id: "eb-users_svg__a",
    d: "M9.565 12.174a2.612 2.612 0 0 1 2.609-2.609 2.612 2.612 0 0 1 2.609 2.609 2.612 2.612 0 0 1-2.61 2.609 2.612 2.612 0 0 1-2.608-2.61zm4.174 0c0-.863-.702-1.565-1.565-1.565s-1.565.702-1.565 1.565.702 1.565 1.565 1.565 1.565-.702 1.565-1.565zm11.478 0a2.612 2.612 0 0 1 2.61-2.609 2.612 2.612 0 0 1 2.608 2.609 2.612 2.612 0 0 1-2.609 2.609 2.612 2.612 0 0 1-2.609-2.61zm4.174 0c0-.863-.702-1.565-1.565-1.565s-1.565.702-1.565 1.565.702 1.565 1.565 1.565 1.565-.702 1.565-1.565zm0 4.174A2.612 2.612 0 0 1 32 18.957v4.695a.52.52 0 0 1-.357.495l-1.242.414-.49 5.399a.522.522 0 0 1-.52.475h-3.13a.522.522 0 0 1 0-1.044h2.654l.478-5.265a.521.521 0 0 1 .355-.447l1.209-.403v-4.32c0-.862-.703-1.565-1.566-1.565h-3.13c-.425 0-.817.17-1.106.462.36.447.584 1.008.584 1.625v5.218c0 .24-.163.448-.395.506l-1.725.43-.49 5.89a.522.522 0 0 1-.52.478H17.39a.522.522 0 0 1-.52-.478l-.49-5.89-1.725-.43a.521.521 0 0 1-.395-.506v-5.218c0-.617.225-1.178.584-1.625a1.549 1.549 0 0 0-1.106-.462h-3.13c-.863 0-1.566.703-1.566 1.566v4.319l1.209.403a.521.521 0 0 1 .355.448l.478 5.264h2.654a.522.522 0 0 1 0 1.044h-3.13a.522.522 0 0 1-.52-.474l-.49-5.4-1.242-.414A.522.522 0 0 1 8 23.652v-4.695a2.612 2.612 0 0 1 2.609-2.61h3.13c.728 0 1.42.315 1.91.84a2.575 2.575 0 0 1 1.22-.317h6.261c.444 0 .856.121 1.222.317.49-.525 1.18-.84 1.909-.84h3.13zm-4.695 7.94v-4.81c0-.863-.703-1.565-1.566-1.565h-6.26c-.863 0-1.566.702-1.566 1.565v4.81l1.692.423c.217.055.375.24.394.463l.481 5.783h4.258l.481-5.783a.522.522 0 0 1 .394-.463l1.692-.423zm-8.348-12.636A3.656 3.656 0 0 1 20 8a3.656 3.656 0 0 1 3.652 3.652A3.656 3.656 0 0 1 20 15.304a3.656 3.656 0 0 1-3.652-3.652zm6.26 0A2.612 2.612 0 0 0 20 9.043a2.612 2.612 0 0 0-2.609 2.61A2.612 2.612 0 0 0 20 14.26a2.612 2.612 0 0 0 2.609-2.609z"
  })), _react["default"].createElement("use", {
    transform: "translate(-8 -8)",
    xlinkHref: "#eb-users_svg__a"
  }));
};

var _default = SvgEbUsers;
exports["default"] = _default;