/** @jsx jsx */
import { jsx } from '@emotion/core';
import Layout from '../components/Layout';
import SlicesBuilder from '../SliceBuilder';
import { Helmet } from 'react-helmet';
import { ArticleHeader } from 'component-studio';
import { Date } from 'prismic-reactjs';
import { format } from 'date-fns';

function Article({
  title,
  date,
  cover,
  category,
  body,
  seo_title,
  seo_description
}) {
  return (
    <Layout>
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" value={seo_description} />
      </Helmet>
      <div css={{ marginTop: 70 }}>
        <ArticleHeader
          title={title}
          date={format(Date(date), 'DD MMM YYYY')}
          category={category || 'Article'}
          image={{
            src: cover && cover.url,
            alt: cover.alt || title
          }}
        />
        <SlicesBuilder slices={body} />
      </div>
    </Layout>
  );
}

Article.getInitialProps = async function getInitialProps(finder, params) {
  const document = await finder.getByUID('article', params.uid);
  if (!document) return null;
  return document.data;
};

export default Article;
