"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var SvgEbFile = function SvgEbFile(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 19 23"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    id: "eb-file_svg__a",
    d: "M11.5 32a.5.5 0 0 1-.5-.5v-22a.5.5 0 0 1 .5-.5h18a.5.5 0 0 1 .5.5v16a.494.494 0 0 1-.147.354l-6 5.999A.498.498 0 0 1 23.5 32h-12zM24 30.293L28.293 26H24v4.293zM12 10v21h11v-5.5a.5.5 0 0 1 .5-.5H29V10H12zm3.5 5a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1h-10zm0 5a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1h-10zm4 4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1h4z"
  })), _react["default"].createElement("use", {
    transform: "translate(-11 -9)",
    xlinkHref: "#eb-file_svg__a"
  }));
};

var _default = SvgEbFile;
exports["default"] = _default;