"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionBannerSkeleton = exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Pill = _interopRequireDefault(require("../components/Pill"));

var _SimpleButton = _interopRequireDefault(require("../components/SimpleButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    label: 'ActionBanner',
    backgroundColor: theme.colors.smallLightGray,
    paddingTop: '45px',
    paddingBottom: '45px',
    '&:after': {
      display: 'block',
      content: '""',
      clear: 'both'
    }
  };
});
var Breadcumb = (0, _styled["default"])('div')({
  marginBottom: 50
});
var HeadingWrapper = (0, _styled["default"])('div')(function (_ref2) {
  var theme = _ref2.theme;
  return _defineProperty({
    label: 'HeadingWrapper',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 30,
    paddingLeft: 15,
    paddingRight: 15,
    '&:last-child': {
      marginBottom: '0'
    }
  }, theme.media.tablet, {
    paddingLeft: 0,
    paddingRight: 0
  });
});
var StyledPill = (0, _styled["default"])(_Pill["default"])({
  textTransform: 'upperCase',
  label: 'StyledPill',
  marginLeft: 20,
  lineHeight: '100%'
});
var StyledText = (0, _styled["default"])(_Text["default"])(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty({
    label: 'StyledText',
    marginBottom: 40,
    ':last-child': {
      marginBottom: 0
    },
    paddingLeft: 15,
    paddingRight: 15
  }, theme.media.tablet, {
    paddingLeft: 0,
    paddingRight: 0
  });
});
var ChildrenWrapper = (0, _styled["default"])('div')({
  label: 'ChildrenWrapper'
});
var StyledContainer = (0, _styled["default"])(_Container["default"])(function (_ref6) {
  var theme = _ref6.theme;
  return _defineProperty({
    label: 'StyledContainer',
    paddingLeft: 0,
    paddingRight: 0
  }, theme.media.tablet, {
    paddingLeft: 15,
    paddingRight: 15
  });
});

var ActionBanner = function ActionBanner(_ref8) {
  var title = _ref8.title,
      description = _ref8.description,
      statusOptions = _ref8.statusOptions,
      children = _ref8.children,
      breadcumb = _ref8.breadcumb,
      className = _ref8.className;
  return _react["default"].createElement(Wrapper, {
    className: className
  }, _react["default"].createElement(StyledContainer, null, breadcumb ? _react["default"].createElement(Breadcumb, null, _react["default"].createElement(_SimpleButton["default"], {
    href: breadcumb.url,
    icon: "left",
    iconPosition: "left"
  }, breadcumb.label)) : null, _react["default"].createElement(HeadingWrapper, null, _react["default"].createElement(_Text["default"], {
    tag: "h1",
    size: "typo1"
  }, title), statusOptions && Object.keys(statusOptions).length > 0 ? _react["default"].createElement(StyledPill, statusOptions) : null), description ? _react["default"].createElement(StyledText, {
    tag: "p"
  }, description) : null, children ? _react["default"].createElement(ChildrenWrapper, null, children) : null));
};

ActionBanner.propTypes = {
  /**
   * Title
   */
  title: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),

  /**
   * description
   */
  description: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]),

  /**
   * statusOptions
   */
  statusOptions: _propTypes["default"].shape(_Pill["default"].PropTypes),

  /**
   * Breadcump link properties that appear above the title
   */
  breadcumb: _propTypes["default"].shape({
    label: _propTypes["default"].string,
    url: _propTypes["default"].string
  }),

  /**
   * children
   */
  children: _propTypes["default"].node
};
var _default = ActionBanner;
exports["default"] = _default;
var TextSkeleton = (0, _styled["default"])(_Text["default"])(function (_ref9) {
  var theme = _ref9.theme;
  return _objectSpread({}, theme.skeletonWave, {
    width: 300
  });
});
var ContentSkeleton = (0, _styled["default"])(_Text["default"])(function (_ref10) {
  var theme = _ref10.theme;
  return _objectSpread({}, theme.skeletonWave, {
    marginBottom: 15
  });
});

var ActionBannerSkeleton = function ActionBannerSkeleton() {
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(StyledContainer, null, _react["default"].createElement(HeadingWrapper, null, _react["default"].createElement(TextSkeleton, {
    tag: "h1",
    size: "typo1"
  }, "Hi header")), _react["default"].createElement(ContentSkeleton, {
    tag: "p"
  }, "Pariatur excepteur ut ullamco mollit fugiat consequat ea excepteur"), _react["default"].createElement(ContentSkeleton, {
    tag: "p"
  }, "Pariatur excepteur ut ullamco mollit fugiat consequat ea excepteur")));
};

exports.ActionBannerSkeleton = ActionBannerSkeleton;