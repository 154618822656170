"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Icon = _interopRequireDefault(require("../../components/Icon"));

var _reactUse = require("react-use");

var _MetaHeader = require("./MetaHeader");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var ListWrapper = (0, _styled["default"])('div')(function (_ref) {
  var isOpen = _ref.isOpen;
  return {
    background: '#fafafa',
    color: '#7f7f7f',
    userSelect: 'none',
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 20,
    transition: isOpen ? 'transform 0.3s, opacity 0.1s ease 0.1s' : 'transform 0.1s ease 0.1s, opacity 0.3s',
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0)' : 'translateY(5px)',
    pointerEvents: isOpen ? 'auto' : 'none'
  };
});
var Lang = (0, _styled["default"])('div')(function (_ref2) {
  var theme = _ref2.theme;
  return {
    padding: 10,
    transition: 'background-color 0.2s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGray
    }
  };
});

function SwitcherList(_ref3) {
  var available = _ref3.available,
      onChange = _ref3.onChange,
      isOpen = _ref3.isOpen,
      setOpen = _ref3.setOpen;
  return _react["default"].createElement(ListWrapper, {
    isOpen: isOpen
  }, _react["default"].createElement("div", null, available.map(function (_ref4, i) {
    var label = _ref4.label,
        id = _ref4.id;
    return _react["default"].createElement(Lang, {
      key: i,
      onClick: function onClick() {
        setOpen(!isOpen);
        onChange(id);
      }
    }, label);
  })));
}

var Wrapper = (0, _styled["default"])('div')({
  label: 'WRAPPER'
});
var CurrentLangWrapper = (0, _styled["default"])('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
});
var ArrowIcon = (0, _styled["default"])(_Icon["default"])(function (_ref5) {
  var open = _ref5.open;
  return {
    marginLeft: 15,
    transition: 'all 0.5s ease',
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
  };
});

function DropdownSelector(_ref6) {
  var current = _ref6.current,
      props = _objectWithoutProperties(_ref6, ["current"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var ref = (0, _react.useRef)(null);
  (0, _reactUse.useClickAway)(ref, function () {
    return setOpen(false);
  });
  return _react["default"].createElement(_MetaHeader.NavItem, {
    onClick: function onClick() {
      return setOpen(!open);
    }
  }, _react["default"].createElement(Wrapper, {
    ref: ref
  }, _react["default"].createElement(CurrentLangWrapper, null, _react["default"].createElement("span", null, current.label), _react["default"].createElement(ArrowIcon, {
    name: "down",
    open: open,
    size: 15
  })), _react["default"].createElement(SwitcherList, _extends({}, props, {
    setOpen: setOpen,
    isOpen: open
  }))));
}

DropdownSelector.propsTypes = {
  /**
   * Current language
   */
  current: _propTypes["default"].shape({
    ID: _propTypes["default"].string,
    label: _propTypes["default"].string
  }),

  /**
   * Array of available languages
   */
  available: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    ID: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),

  /**
   * Function called when a lang is selected
   */
  onChange: _propTypes["default"].func
};
DropdownSelector.defaultProps = {
  current: {},
  available: [],
  onChange: function onChange() {}
};
var _default = DropdownSelector;
exports["default"] = _default;