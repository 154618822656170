import React from 'react';
import PropTypes from 'prop-types';
import Prismic from 'prismic-javascript';

export const PrismicContext = React.createContext();

export function PrismicProvider({ repository, children }) {
  if (!repository)
    throw new Error('Could not set PrismicProvider without repository');

  const [prismic, setPrismic] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const endpoint = `https://${repository}.prismic.io/api/v2`;

  // Preview requirement
  window.prismic = { endpoint };

  React.useEffect(() => {
    async function getPrismic() {
      const onTimeout = setTimeout(() => {
        setLoading(false);
        setError(new Error('Prismic API not reachable, more than 5 seconds'));
      }, 5000);

      try {
        const prismic = await Prismic.api(endpoint);
        setPrismic(prismic);
      } catch (err) {
        setError(err);
      } finally {
        clearTimeout(onTimeout);
        setLoading(false);
      }
    }
    getPrismic();
  }, [endpoint]);

  if (loading) return null;
  if (error) throw error;

  return (
    <PrismicContext.Provider value={prismic}>
      {children}
    </PrismicContext.Provider>
  );
}

PrismicProvider.propTypes = {
  repository: PropTypes.string.isRequired
};
