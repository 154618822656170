"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Iframe;

var _core = require("@emotion/core");

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function Iframe(_ref) {
  var url = _ref.url,
      props = _objectWithoutProperties(_ref, ["url"]);

  return (0, _core.jsx)("div", null, (0, _core.jsx)(_Text["default"].h2, {
    mb: 20,
    css: {
      textAlign: 'center'
    }
  }, props.title), (0, _core.jsx)("iframe", _extends({
    css: {
      margin: '0 auto',
      display: 'block'
    },
    src: url
  }, props)));
}

Iframe.defaultProps = {
  width: 560,
  height: 315,
  frameBorder: 0,
  allow: 'autoplay; encrypted-media;'
};