"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _recharts = require("recharts");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function getValueYDelta(info, label, value) {
  var size = getSize(value);

  if (label) {
    if (info) {
      if (size === 'small') return 5;
      if (size === 'normal') return 5;
      if (size === 'big') return 10;
    } else {
      if (size === 'small') return 5;
      return 15;
    }
  } else {
    return info ? 0 : 7;
  }
}

function getSize(value) {
  var length = value.length;
  if (length < 8) return 'big';

  if (length > 8) {
    if (length > 10) return 'small';
  }

  return 'normal';
}

function PieChart(_ref) {
  var size = _ref.size,
      label = _ref.label,
      value = _ref.value,
      info = _ref.info,
      values = _ref.values,
      theme = _ref.theme;
  var labelYDelta = info ? -30 : -25;
  var valueYDelta = getValueYDelta(info, label, value);
  var infoYDelta = label ? 35 : 25;
  return _react["default"].createElement(_recharts.PieChart, {
    width: size,
    height: size
  }, label ? _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 + labelYDelta,
    dy: 4,
    textAnchor: "middle",
    fill: "#999",
    fontSize: "13px"
  }, label) : null, _react["default"].createElement("text", {
    "data-cy": label,
    x: size / 2,
    y: size / 2 + valueYDelta,
    dy: 8,
    textAnchor: "middle",
    fill: "#333",
    fontSize: getSize(value) === 'big' ? '48px' : getSize(value) === 'small' ? '34px' : '38px',
    fontWeight: "bold"
  }, value), info ? _react["default"].createElement("text", {
    x: size / 2,
    y: size / 2 + infoYDelta,
    dy: 4,
    textAnchor: "middle",
    fill: "#999",
    fontSize: "13px"
  }, info) : null, _react["default"].createElement(_recharts.Pie, {
    startAngle: -270,
    data: values,
    dataKey: "value",
    innerRadius: "90%",
    outerRadius: "100%",
    animationBegin: 500,
    animationDuration: 600,
    AnimationEasing: 'ease-out'
  }, values && values.map(function (_ref2, i) {
    var color = _ref2.color;
    return _react["default"].createElement(_recharts.Cell, {
      key: i,
      fill: theme.colors[color] || color
    });
  })));
}

PieChart.propTypes = {
  /**
   * Width and Height, in px
   */
  size: _propTypes["default"].number,

  /**
   * Display above the value
   */
  label: _propTypes["default"].string,

  /**
   * Main display in the middle of the graph
   */
  value: _propTypes["default"].any,

  /**
   * Display below the value
   */
  info: _propTypes["default"].string,

  /**
   * Array of values, each containing a numeric value and a color
   */
  values: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    color: _propTypes["default"].string,
    value: _propTypes["default"].number
  }))
};
PieChart.defaultProps = {
  size: 200,
  values: []
};
var _default = PieChart;
exports["default"] = _default;