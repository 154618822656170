import React from 'react';
import Helmet from 'react-helmet';
import { HeaderOneLine as Header, Footer } from 'component-studio';
import { LayoutContext } from '../contexts/Layout';
import { LanguageContext } from '../contexts/Language';
import { TranslationContext } from '../contexts/Translation';
import linkResolver from '../linkResolver';

export default function Layout({ children }) {
  const { lang, languages, setLang } = React.useContext(LanguageContext);
  const translate = React.useContext(TranslationContext);
  const layout = React.useContext(LayoutContext);
  const { logo, menu, quicklinks, socials, legal, favicon } = layout;

  const cleanLogo = {
    src: logo.url,
    alt: logo.alt
  };

  const cleanMenu = menu.map(({ primary, items }) => ({
    label: primary.label,
    url: linkResolver(primary.link),
    items: items.map(({ link, label }) => ({
      label,
      url: linkResolver(link)
    }))
  }));

  const cleanFooterLinks = quicklinks.map(({ label, link }) => ({
    label,
    url: linkResolver(link)
  }));

  const cleanSocial = socials.map(({ icon, link }) => ({
    icon,
    url: linkResolver(link)
  }));

  return (
    <React.Fragment>
      {favicon && favicon.url ? (
        <Helmet>
          <link
            rel="shortcut icon"
            href={`${favicon.url}?random=${Math.random()}`}
          />
        </Helmet>
      ) : null}

      <Header menu={cleanMenu} logo={cleanLogo} />

      <main role="main">{children}</main>

      <Footer
        links={cleanFooterLinks}
        socials={cleanSocial}
        lang={lang}
        langs={languages}
        langTitle={translate('languages')}
        onLangChange={setLang}
        legal={legal}
      />
    </React.Fragment>
  );
}
