"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _HeaderMobile = _interopRequireDefault(require("./HeaderMobile"));

var _HeaderOneLineDesktop = _interopRequireDefault(require("./HeaderOneLineDesktop"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} // TODO: enable custom icon for each user links ?
// TODO: add eases to animations users


var Header = function Header(props) {
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_HeaderMobile["default"], props), _react["default"].createElement(_HeaderOneLineDesktop["default"], props));
};

Header.propTypes = {
  /**
   * Object with alt and src attribute.
   */
  logo: _propTypes["default"].shape({
    alt: _propTypes["default"].string,
    src: _propTypes["default"].string
  }).isRequired,

  /**
   * Array of Object with 3 properties: items, label, url.
   */
  menu: _propTypes["default"].arrayOf(_propTypes["default"].object),

  /**
   * User object with links and disconnect path.
   */
  user: _propTypes["default"].shape({
    disconnect: _propTypes["default"].object,
    links: _propTypes["default"].array
  })
};
Header.defaultProps = {
  logo: {},
  menu: [],
  user: {}
};
var _default = Header;
exports["default"] = _default;