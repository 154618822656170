"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _ArticleContainer = _interopRequireDefault(require("./ArticleContainer"));

var _Text = _interopRequireDefault(require("../../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')({
  backgroundColor: '#fff',
  marginTop: '40px'
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: 30
});
var Subline = (0, _styled["default"])('div')({
  label: 'Subline',
  marginBottom: '30px',
  overflow: 'hidden'
});
var StyledDate = (0, _styled["default"])(_Text["default"])(function (_ref) {
  var theme = _ref.theme,
      hasCategory = _ref.hasCategory;
  return _objectSpread({
    display: 'inline',
    textTransform: 'uppercase',
    paddingRight: '10px'
  }, hasCategory && {
    borderRight: "2px solid ".concat(theme.colors.label)
  });
});
var StyledCategory = (0, _styled["default"])(_Text["default"])(function (_ref2) {
  var theme = _ref2.theme,
      hasDate = _ref2.hasDate;
  return _objectSpread({
    display: 'inline',
    textTransform: 'uppercase',
    color: theme.colors.label
  }, hasDate && {
    paddingLeft: '10px'
  });
});
var Image = (0, _styled["default"])('img')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    marginBottom: '30px',
    width: '100%'
  }, theme.media.tablet, {
    marginBottom: '40px'
  });
});

function ArticleHeader(_ref5) {
  var title = _ref5.title,
      date = _ref5.date,
      category = _ref5.category,
      image = _ref5.image;
  var hasBoth = date && category;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_ArticleContainer["default"], null, _react["default"].createElement(Title, {
    tag: "h1",
    size: "typo1"
  }, title), date || category ? _react["default"].createElement(Subline, null, date && _react["default"].createElement(StyledDate, {
    hasCategory: hasBoth
  }, date), category && _react["default"].createElement(StyledCategory, {
    hasDate: hasBoth
  }, category)) : null, image && image.src && _react["default"].createElement(Image, {
    src: image.src,
    alt: image.alt
  })));
}

ArticleHeader.propTypes = {
  /**
   * Article header
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * article date
   */
  date: _propTypes["default"].string,

  /**
   * category
   */
  category: _propTypes["default"].string,

  /**
   * Article banner
   */
  image: _propTypes["default"].shape({
    src: _propTypes["default"].string,
    alt: _propTypes["default"].string
  })
};
var _default = ArticleHeader;
exports["default"] = _default;