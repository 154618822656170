"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _BenefitsTable = _interopRequireDefault(require("../components/BenefitsTable"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')({
  marginTop: 30,
  marginBottom: 30
});

function BenefitsTableSlice(props) {
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(_BenefitsTable["default"], props)));
}

BenefitsTableSlice.propTypes = _BenefitsTable["default"].propTypes;
var _default = BenefitsTableSlice;
exports["default"] = _default;