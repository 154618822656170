/**
 * List of available prismic repositories.
 * Each row contains these 2 items:
 *  - The name of the prismic repository
 *  - The regex used on window.location.hostname to get the prismic repository to use
 * Be aware that theses value get override by REACT_APP_PRISMIC_REPOSITORY in the .env file
 */

export default [
  ['phaares', /phaares/],
  ['l-oreal', /l-oreal/],
  ['saint-louis-sucre', /saint-louis-sucre/],
  ['cora', /cora/],
  ['standalone-studio', /studio-/]
];
