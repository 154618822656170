"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.NavItem = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../../components/Container"));

var _Link = _interopRequireDefault(require("../../components/Link"));

var _slugify = _interopRequireDefault(require("slugify"));

var _Notifications = _interopRequireDefault(require("./Notifications"));

var _DropdownSelector = _interopRequireDefault(require("./DropdownSelector"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    backgroundColor: '#fafafa',
    color: '#7F7F7F',
    display: 'none'
  }, theme.media.laptop, {
    display: 'block'
  });
});
var Nav = (0, _styled["default"])('nav')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});
var NavItem = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    transition: 'background-color 0.2s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGray
    }
  };
});
exports.NavItem = NavItem;

var MetaHeader = function MetaHeader(_ref4) {
  var menus = _ref4.menus,
      notifications = _ref4.notifications,
      user = _ref4.user;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(Nav, null, notifications ? _react["default"].createElement(NavItem, null, _react["default"].createElement(_Notifications["default"], _extends({}, notifications, {
    count: user && user.notifications
  }))) : null, menus && menus.length > 0 && menus.map(function (m, i) {
    if (m && (0, _lodash.get)(m, 'available.length', 0) > 1) return _react["default"].createElement(_DropdownSelector["default"], _extends({
      key: i
    }, m));
    return _react["default"].createElement(_Link["default"], {
      key: i,
      href: m.url,
      tracker: 'metaheader-'.concat((0, _slugify["default"])(m.label).toLocaleLowerCase())
    }, _react["default"].createElement(NavItem, null, m.label));
  }))));
};

MetaHeader.propTypes = {
  /**
   * Array of menus
   */
  menus: _propTypes["default"].array,

  /**
   * Properties for the component Notifications
   */
  notifications: _propTypes["default"].shape(_Notifications["default"].propTypes),

  /**
   * user object used in User component
   */
  user: _propTypes["default"].object
};
var _default = MetaHeader;
exports["default"] = _default;