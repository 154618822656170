"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function Currency(_ref) {
  var value = _ref.value,
      currency = _ref.currency,
      locale = _ref.locale;
  if (isNaN(value)) return value;
  var isFloat = value === Math.floor(value);
  var length = Math.floor(value).toString().length;
  var formattedValue = new Intl.NumberFormat(locale, _objectSpread({
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2
  }, isFloat ? {
    maximumSignificantDigits: length
  } : {})).format(value);
  return formattedValue;
}

Currency.propTypes = {
  /**
   * Number to format
   */
  value: _propTypes["default"].number.isRequired,

  /**
   * ISO 4217 devise (https://www.currency-iso.org/en/home/tables/table-a1.html)
   */
  currency: function currency(props, propName, componentName) {
    if (!/^[A-Z]{3}$/.test(props[propName])) {
      return new Error("Invalid prop ".concat(propName, " supplied to ").concat(componentName, ". Validation failed."));
    }
  },

  /**
   * Locale used to format
   */
  locale: _propTypes["default"].string.isRequired
};
Currency.defaultProps = {
  value: 0,
  currency: 'USD',
  locale: 'en-US'
};
var _default = Currency;
exports["default"] = _default;