"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactUse = require("react-use");

var _Icon = _interopRequireDefault(require("../../components/Icon"));

var _AnimatedIcons = _interopRequireDefault(require("../../components/AnimatedIcons"));

var _utils = require("../../theme/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var LangLabel = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    color: theme.colors.darkGray,
    textTransform: 'capitalize'
  };
});
var LangList = (0, _styled["default"])('div')(function (_ref2) {
  var theme = _ref2.theme;
  return {
    border: "1px solid ".concat(theme.colors.gray),
    marginTop: '10px'
  };
});
var LangListItem = (0, _styled["default"])('div')(function (_ref3) {
  var theme = _ref3.theme;
  return {
    listStyle: 'none',
    padding: '15px',
    color: theme.colors.darkGray,
    fontWeight: 600,
    borderBottom: "1px solid ".concat(theme.colors.gray),
    backgroundColor: 'white',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: theme.colors.lightGray
    },
    '&:last-child': {
      borderBottom: '0'
    }
  };
});
var LangListItemSelected = (0, _styled["default"])('div')(function (_ref4) {
  var theme = _ref4.theme,
      selected = _ref4.selected;
  return {
    color: selected ? theme.colors.primary : 'inherit'
  };
});
var FooterLangList = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme,
      isOpen = _ref5.isOpen;
  return _defineProperty({
    background: 'white',
    padding: '20px',
    color: theme.colors.gray,
    userSelect: 'none',
    display: isOpen ? 'block' : 'none'
  }, theme.media.tablet, {
    boxShadow: 'rgba(0,0,0,0.3) 1px 1px 15px 0px',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    right: 0,
    display: 'block',
    transition: isOpen ? 'transform 0.3s, opacity 0.1s ease 0.1s' : 'transform 0.1s ease 0.1s, opacity 0.3s',
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0)' : 'translateY(5px)',
    pointerEvents: isOpen ? 'auto' : 'none'
  });
});

function LangSwitcherList(_ref7) {
  var lang = _ref7.lang,
      langs = _ref7.langs,
      title = _ref7.title,
      onLangChange = _ref7.onLangChange,
      isOpen = _ref7.isOpen;
  return _react["default"].createElement(FooterLangList, {
    isOpen: isOpen
  }, _react["default"].createElement(LangLabel, null, title), _react["default"].createElement(LangList, null, langs.map(function (_lang, i) {
    return _react["default"].createElement(LangListItem, {
      key: i,
      onClick: function onClick() {
        return onLangChange(_lang.id);
      }
    }, _react["default"].createElement(LangListItemSelected, {
      selected: _lang.id === lang.id
    }, _lang.label));
  })));
}

var FooterLangInner = (0, _styled["default"])('div')(function (_ref8) {
  var theme = _ref8.theme;
  return _defineProperty({
    label: 'FooterLang',
    borderBottom: "1px solid ".concat((0, _utils.lighten)(theme.colors.primary, 15)),
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '.6px',
    cursor: 'pointer'
  }, theme.media.tablet, {
    minWidth: '320px',
    margin: 0,
    borderBottom: 0,
    borderRight: "1px solid ".concat((0, _utils.lighten)(theme.colors.primary, 15)),
    transition: 'background-color 0.2s',
    position: 'relative',
    left: '-15px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  });
});
var SelectedLangWrapper = (0, _styled["default"])('div')({
  position: 'relative'
});
var SelectedLang = (0, _styled["default"])('div')({
  paddingLeft: '15px',
  height: '55px',
  display: 'flex',
  alignItems: 'center'
}); // const AnimatedIcon = styled(Icon)(({ open }) => ({
//   height: '28px',
//   width: '28px',
//   position: 'absolute',
//   top: '50%',
//   right: '15px',
//   color: 'white',
//   transition: 'transform 0.3s, color 0.3s',
//   transform: open
//     ? 'translate(0, -50%) rotate(0deg)'
//     : 'translate(0, -50%) rotate(180deg)'
// }));

var WorldIcon = (0, _styled["default"])(_Icon["default"])({
  marginRight: '10px',
  verticalAlign: 'sub'
});
var StyledAnimatedIcon = (0, _styled["default"])(_AnimatedIcons["default"].chevron)({
  position: 'absolute',
  top: '50%',
  right: '15px'
});

function FooterLang(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var ref = (0, _react.useRef)(null);
  (0, _reactUse.useClickAway)(ref, function () {
    return setOpen(false);
  });
  return _react["default"].createElement(FooterLangInner, {
    ref: ref
  }, _react["default"].createElement(SelectedLangWrapper, {
    onClick: function onClick() {
      return setOpen(!open);
    }
  }, _react["default"].createElement(SelectedLang, null, _react["default"].createElement(WorldIcon, {
    name: "globe",
    size: 20
  }), _react["default"].createElement("span", null, props.lang.label || 'Français')), _react["default"].createElement(StyledAnimatedIcon, {
    size: 28,
    open: open
  })), _react["default"].createElement(LangSwitcherList, _extends({}, props, {
    isOpen: open
  })));
}

FooterLang.propTypes = {
  lang: _propTypes["default"].shape({
    ID: _propTypes["default"].string,
    label: _propTypes["default"].string
  }).isRequired,
  langs: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    ID: _propTypes["default"].string,
    label: _propTypes["default"].string
  })).isRequired,
  title: _propTypes["default"].string,
  onLangChange: _propTypes["default"].func.isRequired
};
FooterLang.defaultProps = {
  lang: {},
  langs: [],
  onLangChange: function onLangChange() {}
};
var _default = FooterLang;
exports["default"] = _default;