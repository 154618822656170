"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("./Link"));

var _Icon = _interopRequireDefault(require("./Icon"));

var _Pill = _interopRequireDefault(require("./Pill"));

var _Text = _interopRequireDefault(require("./Text"));

var _utils = require("../theme/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var StyledWrapper = (0, _styled["default"])('div')(function (_ref) {
  var border = _ref.border,
      theme = _ref.theme;
  return {
    label: 'data-table-row',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    // Row with link have border
    border: border ? '1px solid #ccc' : 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    transition: 'background-color 0.2s ease',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.smallLightGray
    },
    '& svg': {
      transition: 'transform 0.15s',
      transform: 'translate3d(0, 0, 0)'
    },
    // When 2 links are one of top of the other, remove the top border of the second one
    '& + &': border ? {
      borderTop: 0
    } : {},
    // Hover state for link
    '&:hover': border ? {
      backgroundColor: (0, _utils.darken)('#fff', 5),
      '& svg': {
        transform: 'translate3d(5px, 0, 0)'
      }
    } : {}
  };
});
var StyledLink = StyledWrapper.withComponent(_Link["default"]);
var StyledPill = (0, _styled["default"])(_Pill["default"])({
  marginRight: 15
});
var Title = (0, _styled["default"])(_Text["default"])({
  flex: 1
});
var Simulation = (0, _styled["default"])('span')({
  fontWeight: 600,
  textDecoration: 'line-through',
  marginLeft: 10
});
var Value = (0, _styled["default"])('span')({
  fontWeight: 600,
  marginLeft: 10
});
var StyledIcon = (0, _styled["default"])(_Icon["default"])({
  paddingLeft: 10
});

function DataTableRow(_ref2) {
  var pill = _ref2.pill,
      title = _ref2.title,
      value = _ref2.value,
      simulation = _ref2.simulation,
      link = _ref2.link; // Displays border if a link is present

  var border = !!link; // Displays a chevron icon at the end of the line if the row
  // as a link and no value

  var chevron = link; // Choose between Link and simple div for the wrapper

  var Wrapper = link ? StyledLink : StyledWrapper;
  return _react["default"].createElement(Wrapper, _extends({
    border: border
  }, link ? {
    to: link
  } : {}), pill ? _react["default"].createElement(StyledPill, {
    color: pill.color,
    large: true
  }, pill.value) : null, _react["default"].createElement(Title, null, title), simulation ? _react["default"].createElement(Simulation, null, simulation) : null, value ? _react["default"].createElement(Value, null, value) : null, chevron ? _react["default"].createElement(StyledIcon, {
    name: "right",
    size: "20"
  }) : null);
}

DataTableRow.propTypes = {
  /**
   * Props for the pill, displays as first element in the row
   */
  pill: _propTypes["default"].shape({
    color: _propTypes["default"].string,
    value: _propTypes["default"].string
  }),

  /**
   * Main title display in the middle of the row
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * Value for this row, displays as the last element of the row
   */
  value: _propTypes["default"].any,

  /**
   * Striked value, representing and old value, displays just before the value
   */
  simulation: _propTypes["default"].any,

  /**
   * Destination if the user click on the row
   */
  link: _propTypes["default"].string
};
var _default = DataTableRow;
exports["default"] = _default;