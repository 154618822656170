"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _MobileCTA = _interopRequireDefault(require("./MobileCTA"));

var _MobileLink = _interopRequireDefault(require("./MobileLink"));

var _MobileLang = _interopRequireDefault(require("./MobileLang"));

var _UserLink = _interopRequireDefault(require("./UserLink"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var MobileListInner = (0, _styled["default"])('div')(function (_ref) {
  var open = _ref.open;
  return {
    background: 'white',
    position: 'absolute',
    top: '100%',
    maxWidth: 370,
    zIndex: 10,
    width: '100%',
    transform: open ? 'translateX(0)' : 'translateX(110%)',
    // 110% to account for usecases where the menu is embeded in a frame. ex: MirrorView
    transition: 'transform 0.5s ease',
    right: 0
  };
});

function MobileMenu(_ref2) {
  var open = _ref2.open,
      links = _ref2.links,
      user = _ref2.user,
      langs = _ref2.langs,
      contact = _ref2.contact;
  return _react["default"].createElement(MobileListInner, {
    open: open
  }, user && user.name ? _react["default"].createElement(_UserLink["default"], {
    name: user.name,
    label: user.label,
    link: user.url,
    notifications: user.notifications
  }) : null, links.map(function (link, i) {
    return _react["default"].createElement(_MobileLink["default"], {
      key: i,
      href: link.url,
      label: link.label
    });
  } // return (
  //   <MobileLink key={i} href={link.url}>
  //     <InnerItemSpan>{link.label}</InnerItemSpan>
  //   </MobileLink>
  // );
  ), contact ? _react["default"].createElement(_MobileLink["default"], {
    href: contact.url,
    label: contact.label
  }) : null, user && user.login ? _react["default"].createElement(_MobileCTA["default"], {
    link: user.login.url,
    label: user.login.label
  }) : //   <Button
  //     href={user.disconnect.url}
  //     large
  //     label={user.disconnect.label}
  //   />
  // </MobileCTA>
  null, user && user.logout ? _react["default"].createElement(_MobileCTA["default"], {
    link: user.logout.url,
    label: user.logout.label
  }) : null, langs && _react["default"].createElement(_MobileLang["default"], langs));
}

var _default = MobileMenu;
exports["default"] = _default;