"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactSpring = require("react-spring");

var _Container = _interopRequireDefault(require("../components/Container"));

var _ContractItem = _interopRequireDefault(require("../components/ContractItem"));

var _SimpleFilters = _interopRequireDefault(require("../components/SimpleFilters"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Wrapper = (0, _styled["default"])('div')({
  marginTop: 70,
  marginBottom: 70
});

var AnimatedList = function AnimatedList(_ref) {
  var items = _ref.items;
  return _react["default"].createElement(_reactSpring.Transition, {
    "native": true,
    items: items,
    keys: function keys(item) {
      return item.key;
    },
    from: {
      opacity: 0,
      y: 10
    },
    enter: {
      opacity: 1,
      y: 0
    },
    leave: {
      opacity: 0,
      y: 0
    },
    config: {
      duration: 200
    }
  }, function (item) {
    return function (_ref2) {
      var opacity = _ref2.opacity,
          y = _ref2.y;
      return _react["default"].createElement(_ContractItem["default"], _extends({}, item, {
        style: {
          opacity: opacity,
          transform: y.interpolate(function (y) {
            return "translate3d(0,".concat(y, "px,0)");
          })
        }
      }));
    };
  });
};

function ContractList(_ref3) {
  var all = _ref3.all,
      contracts = _ref3.contracts,
      filters = _ref3.filters,
      onFilter = _ref3.onFilter;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, filters && filters.length > 0 ? _react["default"].createElement(_SimpleFilters["default"], {
    all: all,
    filters: filters,
    onFilter: onFilter
  }) : null, contracts && contracts.length > 0 ? _react["default"].createElement(AnimatedList, {
    items: contracts
  }) : null));
}

ContractList.propTypes = {
  /**
   * List of props that we will pass to contractItem
   */
  contracts: _propTypes["default"].arrayOf(_propTypes["default"].shape(_ContractItem["default"].propTypes))
};
ContractList.defaultProps = {
  contracts: []
};
var _default = ContractList;
exports["default"] = _default;