"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: '40px'
});
var ContactCTAContainer = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      colorIndex = _ref.colorIndex;
  return {
    label: 'ContactCTA',
    backgroundColor: theme.colors[colorIndex] || colorIndex,
    paddingTop: '70px',
    paddingBottom: '70px'
  };
});
var Image = (0, _styled["default"])('img')(function (_ref2) {
  var theme = _ref2.theme;
  return _defineProperty({
    marginBottom: '15px',
    maxWidth: '80px'
  }, theme.media.laptop, {
    "float": 'left'
  });
});
var ContentWrapper = (0, _styled["default"])(_Text["default"])(function (_ref4) {
  var theme = _ref4.theme,
      hasImage = _ref4.hasImage;
  return _defineProperty({
    label: 'ContactCTAParagraph',
    marginBottom: '0'
  }, theme.media.laptop, {
    textAlign: 'left',
    marginLeft: hasImage ? '120px' : 0,
    maxWidth: hasImage ? '540px' : '660px'
  });
});
var ContainerInner = (0, _styled["default"])('div')(function (_ref6) {
  var theme = _ref6.theme;
  return _defineProperty({
    label: 'ContainerInner',
    minHeight: '140px',
    paddingLeft: '30px',
    paddingRight: '30px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    paddingTop: '30px',
    paddingBottom: '30px',
    backgroundColor: 'white',
    borderRadius: 3
  }, theme.media.tablet, {
    paddingLeft: '30px',
    paddingRight: '30px',
    position: 'relative'
  });
});
var ButtonWrapper = (0, _styled["default"])('div')(function (_ref8) {
  var theme = _ref8.theme;
  return _defineProperty({
    marginTop: '30px'
  }, theme.media.laptop, {
    "float": 'right',
    display: 'block',
    top: '50%',
    position: 'absolute',
    right: '30px',
    bottom: '30px',
    marginTop: '-20px'
  });
});

function ContactCTA(_ref10) {
  var backgroundColorIndex = _ref10.backgroundColorIndex,
      content = _ref10.content,
      image = _ref10.image,
      link = _ref10.link,
      title = _ref10.title;
  var hasImage = image && image.url;
  return _react["default"].createElement(ContactCTAContainer, {
    colorIndex: backgroundColorIndex
  }, _react["default"].createElement(_Container["default"], null, title && _react["default"].createElement(Title, {
    tag: "h2",
    size: "typo1"
  }, title), _react["default"].createElement(ContainerInner, null, hasImage ? _react["default"].createElement(Image, {
    src: image.url,
    alt: image.alt
  }) : null, _react["default"].createElement(ContentWrapper, {
    hasImage: hasImage
  }, content), _react["default"].createElement(ButtonWrapper, null, _react["default"].createElement(_Button["default"], {
    href: link.url,
    tracker: "contact"
  }, link.label)))));
}

ContactCTA.propTypes = {
  /**
   * Component title
   */
  title: _propTypes["default"].string,

  /**
   * Content, can be element(s)
   */
  content: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element, _propTypes["default"].array]),

  /**
   * Button
   */
  link: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  }),

  /**
   * image
   */
  image: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    alt: _propTypes["default"].string
  }),

  /**
   * backgroundColorIndex
   */
  backgroundColorIndex: _propTypes["default"].string
};
ContactCTA.defaultProps = {
  backgroundColorIndex: 'white'
};
var _default = ContactCTA;
exports["default"] = _default;