"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Icon = _interopRequireDefault(require("../Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var StyledChevronIcon = (0, _styled["default"])(_Icon["default"])(function (_ref) {
  var open = _ref.open;
  return {
    height: '28px',
    width: '28px',
    transition: 'transform 0.3s, color 0.3s',
    transform: open ? 'translate(0, -50%) rotate(0deg)' : 'translate(0, -50%) rotate(180deg)'
  };
});

var AnimatedChevronIcon = function AnimatedChevronIcon(props) {
  return _react["default"].createElement(StyledChevronIcon, _extends({}, props, {
    name: 'up'
  }));
};

var _default = AnimatedChevronIcon;
exports["default"] = _default;