"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Icon = _interopRequireDefault(require("../../components/Icon"));

var _Link = _interopRequireDefault(require("../../components/Link"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])(_Link["default"])({
  display: 'flex',
  backgroundColor: '#fff',
  transform: 'scale(1)',
  transition: 'transform 0.3s ease',
  justifyContent: 'space-between',
  boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.3)',
  alignItems: 'center',
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 30,
  paddingRight: 20,
  '&:hover': {
    transform: 'scale(1.03)'
  }
});
var Label = (0, _styled["default"])('span')({
  fontSize: 18,
  letterSpacing: 0.6,
  color: '#333'
});

function Item(_ref) {
  var label = _ref.label,
      url = _ref.url;
  return _react["default"].createElement(Wrapper, {
    href: url
  }, _react["default"].createElement(Label, null, label), url ? _react["default"].createElement(_Icon["default"], {
    name: "right",
    size: 30
  }) : null);
}

Item.propTypes = {
  /**
   * Label displayed on the item
   */
  label: _propTypes["default"].string.isRequired,

  /**
   * Target of the link
   */
  url: _propTypes["default"].string
};
var _default = Item;
exports["default"] = _default;