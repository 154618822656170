"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ServiceCard;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactSpring = require("react-spring");

var _slugify = _interopRequireDefault(require("slugify"));

var _Text = _interopRequireDefault(require("./Text"));

var _Button = _interopRequireDefault(require("./Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])(_reactSpring.animated.div)(function (_ref) {
  var _ref2;

  var theme = _ref.theme,
      link = _ref.link;
  return _ref2 = {
    position: 'relative',
    borderTop: '1px solid #e5e5e5',
    color: theme.colors.text,
    padding: 30,
    paddingBottom: link ? 72 : 0,
    display: 'block',
    backgroundColor: '#fff'
  }, _defineProperty(_ref2, theme.media.laptop, {
    borderRadius: 3,
    border: '1px solid #e5e5e5',
    width: 450,
    marginBottom: 30,
    marginRight: 30,
    '&:nth-child(2n)': {
      marginRight: 0
    }
  }), _defineProperty(_ref2, theme.media.desktop, {
    width: 350,
    '&:nth-child(2n)': {
      marginRight: 30
    },
    '&:nth-child(3n)': {
      marginRight: 0
    }
  }), _ref2;
});
var Category = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme,
      colorKey = _ref3.colorKey;
  return {
    display: 'block',
    marginBottom: 30,
    '&:after': {
      // eslint-disable-next-line quotes
      content: "''",
      display: 'block',
      height: 5,
      width: 40,
      backgroundColor: theme.colors[colorKey] || colorKey,
      position: 'relative',
      top: 12
    }
  };
});
var ButtonWrapper = (0, _styled["default"])('div')({
  position: 'absolute',
  bottom: 30,
  right: 30
});

function ServiceCard(_ref4) {
  var category = _ref4.category,
      colorKey = _ref4.colorKey,
      title = _ref4.title,
      content = _ref4.content,
      action = _ref4.action,
      style = _ref4.style;
  return _react["default"].createElement(Wrapper, {
    style: style,
    link: action && action.url
  }, category ? _react["default"].createElement(Category, {
    size: "typo6",
    colorKey: colorKey
  }, category) : null, _react["default"].createElement(_Text["default"].h3, {
    size: "typo2",
    mb: 30
  }, title), _react["default"].createElement(_Text["default"], {
    tag: "div",
    mb: 30
  }, content), action && action.label && action.url ? _react["default"].createElement(ButtonWrapper, null, _react["default"].createElement(_Button["default"], {
    href: action.url,
    tracker: "service-".concat((0, _slugify["default"])(title).toLowerCase())
  }, action.label)) : null);
}

ServiceCard.propTypes = {
  category: _propTypes["default"].string,
  colorKey: _propTypes["default"].string,
  title: _propTypes["default"].string.isRequired,
  content: _propTypes["default"].any,
  action: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })
};