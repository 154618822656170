"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _reactSpring = require("react-spring");

var _Button = _interopRequireDefault(require("./Button"));

var _Link = _interopRequireDefault(require("./Link"));

var _Icon = _interopRequireDefault(require("./Icon"));

var _Text = _interopRequireDefault(require("./Text"));

var _Pill = _interopRequireDefault(require("./Pill"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])(_reactSpring.animated.div)(function (_ref) {
  var colors = _ref.theme.colors;
  return {
    backgroundColor: colors.white,
    border: "1px solid ".concat(colors.lightGray),
    '&:not(:last-child)': {
      marginBottom: 20
    }
  };
});
var ContractLink = (0, _styled["default"])(_Link["default"])(function (_ref2) {
  var colors = _ref2.theme.colors;
  return {
    display: 'block',
    backgroundColor: colors.white,
    padding: '20px 30px 5px'
  };
});
var ContractBlock = ContractLink.withComponent('div');
var FirstLine = (0, _styled["default"])('div')(function (_ref3) {
  var _ref4;

  var theme = _ref3.theme;
  return _ref4 = {}, _defineProperty(_ref4, theme.media.tablet, {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
    marginBottom: 20
  }), _defineProperty(_ref4, theme.media.laptop, {
    alignItems: 'center'
  }), _ref4;
});
var TitleWrapper = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    marginBottom: 20
  }, theme.media.tablet, {
    marginBottom: 0
  });
});
var StyledLink = (0, _styled["default"])(_Link["default"])({
  label: 'StyledLink',
  '&:hover': {
    textDecoration: 'underline'
  }
});
var StyledWrapperTitle = (0, _styled["default"])('div')(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({}, theme.media.laptop, {
    display: 'inline-block',
    verticalAlign: 'middle'
  });
});
var StyledTitle = (0, _styled["default"])(_Text["default"])(function (_ref9) {
  var theme = _ref9.theme;
  return _defineProperty({
    textTransform: 'uppercase',
    fontWeight: 'normal'
  }, theme.media.laptop, {
    marginRight: 25
  });
});
var SecondLine = (0, _styled["default"])('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  flexWrap: 'wrap'
});
var Item = (0, _styled["default"])('div')(function (_ref11) {
  var _ref12;

  var _ref11$theme = _ref11.theme,
      colors = _ref11$theme.colors,
      media = _ref11$theme.media;
  return _ref12 = {
    display: 'flex',
    alignItems: 'center',
    color: colors.text,
    margin: '15px 0',
    width: '50%',
    position: 'relative',
    lineHeight: '16px'
  }, _defineProperty(_ref12, media.tablet, {
    width: '33%'
  }), _defineProperty(_ref12, media.laptop, {
    width: '16%',
    margin: '0 5px 15px 0'
  }), _ref12;
});
var SubText = (0, _styled["default"])(_Text["default"])({
  display: 'block'
});
var ActionWrapper = (0, _styled["default"])('div')(function (_ref13) {
  var media = _ref13.theme.media;
  return _defineProperty({
    marginBottom: 20
  }, media.tablet, {
    textAlign: 'right',
    marginBottom: 0
  });
});
var ItemIcon = (0, _styled["default"])(_Icon["default"])({
  label: 'ItemIcon',
  marginRight: 20,
  color: '#5a5a5a'
});
var Action = (0, _styled["default"])('div')(function (_ref15) {
  var colors = _ref15.theme.colors;
  return {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: colors.third,
    textAlign: 'center'
  };
});
var ActionText = (0, _styled["default"])('span')(function (_ref16) {
  var theme = _ref16.theme;
  return _defineProperty({
    marginBottom: '20px',
    display: 'block'
  }, theme.media.desktop, {
    display: 'initial',
    marginRight: 25
  });
});
var BaseContract = (0, _styled["default"])('div')(function (_ref18) {
  var colors = _ref18.theme.colors;
  return {
    padding: '10px 25px',
    backgroundColor: colors.smallLightGray,
    color: colors.darkGray
  };
});
var StyledButton = (0, _styled["default"])(_Button["default"])(function (_ref19) {
  var theme = _ref19.theme;
  return _defineProperty({
    marginRight: 10,
    color: '#5a5a5a',
    width: 'initial'
  }, theme.media.tablet, {
    marginLeft: 10,
    marginRight: 0
  });
});
var StyledPill = (0, _styled["default"])('div')(function (_ref21) {
  var theme = _ref21.theme;
  return _defineProperty({
    marginTop: 20,
    textTransform: 'uppercase'
  }, theme.media.laptop, {
    marginTop: 0,
    display: 'inline-block',
    verticalAlign: 'middle'
  });
});

var Status = function Status(props) {
  return _react["default"].createElement(StyledPill, null, _react["default"].createElement(_Pill["default"], props));
}; // const ToolTipContainer = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   background: 'lightgoldenrodyellow',
//   padding: '5px',
//   border: '1px solid gray',
//   bottom: '100%',
//   marginBottom: '10px'
// }));


var CustomValue = (0, _styled["default"])(_Text["default"])(function (_ref23) {
  var tooltip = _ref23.tooltip,
      theme = _ref23.theme;
  return _objectSpread({
    position: 'relative',
    display: 'block'
  }, tooltip ? {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '100%',
      background: 'white',
      width: '10px',
      height: '10px',
      marginBottom: '5px',
      boxShadow: '1px 1px 1px 0px rgba(0,0,0,0.12)',
      transform: 'rotate(45deg) translate(0, -5px)',
      border: "1px solid ".concat(theme.colors.lightGray),
      zIndex: '1',
      borderTopWidth: '0',
      borderLeftWidth: '0',
      opacity: 0,
      transition: 'opacity 0.2s, transform 0.2s' // transform: 'translate(0, -5px)'

    },
    '&:after': {
      content: "\"".concat(tooltip, "\""),
      background: 'white',
      position: 'absolute',
      padding: '10px',
      left: '-10px',
      bottom: '100%',
      marginBottom: '10px',
      minWidth: '90px',
      border: "1px solid ".concat(theme.colors.lightGray),
      borderRadius: 4,
      boxShadow: '0 1px 2px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24)',
      opacity: 0,
      transition: 'opacity 0.2s, transform 0.2s',
      transform: 'translate(0, -5px)',
      lineHeight: '1.2',
      color: theme.colors.text
    },
    '&:hover': {
      '&:before': {
        transform: 'rotate(45deg) translate(0, 0)',
        opacity: 1
      },
      '&:after': {
        opacity: 1,
        transform: 'translate(0, 0)'
      }
    }
  } : {});
});

function CustomValueText(_ref24) {
  var tooltip = _ref24.tooltip,
      value = _ref24.value,
      subValue = _ref24.subValue,
      props = _objectWithoutProperties(_ref24, ["tooltip", "value", "subValue"]);

  return _react["default"].createElement(CustomValue, {
    tooltip: tooltip,
    size: "typo5",
    "data-cy-info": props['data-cy-info']
  }, value, subValue && _react["default"].createElement(SubText, {
    size: "typo6"
  }, subValue));
}

var StyledText = (0, _styled["default"])(_Text["default"])({
  display: 'block'
});

function ContractItem(_ref25) {
  var title = _ref25.title,
      status = _ref25.status,
      action = _ref25.action,
      items = _ref25.items,
      link = _ref25.link,
      baseContract = _ref25.baseContract,
      actionButtons = _ref25.actionButtons,
      style = _ref25.style;
  return _react["default"].createElement(Wrapper, {
    style: style
  }, _react["default"].createElement(ContractBlock, {
    "data-cy": "ContractBlock"
  }, _react["default"].createElement(FirstLine, null, _react["default"].createElement(TitleWrapper, null, _react["default"].createElement(StyledWrapperTitle, null, link ? _react["default"].createElement(StyledLink, {
    href: link,
    tracker: 'link-title'
  }, _react["default"].createElement(StyledTitle, {
    tag: "h3",
    size: "typo3"
  }, title)) : _react["default"].createElement(StyledTitle, {
    tag: "h3",
    size: "typo3"
  }, title)), status && status.label ? _react["default"].createElement(Status, {
    color: status.color,
    label: status.label
  }) : null), _react["default"].createElement(ActionWrapper, null, actionButtons && actionButtons.length > 0 && actionButtons.map(function (props, i) {
    return _react["default"].createElement(StyledButton, _extends({
      key: i
    }, props, {
      size: "normal",
      ghost: true,
      tracker: 'link-icon'
    }));
  }))), items && items.length > 0 ? _react["default"].createElement(SecondLine, null, items.map(function (_ref26, i) {
    var icon = _ref26.icon,
        label = _ref26.label,
        value = _ref26.value,
        subValue = _ref26.subValue,
        tooltip = _ref26.tooltip;
    return (value || subValue) && _react["default"].createElement(Item, {
      key: i
    }, _react["default"].createElement(ItemIcon, {
      name: icon,
      size: 26
    }), _react["default"].createElement("div", null, _react["default"].createElement(StyledText, {
      size: "typo6"
    }, label, ":"), _react["default"].createElement(CustomValueText, {
      "data-cy-info": label,
      tooltip: tooltip,
      value: value,
      subValue: subValue
    })));
  })) : null), action && _react["default"].createElement(Action, null, action.text && _react["default"].createElement(ActionText, null, action.text), _react["default"].createElement(StyledButton, {
    label: action.label,
    href: action.link,
    ghost: true
  })), baseContract && _react["default"].createElement(BaseContract, null, baseContract.label, " ", baseContract.title));
}

ContractItem.propTypes = {
  /**
   * Name of the contract
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * Props for the pill
   */
  status: _propTypes["default"].shape(_Pill["default"].propTypes),

  /**
   * Url for the details of the contracts
   */
  link: _propTypes["default"].string,

  /**
   * List of quick value for the contract
   */
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    icon: _propTypes["default"].string,
    label: _propTypes["default"].string,
    value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number, _propTypes["default"].element])
  })),

  /**
   * Potential action
   */
  action: _propTypes["default"].shape({
    text: _propTypes["default"].string,
    label: _propTypes["default"].string,
    link: _propTypes["default"].string
  }),

  /**
   * Potential actions Buttons
   */
  actionButtons: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    icon: _propTypes["default"].string,
    to: _propTypes["default"].string,
    title: _propTypes["default"].string
  })),

  /**
   * Label and title for the bottom part
   */
  baseContract: _propTypes["default"].shape({
    label: _propTypes["default"].string,
    title: _propTypes["default"].string
  })
};
ContractItem.defaultProps = {
  items: []
};
var _default = ContractItem;
exports["default"] = _default;