"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../components/Link"));

var _SimpleButton = _interopRequireDefault(require("../../components/SimpleButton"));

var _Currency = _interopRequireDefault(require("../../components/Currency"));

var _Text = _interopRequireDefault(require("../../components/Text"));

var _theme = _interopRequireDefault(require("../../theme"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var StyledLink = (0, _styled["default"])(_Link["default"])(function (_ref) {
  var theme = _ref.theme,
      hasLink = _ref.hasLink,
      isEven = _ref.isEven;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _objectSpread({
    label: 'StyledLink',
    display: 'block',
    position: 'relative',
    padding: '20px 10px',
    backgroundColor: theme.colors.white,
    '&:not(:last-child)': {
      borderBottomWidth: 0
    }
  }, isEven ? {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.smallLightGray
    }
  } : {
    border: '1px solid #e5e5e5'
  }, hasLink ? {
    willChange: 'transition',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#fafafa',
      '& .saving-row__label': {
        textDecoration: 'underline'
      }
    }
  } : {});
});
var ColorIndicator = (0, _styled["default"])('span')(function (_ref2) {
  var theme = _ref2.theme,
      colorKey = _ref2.colorKey;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return {
    label: 'ColorIndicator',
    backgroundColor: theme.colors[colorKey] || colorKey,
    borderRadius: '4px',
    marginRight: '15px',
    padding: '7px 15px',
    color: theme.colors.white
  };
});
var Label = (0, _styled["default"])('span')(function (_ref3) {
  var theme = _ref3.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _defineProperty({
    label: 'Label',
    marginBottom: '10px'
  }, theme.media.tablet, {
    marginBottom: 0
  });
});
var Amount = (0, _styled["default"])('span')({
  label: 'Amount',
  fontWeight: 600,
  position: 'absolute',
  right: 0,
  marginRight: '20px'
});

function SavingRow(_ref5) {
  var isEven = _ref5.isEven,
      colorKey = _ref5.colorKey,
      label = _ref5.label,
      amount = _ref5.amount,
      url = _ref5.url,
      currency = _ref5.currency,
      locale = _ref5.locale;
  return _react["default"].createElement(StyledLink, {
    isEven: isEven,
    href: url,
    hasLink: !!url
  }, _react["default"].createElement(ColorIndicator, {
    colorKey: colorKey
  }, "30%"), _react["default"].createElement(Label, {
    className: "saving-row__label"
  }, label), _react["default"].createElement(Amount, null, _react["default"].createElement(_Currency["default"], {
    value: amount,
    currency: currency,
    locale: locale
  })));
}

SavingRow.propTypes = {
  colorKey: _propTypes["default"].string.isRequired,
  label: _propTypes["default"].string.isRequired,
  amount: _propTypes["default"].number,
  url: _propTypes["default"].string,
  currency: _propTypes["default"].string.isRequired,
  locale: _propTypes["default"].string.isRequired
};
var TableWrapper = (0, _styled["default"])('div')(function (_ref6) {
  var theme = _ref6.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return {
    '&:not(:last-child)': _defineProperty({
      marginBottom: '20px'
    }, theme.media.tablet, {
      marginBottom: '30px'
    })
  };
});
var Title = (0, _styled["default"])(_Text["default"])({
  marginBottom: '15px'
});

function Table(_ref7) {
  var isEven = _ref7.isEven,
      title = _ref7.title,
      savings = _ref7.savings,
      currency = _ref7.currency,
      locale = _ref7.locale;
  return _react["default"].createElement(TableWrapper, null, _react["default"].createElement(Title, {
    size: "typo4",
    tag: "h3"
  }, title), savings.map(function (saving, i) {
    return _react["default"].createElement(SavingRow, _extends({
      isEven: isEven
    }, saving, {
      key: i,
      currency: currency,
      locale: locale
    }));
  }));
}

Table.propTypes = {
  title: _propTypes["default"].string.isRequired,
  savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    colorKey: _propTypes["default"].string.isRequired,
    label: _propTypes["default"].string.isRequired,
    amount: _propTypes["default"].number,
    url: _propTypes["default"].string
  })).isRequired,
  currency: _propTypes["default"].string.isRequired,
  locale: _propTypes["default"].string.isRequired
};
Table.defaultProps = {
  savings: []
};
var ActionWrapper = (0, _styled["default"])('div')(function (_ref8) {
  var theme = _ref8.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return _defineProperty({}, theme.media.tablet, {
    display: 'flex',
    justifyContent: 'space-between'
  });
});
var StyledButton = (0, _styled["default"])(_SimpleButton["default"])(function (_ref10) {
  var theme = _ref10.theme;
  if (!Object.keys(theme).length) theme = _theme["default"];
  return {
    display: 'block',
    '&:not(:last-child)': _defineProperty({
      marginBottom: '15px'
    }, theme.media.tablet, {
      marginBottom: 0
    }),
    '&:hover': {
      textDecoration: 'underline'
    }
  };
});

function SavingTables(_ref11) {
  var tables = _ref11.tables,
      actions = _ref11.actions,
      currency = _ref11.currency,
      locale = _ref11.locale;
  return _react["default"].createElement("div", null, tables.map(function (_ref12, i) {
    var title = _ref12.title,
        savings = _ref12.savings;
    var hasLinks = savings.filter(function (o) {
      return o.url;
    }).length;
    return _react["default"].createElement(Table, {
      isEven: !(hasLinks > 0),
      title: title,
      savings: savings,
      key: i,
      currency: currency,
      locale: locale
    });
  }), actions.length > 0 ? _react["default"].createElement(ActionWrapper, null, actions.map(function (_ref13, i) {
    var url = _ref13.url,
        label = _ref13.label;
    return _react["default"].createElement(StyledButton, {
      href: url,
      key: i
    }, label);
  })) : null);
}

SavingTables.propTypes = {
  tables: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    title: _propTypes["default"].string.isRequired,
    savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      colorKey: _propTypes["default"].string.isRequired,
      label: _propTypes["default"].string.isRequired,
      amount: _propTypes["default"].number,
      url: _propTypes["default"].string
    })).isRequired
  })),
  actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })),
  currency: _propTypes["default"].string.isRequired,
  locale: _propTypes["default"].string.isRequired
};
SavingTables.defaultProps = {
  tables: [],
  actions: []
};
var _default = SavingTables;
exports["default"] = _default;