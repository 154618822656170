"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../../components/Link"));

var _Icon = _interopRequireDefault(require("../../../components/Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var StyledLink = (0, _styled["default"])(_Link["default"])(function (_ref) {
  var theme = _ref.theme;
  return {
    backgroundColor: 'white',
    height: 52,
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 28,
    paddingRight: 20,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    borderBottom: "2px solid ".concat(theme.colors.lightGray)
  };
});

function MobileLink(_ref2) {
  var to = _ref2.to,
      label = _ref2.label;
  return _react["default"].createElement(StyledLink, {
    href: to
  }, label, _react["default"].createElement(_Icon["default"], {
    name: "right"
  }));
}

var _default = MobileLink;
exports["default"] = _default;