"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _DataTableRow = _interopRequireDefault(require("./DataTableRow"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Wrapper = (0, _styled["default"])('div')({});
var TitleWrapper = (0, _styled["default"])('div')({
  marginBottom: 20,
  overflow: 'hidden'
});
var Title = (0, _styled["default"])('span')({
  fontSize: 18,
  fontWeight: 600,
  "float": 'left'
});
var Info = (0, _styled["default"])('span')({
  "float": 'right',
  fontSize: 16
});

function DataTable(_ref) {
  var title = _ref.title,
      info = _ref.info,
      rows = _ref.rows;
  return _react["default"].createElement(Wrapper, null, title || info ? _react["default"].createElement(TitleWrapper, null, _react["default"].createElement(Title, null, title), info ? _react["default"].createElement(Info, null, info) : null) : null, _react["default"].createElement("div", null, rows.length > 0 ? rows.map(function (row, i) {
    return _react["default"].createElement(_DataTableRow["default"], _extends({}, row, {
      key: i
    }));
  }) : null));
}

DataTable.propTypes = {
  /**
   * Title, displayed above the table
   */
  title: _propTypes["default"].string,

  /**
   * Small info displayed at the right of the title
   */
  info: _propTypes["default"].string,

  /**
   * Array of props that each row will take
   */
  rows: _propTypes["default"].arrayOf(_propTypes["default"].shape(_DataTableRow["default"].propTypes))
};
DataTable.defaultProps = {
  rows: []
};
var _default = DataTable;
exports["default"] = _default;