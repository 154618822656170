"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = SimpleButton;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("./Link"));

var _Icon = _interopRequireDefault(require("./Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Button = (0, _styled["default"])(_Link["default"])(function (_ref) {
  var theme = _ref.theme,
      color = _ref.color;
  return {
    label: 'simple-button',
    cursor: 'pointer',
    outline: 'none',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    color: theme.colors[color] || color,
    fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    fontSize: '14px',
    transition: 'color 0.3s',
    display: 'inline-block',
    '&:hover': {
      color: '#000047'
    }
  };
});
var Label = (0, _styled["default"])('span')({
  label: 'simple-button-label',
  lineHeight: '20px'
});
var StyledIcon = (0, _styled["default"])(_Icon["default"])(function (_ref2) {
  var left = _ref2.left;
  return {
    label: 'simple-button-icon',
    marginLeft: left ? 0 : 15,
    marginRight: left ? 5 : 0,
    verticalAlign: 'bottom'
  };
});

function SimpleButton(_ref3) {
  var icon = _ref3.icon,
      iconPosition = _ref3.iconPosition,
      children = _ref3.children,
      props = _objectWithoutProperties(_ref3, ["icon", "iconPosition", "children"]);

  var left = iconPosition === 'left';

  var IconComponent = _react["default"].createElement(StyledIcon, {
    name: icon,
    size: 20,
    left: left,
    key: "icon"
  });

  var content = [icon && left ? IconComponent : null, children ? _react["default"].createElement(Label, {
    key: "label"
  }, children) : null, icon && !left ? IconComponent : null];
  return _react["default"].createElement(Button, props, content);
}

SimpleButton.propTypes = {
  icon: _propTypes["default"].string,
  iconPosition: _propTypes["default"].oneOf(['left', 'right'])
};
SimpleButton.defaultProps = {
  color: 'primary'
};