"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../../components/Container"));

var _Item = _interopRequireDefault(require("./Item"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var bg = _ref.bg;
  return {
    backgroundColor: bg,
    paddingTop: 80,
    paddingBottom: 80
  };
});
var Title = (0, _styled["default"])('div')({
  fontSize: 28,
  fontWeight: 600,
  color: '#333',
  marginBottom: 52
});
var ItemWrapper = (0, _styled["default"])('div')({
  '&:not(:last-child)': {
    marginBottom: 16
  }
});

function ItemList(_ref2) {
  var title = _ref2.title,
      items = _ref2.items,
      backgroundColor = _ref2.backgroundColor;
  return _react["default"].createElement(Wrapper, {
    bg: backgroundColor
  }, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(Title, null, title) : null, items.map(function (item, i) {
    return _react["default"].createElement(ItemWrapper, {
      key: i
    }, _react["default"].createElement(_Item["default"], item));
  })));
}

ItemList.propTypes = {
  /**
   * Slice title
   */
  title: _propTypes["default"].string,

  /**
   * Color of the background
   */
  backgroundColor: _propTypes["default"].string,

  /**
   * Array of Item properties
   */
  items: _propTypes["default"].arrayOf(_propTypes["default"].shape(_Item["default"].propTypes))
};
ItemList.defaultProps = {
  backgroundColor: 'transparent',
  items: []
};
var _default = ItemList;
exports["default"] = _default;