"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Link = _interopRequireDefault(require("../../components/Link"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} // width and height are HTML keywords and can't be used in styled


var StyledLink = (0, _styled["default"])(_Link["default"])(function (_ref) {
  var _width = _ref._width,
      _height = _ref._height;
  return {
    height: _height,
    width: _width,
    display: 'block'
  };
});
var Image = (0, _styled["default"])('img')({
  height: '100%',
  maxWidth: '100%'
});

function Logo(_ref2) {
  var src = _ref2.src,
      alt = _ref2.alt,
      width = _ref2.width,
      height = _ref2.height,
      to = _ref2.to;
  return _react["default"].createElement(StyledLink, {
    href: to,
    _width: width,
    _height: height
  }, _react["default"].createElement(Image, {
    src: src,
    alt: alt
  }));
}

Logo.propTypes = {
  /**
   * Logo src
   */
  src: _propTypes["default"].string,

  /**
   * Logo alt
   */
  alt: _propTypes["default"].string,

  /**
   * Width of the logo
   */
  width: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string]),

  /**
   * Height of the logo
   */
  height: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string]),

  /**
   * Destination of the logo link
   */
  to: _propTypes["default"].string
};
Logo.defaultProps = {
  height: '60px',
  alt: 'Logo',
  to: '/'
};
var _default = Logo;
exports["default"] = _default;