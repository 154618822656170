import React from 'react';
import { ContactCTA } from 'component-studio';
import linkResolver from '../linkResolver';
import RichText from '../components/RichText';

function PrismicContactCTA({
  background_color_contact,
  content_contact,
  image_contact,
  link_contact,
  link_label_contact
}) {
  return (
    <ContactCTA
      backgroundColorIndex={background_color_contact}
      content={<RichText>{content_contact}</RichText>}
      image={image_contact}
      link={{
        label: link_label_contact,
        url: linkResolver(link_contact)
      }}
    />
  );
}

export default PrismicContactCTA;
