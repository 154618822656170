"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Icon = _interopRequireDefault(require("./Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      active = _ref.active,
      onClick = _ref.onClick;
  return _defineProperty({
    backgroundColor: '#fff',
    padding: '0 20px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    cursor: onClick ? 'pointer' : 'auto',
    '&:not(:last-child)': _defineProperty({
      borderBottom: '1px solid #e5e5e5'
    }, theme.media.laptop, {
      borderBottom: active ? '3px solid #00adc6' : '1px solid #e5e5e5'
    })
  }, theme.media.laptop, {
    maxWidth: '220px',
    border: active ? '3px solid #00adc6' : '1px solid #e5e5e5',
    padding: active ? '0 18px' : '',
    height: '90px',
    borderRadius: 3,
    transition: 'border-color 0.2s ease',
    '&:hover': onClick ? {
      padding: '0 18px',
      border: '3px solid #00adc6'
    } : {}
  });
});
var IconWrapper = (0, _styled["default"])('span')(function (_ref3) {
  var theme = _ref3.theme,
      colorKey = _ref3.colorKey;
  return {
    marginRight: '15px',
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    backgroundColor: theme.colors[colorKey] || colorKey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
});
var Label = (0, _styled["default"])('span')({
  fontSize: '16px',
  lineHeight: 1.56,
  fontWeight: 600,
  flex: 1
});
var CheckIcon = (0, _styled["default"])(_Icon["default"])(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty({
    color: theme.colors.primary
  }, theme.media.laptop, {
    visibility: 'hidden',
    width: 0,
    height: 0
  });
});

function ServiceCheckbox(_ref6) {
  var icon = _ref6.icon,
      colorKey = _ref6.colorKey,
      label = _ref6.label,
      onClick = _ref6.onClick,
      active = _ref6.active;
  return _react["default"].createElement(Wrapper, {
    onClick: onClick,
    active: active
  }, _react["default"].createElement(IconWrapper, {
    colorKey: colorKey
  }, icon), _react["default"].createElement(Label, null, label), active ? _react["default"].createElement(CheckIcon, {
    name: "check",
    size: "18px"
  }) : null);
}

ServiceCheckbox.propTypes = {
  /**
   * Icon element
   */
  icon: _propTypes["default"].element,

  /**
   * Key of the color used as background behind the icon
   */
  colorKey: _propTypes["default"].string,

  /**
   * Service label
   */
  label: _propTypes["default"].string.isRequired,

  /**
   * onClick listener
   */
  onClick: _propTypes["default"].func,

  /**
   * Tell the card if it's active or no
   */
  active: _propTypes["default"].bool
};
ServiceCheckbox.defaultProps = {
  active: false
};
var _default = ServiceCheckbox;
exports["default"] = _default;