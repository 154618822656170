"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _Container = _interopRequireDefault(require("../components/Container"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')({
  backgroundColor: '#fff',
  paddingBottom: '30px'
});
var NotificationInner = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme,
      backgroundColor = _ref.backgroundColor;
  return _defineProperty({
    label: 'NotificationInner',
    backgroundColor: backgroundColor,
    padding: '20px',
    '&:after': {
      display: 'block',
      content: '""',
      clear: 'both'
    }
  }, theme.media.desktop, {
    padding: '35px 40px',
    position: 'relative'
  });
});
var TextWrapper = (0, _styled["default"])(_Text["default"])(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    label: 'TextWrapper'
  }, theme.media.desktop, {
    marginBottom: 0,
    width: '70%',
    paddingRight: '30px',
    "float": 'left'
  });
});
var CTAContainer = (0, _styled["default"])('div')(function (_ref5) {
  var theme = _ref5.theme;
  return _defineProperty({
    label: 'CTAContainer'
  }, theme.media.desktop, {
    width: '30%',
    textAlign: 'center',
    "float": 'left',
    position: 'absolute',
    top: '50%',
    right: '30px',
    marginTop: '-25px'
  });
});
var CTAWrapper = (0, _styled["default"])(_Button["default"])(function (_ref7) {
  var theme = _ref7.theme;
  return _defineProperty({
    label: 'CTAWrapper',
    width: '100%'
  }, theme.media.tablet, {
    width: 'auto'
  });
});

var Notification = function Notification(_ref9) {
  var content = _ref9.content,
      backgroundColor = _ref9.backgroundColor,
      link = _ref9.link;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(NotificationInner, {
    backgroundColor: backgroundColor
  }, _react["default"].createElement(TextWrapper, null, content), _react["default"].createElement(CTAContainer, null, _react["default"].createElement(CTAWrapper, {
    href: link.url
  }, link.label)))));
};

var _default = Notification;
exports["default"] = _default;