"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../components/Link"));

var _Icon = _interopRequireDefault(require("../../components/Icon"));

var _utils = require("../../theme/utils");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FooterSocialsWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'FooterScials',
    padding: '15px',
    borderBottom: "1px solid ".concat((0, _utils.darken)(theme.colors.primary, 10)),
    display: 'flex',
    justifyContent: 'center',
    '> ul': {
      paddingLeft: 0
    }
  }, theme.media.tablet, {
    padding: '13px 0',
    borderBottom: 0
  });
});
var Li = (0, _styled["default"])('li')(function (_ref3) {
  var theme = _ref3.theme;
  return _defineProperty({
    display: 'inline-block',
    marginRight: '20px',
    marginBottom: '0',
    '&:last-child': {
      marginRight: 0
    }
  }, theme.media.tablet, {
    marginRight: '15px'
  });
});
var StyledIcon = (0, _styled["default"])(_Icon["default"])(function (_ref5) {
  var _ref6;

  var theme = _ref5.theme;
  return _ref6 = {
    '& path': {
      transition: 'fill 0.3s'
    },
    width: '28px',
    height: '28px'
  }, _defineProperty(_ref6, theme.media.tablet, {
    height: '24px',
    width: '24px'
  }), _defineProperty(_ref6, theme.media.desktop, {
    height: '32px',
    width: '32px'
  }), _ref6;
});
var StyledLink = (0, _styled["default"])(_Link["default"])({
  display: 'block',
  lineHeight: 0,
  opacity: 1,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 0.8
  }
});

function FooterSocials(_ref7) {
  var socials = _ref7.socials;
  return _react["default"].createElement(FooterSocialsWrapper, null, _react["default"].createElement("ul", null, socials.map(function (social, i) {
    return _react["default"].createElement(Li, {
      key: i
    }, _react["default"].createElement(StyledLink, {
      href: social.url
    }, _react["default"].createElement(StyledIcon, {
      size: 30,
      name: social.icon
    })));
  })));
}

FooterSocials.propTypes = {
  socials: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string,
    icon: _propTypes["default"].string
  })).isRequired
};
FooterSocials.defaultProps = {
  socials: []
};
var _default = FooterSocials;
exports["default"] = _default;