"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled2 = _interopRequireDefault(require("@emotion/styled"));

var _reactSlick = _interopRequireDefault(require("react-slick"));

var _reactProgressiveImage = _interopRequireDefault(require("react-progressive-image"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Text = _interopRequireDefault(require("../components/Text"));

var _media = _interopRequireWildcard(require("../theme/media"));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var CardWrapper = (0, _styled2["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme,
      backgroundColor = _ref.backgroundColor;
  return _ref2 = {
    backgroundColor: theme.colors[backgroundColor] || backgroundColor,
    border: "1px solid ".concat(theme.colors.lightGray),
    height: '600px',
    overflow: 'hidden',
    width: '320px',
    margin: '0 auto',
    padding: '30px',
    textAlign: 'center',
    display: 'flex',
    color: theme.colors.text,
    flexDirection: 'column'
  }, _defineProperty(_ref2, _media["default"].tablet, {
    width: '333px',
    // let space for boxShadow
    marginLeft: '10px'
  }), _defineProperty(_ref2, _media["default"].laptop, {
    width: '350px',
    marginLeft: 'auto'
  }), _ref2;
});
var ImageWrapper = (0, _styled2["default"])('div')({
  marginBottom: '30px'
});
var Image = (0, _styled2["default"])('img')(function (_ref3) {
  var loading = _ref3.loading;
  return {
    willChange: 'opacity',
    transition: 'opacity 0.5s',
    opacity: loading ? 0 : 1,
    maxHeight: '200px',
    minHeight: '140px',
    margin: '20px auto'
  };
});
var TitleWrapper = (0, _styled2["default"])(_Text["default"])({
  marginBottom: '30px'
});
var TextWrapper = (0, _styled2["default"])(_Text["default"])({
  textAlign: 'center',
  flex: 1
});

function Card(_ref4) {
  var image = _ref4.image,
      title = _ref4.title,
      text = _ref4.text,
      action = _ref4.action,
      backgroundCardColor = _ref4.backgroundCardColor;
  return _react["default"].createElement(CardWrapper, {
    backgroundColor: backgroundCardColor
  }, _react["default"].createElement(ImageWrapper, null, _react["default"].createElement(_reactProgressiveImage["default"], {
    src: image.src
  }, function (src, loading) {
    return _react["default"].createElement(Image, {
      loading: loading,
      src: src,
      alt: image.alt
    });
  })), _react["default"].createElement(TitleWrapper, {
    size: "typo3"
  }, title), _react["default"].createElement(TextWrapper, null, text), action ? _react["default"].createElement("div", null, _react["default"].createElement(_Button["default"], {
    href: action.url,
    color: "secondary"
  }, action.label)) : null);
}

Card.propTypes = {
  /**
   * Image of the card, src+alt
   */
  image: _propTypes["default"].shape({
    src: _propTypes["default"].string.isRequired,
    alt: _propTypes["default"].string.isRequired
  }).isRequired,

  /**
   * Card title
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * Text below title
   */
  text: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]).isRequired,

  /**
   * Final CTA
   */
  action: _propTypes["default"].shape({
    label: _propTypes["default"].string,
    url: _propTypes["default"].string
  })
};
var Wrapper = (0, _styled2["default"])('div')({
  padding: '70px 0'
});
var SliceTitleWrapper = (0, _styled2["default"])(_Text["default"])({
  marginBottom: '50px'
});
var SliderWrapper = (0, _styled2["default"])('div')(_defineProperty({
  marginLeft: '-15px',
  marginRight: '-15px',
  paddingBottom: '30px'
}, _media["default"].tablet, {
  marginLeft: 0,
  marginRight: 0
}));
var sliderSettings = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  responsive: [{
    breakpoint: _media.breakpoints.desktop - 1,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: _media.breakpoints.tablet - 1,
    settings: {
      slidesToShow: 1
    }
  }]
};

function ThreeCards(_ref5) {
  var title = _ref5.title,
      cards = _ref5.cards,
      backgroundCardColor = _ref5.backgroundCardColor;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, title ? _react["default"].createElement(SliceTitleWrapper, {
    size: "typo2",
    tag: "h2",
    title: title
  }) : null, _react["default"].createElement(SliderWrapper, null, _react["default"].createElement(_reactSlick["default"], sliderSettings, cards.map(function (card, i) {
    return _react["default"].createElement(Card, _extends({}, card, {
      backgroundCardColor: backgroundCardColor,
      key: i
    }));
  })))));
}

ThreeCards.propTypes = {
  /**
   * Title as H2 above the cards
   */
  title: _propTypes["default"].string,

  /**
   * Array of cards
   */
  cards: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    image: _propTypes["default"].shape({
      src: _propTypes["default"].string.isRequired,
      alt: _propTypes["default"].string.isRequired
    }).isRequired,
    title: _propTypes["default"].string.isRequired,
    text: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].element]).isRequired,
    action: _propTypes["default"].shape({
      url: _propTypes["default"].string,
      label: _propTypes["default"].string
    })
  })).isRequired
};
ThreeCards.defaultProps = {
  cards: []
};
var _default = ThreeCards;
exports["default"] = _default;