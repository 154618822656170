"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _ArticleContainer = _interopRequireDefault(require("./ArticleContainer"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var ArticleContent = (0, _styled["default"])(_ArticleContainer["default"])(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'ArticleContent',
    marginBottom: '50px',
    fontSize: 16,
    lineHeight: 1.5,
    fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
    color: theme.colors.text
  }, theme.media.tablet, {
    fontSize: 18,
    lineHeight: 1.67
  });
});
var _default = ArticleContent;
exports["default"] = _default;