"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Banner;

var _core = require("@emotion/core");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Container = _interopRequireDefault(require("../components/Container"));

var _Button = _interopRequireDefault(require("../components/Button"));

var _Text = _interopRequireDefault(require("../components/Text"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var coverOpacity = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  background: '#fff',
  opacity: 0.2
};

function Cover(_ref) {
  var _objectSpread2;

  var src = _ref.src,
      background = _ref.background,
      children = _ref.children;

  var theme = _react["default"].useContext(_core.ThemeContext);

  return (0, _core.jsx)("div", {
    css: _objectSpread({
      position: 'relative',
      padding: '40px 0 35px',
      backgroundSize: 'cover'
    }, src ? {
      backgroundImage: "url(".concat(src, ")")
    } : {}, (_objectSpread2 = {
      backgroundColor: background,
      backgroundPosition: 'center',
      textAlign: 'center',
      color: theme.colors.white,
      ':after': coverOpacity
    }, _defineProperty(_objectSpread2, theme.media.tablet, {
      padding: '70px 0'
    }), _defineProperty(_objectSpread2, theme.media.laptop, {
      textAlign: 'left'
    }), _objectSpread2))
  }, children);
}

var Wrapper = (0, _styled["default"])('div')(function (_ref2) {
  var _ref3;

  var theme = _ref2.theme;
  return _ref3 = {
    position: 'relative',
    zIndex: 1
  }, _defineProperty(_ref3, theme.media.tablet, {
    margin: '0 30px'
  }), _defineProperty(_ref3, theme.media.laptop, {
    margin: '0 130px'
  }), _defineProperty(_ref3, theme.media.desktop, {
    margin: '0 95px'
  }), _ref3;
});

function Category(_ref4) {
  var children = _ref4.children;
  return (0, _core.jsx)(_Text["default"], {
    size: "typo6",
    css: {
      textTransform: 'uppercase',
      color: 'white'
    }
  }, children);
}

function Separator() {
  var _ref5;

  var theme = _react["default"].useContext(_core.ThemeContext);

  return (0, _core.jsx)("div", {
    css: (_ref5 = {
      width: '25px',
      height: '1px',
      margin: '15px auto 10px',
      background: 'white'
    }, _defineProperty(_ref5, theme.media.tablet, {
      width: '40px',
      margin: '20px auto 15px'
    }), _defineProperty(_ref5, theme.media.laptop, {
      margin: '20px 0 15px'
    }), _ref5)
  });
}

function Title(_ref6) {
  var children = _ref6.children;

  var theme = _react["default"].useContext(_core.ThemeContext);

  return (0, _core.jsx)(_Text["default"].h1, {
    css: _defineProperty({
      marginBottom: '40px'
    }, theme.media.tablet, {
      marginBottom: '30px'
    })
  }, children);
}

function Subtitle(_ref8) {
  var children = _ref8.children;

  var theme = _react["default"].useContext(_core.ThemeContext);

  return (0, _core.jsx)(_Text["default"].p, {
    css: _defineProperty({
      marginBottom: '35px'
    }, theme.media.tablet, {
      marginBottom: '45px'
    })
  }, children);
}

function Action(_ref10) {
  var url = _ref10.url,
      label = _ref10.label;
  return (0, _core.jsx)(_Button["default"], {
    size: "big",
    href: url
  }, label);
}

function Banner(_ref11) {
  var background = _ref11.background,
      cover = _ref11.cover,
      title = _ref11.title,
      category = _ref11.category,
      subtitle = _ref11.subtitle,
      action = _ref11.action;

  var theme = _react["default"].useContext(_core.ThemeContext);

  return (0, _core.jsx)(Cover, {
    src: cover,
    background: background || theme.colors.primary
  }, (0, _core.jsx)(_Container["default"], null, (0, _core.jsx)(Wrapper, null, category && (0, _core.jsx)(Category, null, category), category && (0, _core.jsx)(Separator, null), (0, _core.jsx)(Title, null, title), subtitle && (0, _core.jsx)(Subtitle, null, subtitle), action && action.url && action.label && (0, _core.jsx)(Action, action))));
}

Banner.propTypes = {
  background: _propTypes["default"].string,
  cover: _propTypes["default"].string,
  title: _propTypes["default"].string.isRequired,
  category: _propTypes["default"].string,
  action: _propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })
};