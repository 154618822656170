"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FooterLegal = (0, _styled["default"])('div')(function (_ref) {
  var _ref2;

  var theme = _ref.theme;
  return _ref2 = {
    label: 'FooterLegal',
    fontSize: '12px',
    textAlign: 'center',
    padding: '10px 0'
  }, _defineProperty(_ref2, theme.media.tablet, {
    flex: 1,
    fontSize: '14px',
    '&:not(:first-of-type)': {
      textAlign: 'right'
    }
  }), _defineProperty(_ref2, theme.media.laptop, {
    textAlign: 'right'
  }), _ref2;
});
var _default = FooterLegal;
exports["default"] = _default;