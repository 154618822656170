"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _BudgetRepartitionCard = _interopRequireDefault(require("../../components/BudgetRepartitionCard"));

var _Text = _interopRequireDefault(require("../../components/Text"));

var _Container = _interopRequireDefault(require("../../components/Container"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Wrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return {
    label: 'Savings',
    backgroundColor: theme.colors.white,
    padding: '50px 0'
  };
});
var Cards = (0, _styled["default"])('div')(function (_ref2) {
  var theme = _ref2.theme;
  return _defineProperty({
    label: 'Saving-cards',
    marginTop: '35px'
  }, theme.media.tablet, {
    display: 'flex',
    justifyContent: 'space-between'
  });
});

function Savings(_ref4) {
  var title = _ref4.title,
      card1 = _ref4.card1,
      card2 = _ref4.card2,
      currency = _ref4.currency,
      locale = _ref4.locale;
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(_Container["default"], null, _react["default"].createElement(_Text["default"], {
    size: "typo3"
  }, title), _react["default"].createElement(Cards, null, card1 ? _react["default"].createElement(_BudgetRepartitionCard["default"], _extends({}, card1, {
    currency: currency,
    locale: locale
  })) : null, card2 ? _react["default"].createElement(_BudgetRepartitionCard["default"], _extends({}, card2, {
    currency: currency,
    locale: locale
  })) : null)));
}

Savings.propTypes = {
  /**
   * Component title
   */
  title: _propTypes["default"].string.isRequired,

  /**
   * First card, on the left
   */
  card1: _propTypes["default"].shape({
    title: _propTypes["default"].string,
    chartLabel: _propTypes["default"].string,
    savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      label: _propTypes["default"].string,
      value: _propTypes["default"].number,
      color: _propTypes["default"].string
    })),
    actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      url: _propTypes["default"].string,
      label: _propTypes["default"].string
    }))
  }),

  /**
   * Second card, on the left
   */
  card2: _propTypes["default"].shape({
    title: _propTypes["default"].string,
    chartLabel: _propTypes["default"].string,
    savings: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      label: _propTypes["default"].string,
      value: _propTypes["default"].number,
      currency: _propTypes["default"].string,
      color: _propTypes["default"].string
    })),
    actions: _propTypes["default"].arrayOf(_propTypes["default"].shape({
      url: _propTypes["default"].string,
      label: _propTypes["default"].string
    }))
  }),

  /**
   * currency of the value
   */
  currency: _propTypes["default"].string.isRequired,

  /**
   * Locale used to format the money
   */
  locale: _propTypes["default"].string.isRequired
};
var _default = Savings;
exports["default"] = _default;