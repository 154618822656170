"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var size = 24;
var margin = size / 4;
var AnimatedBurger = (0, _styled["default"])('div')(function (_ref) {
  var isOpen = _ref.isOpen,
      theme = _ref.theme;
  return {
    width: "".concat(size, "px"),
    height: "".concat(size, "px"),
    position: 'relative',
    margin: "".concat(margin, "px 15px"),
    cursor: 'pointer',
    '& span': {
      display: 'block',
      position: 'absolute',
      height: '2px',
      width: '100%',
      background: theme.colors.primary,
      borderRadius: '2px',
      opacity: 1,
      left: 0,
      transform: 'rotate(0deg)',
      transition: '0.3s cubic-bezier(0.075, 0.82, 0.165, 1)',
      willChange: 'transform'
    },
    '& span:nth-of-type(1)': _objectSpread({
      top: "".concat(margin, "px")
    }, isOpen ? {
      top: "".concat(margin, "px"),
      transform: 'rotate(-135deg)',
      width: "".concat(size / 2 - 2, "px")
    } : {}),
    '& span:nth-of-type(2)': _objectSpread({
      top: "".concat(margin * 2, "px")
    }, isOpen ? {
      top: "".concat(margin * 2, "px"),
      left: "".concat(0, "px"),
      transform: 'rotate(135deg)'
    } : {}),
    '& span:nth-of-type(3)': _objectSpread({
      top: "".concat(margin * 3, "px")
    }, isOpen ? {
      top: "".concat(margin * 3, "px"),
      transform: 'rotate(-135deg)',
      left: "".concat(size - margin * 2, "px"),
      width: "".concat(size / 2 - 2, "px")
    } : {})
  };
});

var MenuHeaderButton = function MenuHeaderButton(_ref2) {
  var _ref2$isOpen = _ref2.isOpen,
      isOpen = _ref2$isOpen === void 0 ? false : _ref2$isOpen,
      onClick = _ref2.onClick;
  return _react["default"].createElement(AnimatedBurger, {
    isOpen: isOpen,
    onClick: onClick
  }, _react["default"].createElement("span", null), _react["default"].createElement("span", null), _react["default"].createElement("span", null));
};

var _default = MenuHeaderButton;
exports["default"] = _default;