"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Link = _interopRequireDefault(require("../../components/Link"));

var _slugify = _interopRequireDefault(require("slugify"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var FooterLinkWrapper = (0, _styled["default"])('div')(function (_ref) {
  var theme = _ref.theme;
  return _defineProperty({
    label: 'FooterLinks',
    padding: '20px 15px',
    ' > ul': {
      paddingLeft: 0
    }
  }, theme.media.tablet, {
    padding: '20px 0'
  });
});
var Li = (0, _styled["default"])('li')(function (_ref3) {
  var _ref4;

  var theme = _ref3.theme;
  return _ref4 = {
    paddingRight: '10px',
    marginRight: '10px'
  }, _defineProperty(_ref4, theme.media.tablet, {
    display: 'inline-block'
  }), _defineProperty(_ref4, '&:not(:last-child)', _defineProperty({
    marginBottom: '10px'
  }, theme.media.tablet, {
    marginBottom: 0,
    borderRight: '1px solid currentcolor',
    paddingRight: '10px',
    marginRight: '10px'
  })), _ref4;
});
var StyledLink = (0, _styled["default"])(_Link["default"])({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});

function FooterLinks(_ref5) {
  var links = _ref5.links;
  return _react["default"].createElement(FooterLinkWrapper, null, _react["default"].createElement("ul", null, links && links.map(function (link, i) {
    return _react["default"].createElement(Li, {
      key: i
    }, _react["default"].createElement(StyledLink, {
      href: link.url,
      tracker: 'footer-'.concat((0, _slugify["default"])(link.label || '').toLocaleLowerCase())
    }, link.label));
  })));
}

FooterLinks.propTypes = {
  links: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    url: _propTypes["default"].string,
    label: _propTypes["default"].string
  })).isRequired
};
FooterLinks.defaultProps = {
  links: []
};
var _default = FooterLinks;
exports["default"] = _default;